import "core-js/modules/es.array.push.js";
import store from "../../services/store.js";
import EnqueteModal from "../common/EnqueteModal.vue";
import { getData } from "@/services/axios";
import moment from "moment";
import RowItem from "../RowItem";
export default {
  store,
  name: "InputEnquete",
  components: {
    RowItem,
    EnqueteModal
  },

  data() {
    return {
      showModalEnquetes: false,
      enquete: this.$store.state.enquete,
      isLoading: false
    };
  },

  created() {
    this.setData();
  },

  updated() {
    this.updateData();
  },

  methods: {
    setData() {
      if (!this.enquete.implement_to) {
        this.enquete.implement_to = moment().add(14, "days").format("YYYY-MM-DD HH:mm:00");
      }
    },

    updateData() {
      this.$store.dispatch("commitEnquete", this.enquete);
    },

    handleImplementTo(v) {
      // DatePickerに変更を検知させるためにオブジェクトを再作成
      this.enquete = Object.assign({}, this.enquete, {
        implement_to: v
      });
      this.$store.dispatch("commitEnquete", this.enquete);
    },

    removeAnswer(q, index) {
      if (q.options.length <= 1) return;
      q.options.splice(index - 1, 1);
      this.$store.dispatch("commitEnquete", this.enquete);
    },

    addAnswer(q) {
      q.options.push("");
      this.$store.dispatch("commitEnquete", this.enquete);
    },

    removeQuestion(index) {
      if (this.enquete.questions.length === 1) return;
      this.enquete.questions.splice(index, 1);
      this.$store.dispatch("commitEnquete", this.enquete);
    },

    addQuestion() {
      this.enquete.questions.push({
        subject: "",
        options: [""],
        type: "single"
      });
      this.$store.dispatch("commitEnquete", this.enquete);
    },

    selectEnquete() {
      this.showModalEnquetes = false;
    },

    setEnquete(params) {
      this.showModalEnquetes = false; // 指定されたアンケートを取得

      this.isLoading = true;
      getData("enquetes/" + params.enquete_id + "?locale=ja-JP").then(enquetes => {
        this.enquete.subject = enquetes.items[0].subject; // questions を取得

        getData("questions?locale=ja-JP&enquete_id=" + enquetes.items[0].id).then(questions => {
          this.enquete.questions = questions.items.map(v => {
            if (v.question_contents_i18n) {
              return {
                subject: v.question_contents_i18n[0].subject,
                type: v.type,
                options: v.question_contents_i18n[0].options
              };
            }
          });
        }).catch(error => {
          console.log(error);
        });
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading = false;
      });
    }

  }
};