import "core-js/modules/es.array.push.js";
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useStore } from "vue2-helpers/vuex";
import { storeToRefs } from "pinia";
import { useGroupStore } from "@/stores/group";
import RowItem from "@/components/RowItem.vue";
export default {
  __name: 'GroupClassSelectForm',
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Object,
      default: () => {}
    }
  },
  emits: ["handleClassroomChange", "handleGroupChange"],

  setup(__props, {
    emit
  }) {
    const props = __props; // @ts-check

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const {
      studentGroups
    } = storeToRefs(useGroupStore());
    const classroomId = ref(props.filter.classroomId);
    const groupId = ref(props.filter.groupId);
    /** @type {ComputedRef<Object<string, any>[]>} sortedGroups */

    const sortedGroups = computed(() => studentGroups.value.slice().sort((
    /** @type {Object<string, any>} */
    n,
    /** @type {Object<string, any>} */
    m) => {
      if (n.name < m.name) {
        return -1;
      } else if (n.name > m.name) {
        return 1;
      } else {
        return 0;
      }
    }));
    const currentGradeList = computed(() => store.state.grades.filter(v => !v.fiscal_year));

    const filterClass = gradeClassroom => {
      if (Number(props.filter.groupId) === 0) {
        return gradeClassroom;
      } // グループ指定がある場合は所属する生徒がいるクラスを抽出する


      const group = studentGroups.value.find(v => v.id === props.filter.groupId);

      if (group) {
        const belongGroupClassroom = new Set(group.student.map(v => v.class_id));
        return gradeClassroom.filter(v => belongGroupClassroom.has(v.id));
      }

      return [];
    };

    const handleClassroomChange = () => {
      if (route.query.temperature) {
        router.push({
          name: route.name,
          query: {
            classroom_id: classroomId.value,
            year_month: props.filter.yearMonth,
            temperature: "1"
          }
        });
      } else {
        router.push({
          name: route.name,
          query: {
            classroom_id: classroomId.value,
            year_month: props.filter.yearMonth
          }
        });
      }

      emit("handleClassroomChange", classroomId.value);
    };

    const handleGroupChange = () => {
      if (route.query.temperature) {
        router.push({
          name: route.name,
          query: {
            group_id: groupId.value,
            year_month: props.filter.yearMonth,
            temperature: "1"
          }
        });
      } else {
        router.push({
          name: route.name,
          query: {
            group_id: groupId.value,
            year_month: props.filter.yearMonth
          }
        });
      }

      classroomId.value = 0;
      emit("handleGroupChange", groupId.value);
    };

    return {
      __sfc: true,
      route,
      router,
      store,
      studentGroups,
      props,
      emit,
      classroomId,
      groupId,
      sortedGroups,
      currentGradeList,
      filterClass,
      handleClassroomChange,
      handleGroupChange,
      RowItem
    };
  }

};