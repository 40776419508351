var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', [_c('table', {
    staticClass: "table sortable_list"
  }, [_c('thead', [_c('tr', [_c('th'), _c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_setup.columnLabel) + " ")])])]), _c('tbody', _vm._l(_setup.indexOrderItemList, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('button', {
      staticClass: "order_button",
      attrs: {
        "disabled": index === 0
      },
      on: {
        "click": function ($event) {
          return _setup.changeOrderUp(index);
        }
      }
    }, [_vm._v(" ↑ ")]), _c('button', {
      staticClass: "order_button",
      attrs: {
        "disabled": index === _setup.items.length - 1
      },
      on: {
        "click": function ($event) {
          return _setup.changeOrderDown(index);
        }
      }
    }, [_vm._v(" ↓ ")])]), _c('td', [_vm._v(_vm._s(item.name))]), _setup.builtInEditButton ? _c('td', {
      staticClass: "tar"
    }, [_c('router-link', {
      staticClass: "edit_button",
      attrs: {
        "to": {
          name: _setup.builtInEditLink,
          params: {
            id: item.id
          }
        }
      }
    }, [_vm._v(" 編集 ")])], 1) : _c('td')]);
  }), 0)]), _c('section', {
    staticClass: "common_form__button_wrap"
  }, [_setup.isEdited && _setup.builtInCancelButton ? _c('p', {
    staticClass: "cancel_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleCancel.apply(null, arguments);
      }
    }
  }, [_vm._v(" キャンセル ")]) : _vm._e(), _setup.isEdited && _setup.builtInSaveButton ? _c('button', {
    staticClass: "save_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleSave.apply(null, arguments);
      }
    }
  }, [_vm._v(" 並び替えを保存 ")]) : _vm._e()])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };