import store from "../../services/store.js";
export default {
  store,
  name: "ConfirmModal",
  props: {
    confirmMessageTitle: {
      type: String,
      default: ""
    },
    confirmMessage: {
      type: String,
      default: ""
    },
    executeButtonLabel: {
      type: String,
      default: "OK"
    }
  },

  data() {
    return {
      title: this.confirmMessageTitle,
      message: this.confirmMessage.replace(/\n/g, "<br/>")
    };
  }

};