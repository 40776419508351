var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('article', {
    staticClass: "forum-article"
  }, [_c('div', {
    staticClass: "forum-article__header"
  }, [_c('time', {
    staticClass: "date",
    attrs: {
      "datetime": _vm.forum.published_at
    }
  }, [_vm._v(" " + _vm._s(_setup.moment(_vm.forum.published_at).format("YYYY年MM月DD日 HH時mm分")) + " ")]), _vm.forum.author_name ? _c('p', {
    staticClass: "contributor"
  }, [_vm._v(" " + _vm._s(_vm.forum.author_name) + "先生 ")]) : _vm._e(), _c('router-link', {
    staticClass: "detail_button",
    attrs: {
      "to": {
        name: 'forums.detail',
        params: {
          id: _vm.forum.id
        }
      }
    }
  }, [_vm._v(" 詳細 ")])], 1), _c('div', {
    staticClass: "forum-article__body"
  }, [_vm._m(0), _c('h3', {
    staticClass: "title"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'forums.detail',
        params: {
          id: _vm.forum.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.forum.title) + " ")])], 1), _c('div', {
    staticClass: "other"
  }, [_vm.forum.attachment_count ? _c('div', {
    staticClass: "other__item"
  }, [_c('i', {
    staticClass: "fa fa-paperclip"
  })]) : _vm._e(), _c('div', {
    staticClass: "other__item"
  }, [_c('i', {
    staticClass: "far fa-comment"
  }), _c('span', {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.forum.school_forum_comment_count))])])]), _c('div', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.forum.message))])])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "icon school"
  }, [_vm._v("校内")])]);
}];
export { render, staticRenderFns };