var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main"
  }, [_c('h1', {
    staticClass: "page-title"
  }, [_vm._v("掲示板")]), _c('ForumSwitch'), _c('div', {
    staticClass: "forum"
  }, _vm._l(_setup.forumList, function (forum) {
    return _c('GovForumArticle', {
      key: forum.id,
      attrs: {
        "forum": forum
      }
    });
  }), 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };