import { h } from 'vue';
var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_absence_index"
  }, [_c('div', {
    staticClass: "page-header"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._l(_vm.student.absents, function (count, i) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.student.absents.length > 0,
        expression: "student.absents.length > 0"
      }],
      key: i,
      staticClass: "icon_wrap"
    }, [_c('p', {
      staticClass: "icon--date"
    }, [_c('span', {
      staticClass: "num"
    }, [_vm._v(_vm._s(count))]), _vm._v(" 欠 ")])]);
  }), _vm._v(" " + _vm._s(_vm.student.name) + " さんの欠席履歴/学習履歴 ")], 2), _c('div', {
    staticClass: "button_wrap"
  }, [_c('div', {
    staticClass: "switch_button"
  }, [_c('router-link', {
    staticClass: "item switch_button__left active",
    attrs: {
      "to": {
        name: 'attendances.detail',
        query: {
          student_id: _vm.student.id
        }
      },
      "aria-current": "page"
    }
  }, [_vm._v(" 欠席履歴 ")]), _c('router-link', {
    staticClass: "item switch_button__center",
    attrs: {
      "to": {
        name: 'dekitus.detail',
        query: {
          student_id: _vm.student.id
        }
      },
      "aria-current": "page"
    }
  }, [_vm._v(" 学習履歴 ")]), _c('router-link', {
    staticClass: "item switch_button__right",
    attrs: {
      "to": {
        name: 'attendances.monthly',
        query: {
          classroom_id: _vm.student.class_id
        }
      }
    }
  }, [_vm._v(" カレンダー ")])], 1)])]), _vm.isLoading.getData ? _c('loading-message') : _vm._e(), _vm.isLoading.deleteData ? _c('loading-message', {
    attrs: {
      "loading-message": "出欠席データを削除しています"
    }
  }) : _vm._e(), _c('div', {
    attrs: {
      "id": "targetFrame"
    }
  }, _vm._l(_vm.attendances, function (attendance, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('table', {
      staticClass: "table"
    }, [_vm._m(0, true), _c('tbody', [_c('tr', {
      staticClass: "table__tr"
    }, [_c('td', {
      staticClass: "date"
    }, [_vm._v(_vm._s(attendance.date))]), _c('td', {
      class: {
        deleted: attendance.isDeleted
      }
    }, [attendance.isDeleted ? _c('span', [_vm._v("取消")]) : _vm._e(), _c('span', {
      class: `cause icon--${_vm.iconColor(attendance.type)}`
    }, [_vm._v(" " + _vm._s(attendance.type) + " ")])]), _c('td', {
      staticClass: "time"
    }, [_vm._v(" " + _vm._s(attendance.created_at) + " ")]), _c('td', {
      staticClass: "memo"
    }, [_vm._v(_vm._s(attendance.guardian_memo))]), _c('td', {
      staticClass: "memo"
    }, [_vm._v(_vm._s(attendance.comment))])]), attendance.history ? _vm._l(attendance.history, function (h, i) {
      return _c('tr', {
        key: i
      }, [_c('td', {
        staticClass: "date"
      }), _c('td', {
        class: {
          deleted: h.isDeleted
        }
      }, [!h.is_daily_latest ? _c('span', {
        staticClass: "history-icon"
      }, [_c('img', {
        attrs: {
          "src": require("@/assets/images/history.svg"),
          "alt": "history"
        }
      }), _vm._v(" 履歴 ")]) : _vm._e(), _c('span', {
        class: `cause icon--${_vm.iconColor(h.type)}`
      }, [_vm._v(" " + _vm._s(h.type) + " ")])]), _c('td', {
        staticClass: "time"
      }, [_vm._v(" " + _vm._s(h.created_at) + " ")]), _c('td', {
        staticClass: "memo"
      }, [_vm._v(_vm._s(h.guardian_memo))]), _c('td', {
        staticClass: "memo"
      }, [_vm._v(_vm._s(h.comment))])]);
    }) : _vm._e()], 2)]), attendance.showAction && !attendance.isDeleted ? _c('div', {
      staticClass: "col_12 tac"
    }, [_c('router-link', {
      staticClass: "update_button",
      attrs: {
        "to": {
          name: 'attendances.edit',
          params: {
            id: attendance.id
          }
        }
      }
    }, [_vm._v(" 更新する ")]), _c('a', {
      staticClass: "delete_button",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.confirmDeleteData(attendance.id);
        }
      }
    }, [_vm._v(" 削除する ")])], 1) : _vm._e()]);
  }), 0), _c('section', {
    staticClass: "common_form__button_wrap"
  }, [_c('button', {
    staticClass: "return_button",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("一覧に戻る")])]), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.closeModal
    }
  }) : _vm._e(), _vm.showConfirmModal ? _c('confirm-modal', {
    attrs: {
      "confirm-message": _vm.confirmMessage,
      "confirm-message-title": _vm.confirmMessageTitle
    },
    on: {
      "clickOK": _vm.removeAttendance,
      "clickCancel": _vm.cancelConfirm
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "col_2"
  }, [_vm._v("日付")]), _c('th', {
    staticClass: "col_2"
  }, [_vm._v("事由")]), _c('th', {
    staticClass: "col_2"
  }, [_vm._v("連絡日時")]), _c('th', {
    staticClass: "col_3"
  }, [_vm._v("保護者メモ")]), _c('th', {
    staticClass: "col_3"
  }, [_vm._v("先生メモ")])])]);
}];
export { render, staticRenderFns };