import "core-js/modules/es.array.push.js";
import { attendanceSelection } from "@/consts/attendanceSelection";
import RowItem from "../RowItem";
export default {
  components: {
    RowItem
  },
  props: {
    type: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      attendanceSelectionList: attendanceSelection,
      attendanceSelectionChildren: [],
      isShowSetTypeButton: false
    };
  },

  methods: {
    setSelectedType(item, depth) {
      this.attendanceSelectionChildren = this.attendanceSelectionChildren.slice(0, depth);
      this.attendanceSelectionChildren.push(item);
      this.isShowSetTypeButton = !item.children;
    },

    setType() {
      const typeStr = this.attendanceSelectionChildren.filter(v => v.value).map(w => w.value).join("-");
      this.$emit("setType", typeStr);
    },

    resetType() {
      const typeSplit = this.type.split("-");
      this.$emit("resetType"); // 変更を押す前の状態を表示する

      this.attendanceSelectionChildren = [];
      let children = this.attendanceSelectionList;

      while (children) {
        const item = this.findBeforeSelectedType(children, typeSplit);

        if (item) {
          this.attendanceSelectionChildren.push(item);
        }

        children = item.children;
      }

      this.isShowSetTypeButton = true;
    },

    findBeforeSelectedType(selectionList, typeSplit) {
      const item = selectionList.find(v => v.value === typeSplit[0]);

      if (item) {
        // 普通に見つかったとき
        typeSplit.shift();
        return item;
      } // 見つからないときはvalueが空文字のものを選択したと仮定する


      const nullValueItem = selectionList.find(v => v.value === "");

      if (nullValueItem) {
        return nullValueItem;
      } // 遅刻早退の時間の部分のみsplitで分割されてうまく見つけられないので次の要素をjoinした状態で確認する


      if (typeSplit[1]) {
        const lateLeaveTiming = selectionList.find(v => v.value === `${typeSplit[0]}-${typeSplit[1]}`);

        if (lateLeaveTiming) {
          typeSplit.splice(0, 2);
          return lateLeaveTiming;
        }
      }
    }

  }
};