import "core-js/modules/es.array.push.js";
import { createData, deleteData, getData, updateData } from "@/services/axios";
import MessageModal from "@/components/common/MessageModal.vue";
import ConfirmModal from "@/components/common/ConfirmModal";
import LoadingMessage from "@/components/common/LoadingMessage";
import RowItem from "../../RowItem";
export default {
  name: "MessageTemplateEditor",
  components: {
    RowItem,
    MessageModal,
    ConfirmModal,
    LoadingMessage
  },
  data: () => ({
    editData: {
      template_name: "",
      is_emergency: false,
      subject: "",
      mail_body: "",
      call_body: ""
    },
    error: {
      template_name: null,
      subject: null,
      mail_body: null,
      call_body: null
    },
    isLoading: true,
    showModal: false,
    showConfirmModal: false,
    confirmMessageTitle: "",
    confirmMessage: ""
  }),
  head: {
    title: () => ({
      inner: "テンプレート管理"
    }),
    meta: []
  },
  watch: {
    "editData.mail_body": function (newval, oldval) {
      if (this.editData.call_body === "" || this.editData.call_body === oldval) {
        this.editData.call_body = newval;
      }
    }
  },
  created: function () {
    this.editData.is_emergency = !!(this.$route.query.mode && this.$route.query.mode === "emergency");

    if (this.$route.params.id !== undefined) {
      this.setData();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    setData() {
      getData("messages/templates/" + this.$route.params.id).then(result => {
        this.editData = result.items[0];
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading = false;
      });
    },

    doSave() {
      let valid = true;
      this.error = {
        template_name: "",
        subject: "",
        mail_body: "",
        call_body: ""
      };

      if (!this.editData.template_name) {
        this.error.template_name = "テンプレート名を指定してください";
        valid = false;
      }

      if (!this.editData.subject) {
        this.error.subject = "メールの件名を指定してください";
        valid = false;
      }

      if (!this.editData.mail_body) {
        this.error.mail_body = "メールのメッセージ本文を指定してください";
        valid = false;
      }

      if (!this.editData.call_body) {
        this.error.call_body = "電話連絡のメッセージ本文を指定してください";
        valid = false;
      }

      if (!valid) {
        return;
      }

      if (this.$route.params.id === undefined) {
        // post
        createData("messages/templates", this.editData).then(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "テンプレートを作成しました",
            title: "作成完了"
          });
        }).catch(error => {
          this.$store.dispatch("commitModalMessage", {
            message: "テンプレート作成に失敗しました",
            title: "作成失敗"
          });
          console.log(error);
        }).then(() => {
          this.showModal = true;
        });
      } else {
        // put
        updateData("messages/templates/" + this.$route.params.id, this.editData).then(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "テンプレートを更新しました",
            title: "更新完了"
          });
        }).catch(error => {
          this.$store.dispatch("commitModalMessage", {
            message: "テンプレート更新に失敗しました",
            title: "更新失敗"
          });
          console.log(error);
        }).then(() => {
          this.showModal = true;
        });
      }
    },

    confirmDelete() {
      this.confirmMessageTitle = "テンプレート削除";
      this.confirmMessage = "テンプレートを削除します。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
    },

    doDelete() {
      // delete
      deleteData("messages/templates/" + this.$route.params.id).then(() => {
        // 成功
        this.$store.dispatch("commitModalMessage", {
          message: "テンプレートを削除しました",
          title: "削除完了"
        });
      }).catch(error => {
        this.$store.dispatch("commitModalMessage", {
          message: "テンプレート削除に失敗しました",
          title: "削除失敗"
        });
        console.log(error);
      }).then(() => {
        this.showModal = true;
      });
      this.showConfirmModal = false;
    },

    cancelConfirm() {
      this.showConfirmModal = false;
    },

    modalClose() {
      this.showModal = false;
      this.$router.push({
        name: "messages.templates"
      });
    }

  }
};