var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('header', {
    staticClass: "header"
  }, [_c('section', {
    staticClass: "header__inner common_width"
  }, [_c('h1', {
    staticClass: "header__title"
  }, [_vm.isLoggedIn && _vm.school.name ? _c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.school.name) + " ")]) : _c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard'
      }
    }
  }, [_vm._v(" COCOO ")])], 1), _vm.isLoggedIn ? _c('nav', {
    staticClass: "global_navigation"
  }, [_c('router-link', {
    staticClass: "button button--small button--no_shadow",
    attrs: {
      "to": {
        name: 'attendances.student_select'
      }
    }
  }, [_vm._v(" 欠席入力 ")]), _c('router-link', {
    staticClass: "button button--small button--no_shadow",
    attrs: {
      "to": {
        name: 'messages.create'
      }
    }
  }, [_vm._v(" お知らせ作成 ")]), _setup.schoolPlan.forum ? _c('router-link', {
    staticClass: "button button--small button--no_shadow",
    attrs: {
      "to": {
        name: 'forums'
      }
    }
  }, [_vm._v(" 掲示板 ")]) : _vm._e(), _c('router-link', {
    staticClass: "button button--small button--no_shadow",
    attrs: {
      "to": {
        name: 'dekitus'
      }
    }
  }, [_vm._v(" 学習履歴 ")]), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'attendances'
      }
    }
  }, [_vm._v(" 出欠席状況 ")]), _c('div', {
    staticClass: "link parent_menu__item",
    on: {
      "click": function ($event) {
        _setup.showMenuOne = !_setup.showMenuOne;
      }
    }
  }, [_vm._v(" お知らせ/アンケート"), _c('span', {
    staticClass: "menu_status_icon",
    class: _setup.showMenuOne ? 'up' : 'down'
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.showMenuOne,
      expression: "showMenuOne"
    }],
    staticClass: "child_menu"
  }, [_c('div', {
    staticClass: "child_menu__block"
  }, [_c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'messages.create'
      }
    }
  }, [_vm._v(" お知らせ作成 ")]), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'messages'
      }
    }
  }, [_vm._v(" お知らせ一覧 ")]), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'enquetes'
      }
    }
  }, [_vm._v(" アンケート一覧 ")])], 1)])]), _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _setup.hideMenuTwo,
      expression: "hideMenuTwo"
    }],
    staticClass: "link parent_menu__item",
    on: {
      "click": function ($event) {
        _setup.showMenuTwo = !_setup.showMenuTwo;
      }
    }
  }, [_vm._v(" 管理/設定"), _c('span', {
    staticClass: "menu_status_icon",
    class: _setup.showMenuTwo ? 'up' : 'down'
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _setup.showMenuTwo,
      expression: "showMenuTwo"
    }],
    staticClass: "child_menu"
  }, [_c(_setup.RowItem, {
    staticClass: "start stretch"
  }, [_vm.me.role_id <= 11 ? _c('div', {
    staticClass: "child_menu__block"
  }, [_c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'grades'
      }
    }
  }, [_vm._v(" 学年管理 ")]), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'classrooms'
      }
    }
  }, [_vm._v(" クラス管理 ")]), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'groups'
      }
    }
  }, [_vm._v(" グループ管理 ")]), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'messages.templates'
      }
    }
  }, [_vm._v(" テンプレート管理 ")]), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'dekitus.setting'
      }
    }
  }, [_vm._v(" 学習支援ツール"), _c('br'), _vm._v("ID連携 ")])], 1) : _c('div', {
    staticClass: "child_menu__block"
  }, [_c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'classrooms'
      }
    }
  }, [_vm._v(" クラス管理 ")]), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'groups'
      }
    }
  }, [_vm._v(" グループ管理 ")]), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'messages.templates'
      }
    }
  }, [_vm._v(" テンプレート管理 ")])], 1), _c('div', {
    staticClass: "child_menu__block"
  }, [_vm.me.role_id <= 11 ? _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'teachers'
      }
    }
  }, [_vm._v(" 先生管理 ")]) : _vm._e(), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'students'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + "/保護者管理 ")]), _vm.me.role_id <= 11 ? _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'participants'
      }
    }
  }, [_vm._v(" 関係者管理 ")]) : _vm._e()], 1), _c('div', {
    staticClass: "child_menu__block"
  }, [_c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'logs.login'
      }
    }
  }, [_vm._v(" アクセス記録 ")]), _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'events'
      }
    }
  }, [_vm._v(" 年間予定 ")]), _vm.me.role_id <= 11 ? _c('router-link', {
    staticClass: "child_menu__item",
    attrs: {
      "to": {
        name: 'elevate'
      }
    }
  }, [_vm._v(" 年次処理 ")]) : _vm._e(), _c('a', {
    staticClass: "child_menu__item",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.logout.apply(null, arguments);
      }
    }
  }, [_vm._v("ログアウト")])], 1)]), _c('p', {
    staticClass: "login_user"
  }, [_vm._v(_vm._s(_vm.me.name) + " 先生としてログイン中")]), _c('p', {
    staticClass: "phone"
  }, [_c('span', [_vm._v(_vm._s(_vm.school.name))]), _c('br'), _vm._v(" 欠席連絡受付電話番号（受信用）: " + _vm._s(_setup.phoneAttendance)), _c('br'), _vm._v(" お知らせ配信電話番号（配信用）: " + _vm._s(_setup.phoneMessage)), _c('br')])], 1)])], 1) : _vm._e()])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };