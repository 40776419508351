import { login } from "@/services/auth";
import { mapState } from "vuex";
export default {
  name: "Index",
  data: () => ({
    isForceLogout: false
  }),
  head: {
    title: function () {
      return {
        inner: "ログイン"
      };
    },
    meta: []
  },
  computed: { ...mapState(["news"]),
    ttlJP: function () {
      let ttl = 1000 * 60 * 60;
      let ttlJP = "一定時間";

      if (ttl) {
        ttl = ttl / 1000 / 60;
        ttlJP = ttl + "分";
      }

      return ttlJP;
    }
  },

  created() {
    this.isForceLogout = !!localStorage.getItem("FORCE_LOGOUT");
  },

  methods: {
    handleLogin() {
      login();
    }

  }
};