import "core-js/modules/es.array.push.js";
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { getData, deleteData } from "@/services/axios";
import { useStore } from "vue2-helpers/vuex";
import moment from "moment";
import LoadingMessage from "@/components/common/LoadingMessage.vue";
import UrlToLink from "@/components/common/UrlToLink.vue";
import ConfirmSlotModal from "@/components/common/ConfirmSlotModal.vue";
import MessageModal from "@/components/common/MessageModal.vue";
import ForumArticle from "@/components/forums/ForumArticle.vue";
import ForumDetailComment from "@/components/forums/ForumDetailComment.vue";
export default {
  __name: 'ForumDetail',

  setup(__props) {
    // @ts-check
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const me = computed(() => store.state.me);
    const isLoading = ref(true);
    const s3AttachedPath = ref("");
    /** @type {Ref<Object<string, any>>} */

    const forumData = ref({});
    const forumList = ref([]);

    const setData = async () => {
      try {
        isLoading.value = true;
        const data = await getData([`school_forums/${route.params.id}`, `school_forums?from=2023-04-01&to=2024-03-31&exclude=${route.params.id}&limit=10`]);
        forumData.value = data[0].items;
        forumList.value = data[1].items;
        s3AttachedPath.value = forumData.value.attachment[0] ? forumData.value.attachment[0].object_key : "";
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const isShowConfirmModal = ref(false);
    const isShowCompleteModal = ref(false);

    const handleForumDelete = () => {
      isShowConfirmModal.value = true;
    };

    const hideConfirmModal = () => {
      isShowConfirmModal.value = false;
    };

    const deleteForum = async () => {
      try {
        isLoading.value = true;
        await deleteData(`school_forums/${route.params.id}`);
        store.dispatch("commitModalMessage", {
          message: "記事が削除されました",
          title: "削除完了"
        });
        isLoading.value = false;
        isShowConfirmModal.value = false;
        isShowCompleteModal.value = true;
      } catch (error) {
        console.log(error);
        isLoading.value = false;
        isShowConfirmModal.value = false;
      }
    };

    const completeDelete = () => {
      isShowCompleteModal.value = false;
      router.push({
        name: "forums"
      });
    }; // created


    setData();
    watch(() => route.params.id, async () => {
      await setData();
    });
    return {
      __sfc: true,
      route,
      router,
      store,
      me,
      isLoading,
      s3AttachedPath,
      forumData,
      forumList,
      setData,
      isShowConfirmModal,
      isShowCompleteModal,
      handleForumDelete,
      hideConfirmModal,
      deleteForum,
      completeDelete,
      moment,
      LoadingMessage,
      UrlToLink,
      ConfirmSlotModal,
      MessageModal,
      ForumArticle,
      ForumDetailComment
    };
  }

};