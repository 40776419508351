/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./EditGuardian.vue?vue&type=template&id=702d12a9&scoped=true&"
import script from "./EditGuardian.vue?vue&type=script&lang=js&"
export * from "./EditGuardian.vue?vue&type=script&lang=js&"
import style0 from "./EditGuardian.vue?vue&type=style&index=0&id=702d12a9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702d12a9",
  null
  
)

export default component.exports