import "core-js/modules/es.array.push.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import store from "../../services/store.js";
import { mapState } from "vuex";
import { getData, createData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import LoadingMessage from "@/components/common/LoadingMessage";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import Encoding from "encoding-japanese";
import emailValidator from "email-validator";
import RowItem from "../RowItem";
export default {
  store,
  name: "TeacherCSVUpload",
  components: {
    MessageModal,
    LoadingMessage,
    RowItem
  },

  data() {
    return {
      csv: [],
      emails: [],
      skipEmails: [],
      notice: [],
      error: [],
      tableError: [],
      isLoading: false,
      showModal: false,
      section1: false,
      section2: false,
      section3: false,
      notifyRegistered: true
    };
  },

  computed: { ...mapState(["classrooms", "me"]),
    entryCount: function () {
      return this.csv.length - 1 - this.skipEmails.length;
    }
  },

  created() {
    if (this.$store.state.me.role_id > 11) {
      this.$router.push({
        name: "dashboard"
      });
    }
  },

  methods: {
    downloadTemplateCSV() {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      saveAs(new Blob([bom, Papa.unparse([["氏名", "メールアドレス", "権限", "学年組"]])], {
        type: "text/csv;charset=utf-8"
      }), "teacher_template.csv");
    },

    setAttachment(e) {
      const csvFile = e.target.files[0];
      if (!csvFile) return;
      this.error = [];
      this.tableError = [];
      this.notice = [];

      if (!csvFile.name.endsWith(".csv")) {
        this.error.push("ファイルの種類が不正です。CSVをアップロードしてください");
      }

      if (csvFile.size > 2097152) {
        this.error.push("ファイルサイズが5MBを超えています。添付できるCSVは5MB以下です。");
      }

      if (this.error.length > 0) return;
      const reader = new FileReader();

      reader.onload = e => {
        const codes = new Uint8Array(e.target.result);
        const encoding = Encoding.detect(codes); // SJISの検知は間違う場合があるので、確実にUTF8でなければSJISとする

        const from = encoding === "UTF8" ? "UTF8" : "SJIS";
        let csvString = Encoding.convert(codes, {
          to: "UNICODE",
          from: from,
          type: "string"
        }); //UTF-8 with BOMのときにBOM除去

        if (csvString.charCodeAt(0) === 0xfeff) {
          csvString = csvString.substr(1);
        }

        const csv = Papa.parse(csvString);
        const cleaned = this.csvDataCleaning(csv);
        this.csv = cleaned.data;
        this.emails = cleaned.emails;

        if (this.emails.length > 0) {
          this.checkEmailsExists();
        } //同名ファイルの再アップロードを検知するためにリセット


        document.getElementById("file").value = "";
      };

      reader.readAsArrayBuffer(csvFile);
    },

    csvDataCleaning(csv) {
      //エラーテーブルを初期化
      csv.data.forEach(v => {
        const row = [];
        v.forEach(() => row.push(false));
        this.tableError.push(row);
      });

      if (csv.errors.length > 0) {
        this.error = csv.errors.map(v => v.message);
      }

      const data = [];
      const emails = [];
      csv.data.forEach((row, i) => {
        if (i === 0) {
          if (row.join() !== "氏名,メールアドレス,権限,学年組") {
            this.error.push("見出し行が「氏名」「メールアドレス」「権限」「学年組」と異なります");
            this.tableError[i] = this.tableError[i].map(() => true);
          }

          return data.push(row);
        }

        if (row.length !== 4) {
          //ExcelのCSVは最終行に空行を作るので許容する
          if (!(row.length === 1 && i === csv.data.length - 1)) {
            this.error.push(i + 1 + "行目の値が4列ではありません");
            this.tableError[i] = this.tableError[i].map(() => true);
            return data.push(row);
          } else {
            return;
          }
        }

        row = row.map((cell, j) => {
          cell = Encoding.toHankakuCase(cell).trim();
          let valid = true;

          if (j === 0) {
            if (!cell) {
              this.error.push(i + 1 + "行目の氏名を入力してください");
              valid = false;
            } else if (!cell.match(/\s/)) {
              this.error.push(i + 1 + "行目の氏名の姓と名の間に半角スペースを入れてください");
              valid = false;
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 1) {
            if (!cell) {
              this.error.push(i + 1 + "行目のメールアドレスを入力してください");
              emails.push("");
              valid = false;
            } else if (!emailValidator.validate(cell)) {
              this.error.push(i + 1 + "行目のメールアドレスの形式が正しくありません");
              emails.push("");
              valid = false;
            } else {
              emails.push(cell);
            }
          }

          if (j === 3) {
            if (cell && !this.classrooms.find(c => c.name === cell)) {
              this.error.push(i + 1 + "行目の学年組はCOCOOに登録されている学年組(クラス名)と異なります");
              valid = false;
            }
          }

          if (!valid) this.tableError[i][j] = true;
          return cell;
        });
        data.push(row);
      });
      return {
        data,
        emails
      };
    },

    checkEmailsExists() {
      const duplicateEmail = this.emails.filter((x, i, self) => {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });

      if (duplicateEmail.length > 0) {
        this.error.push("CSVの中で " + duplicateEmail.join(", ") + "のメールアドレスが重複しています。");
      }

      this.isLoading = true;
      const params = "?emails[]=" + this.emails.map(v => encodeURIComponent(v)).reduce((accumulator, currentValue) => accumulator + "&emails[]=" + currentValue);
      return getData("reader/get_emails_duplicate" + params).then(data => {
        this.isLoading = false;

        if (data.items.length > 0) {
          const skipEmails = [];
          data.items.forEach(v => {
            if (v.school === "other") {
              this.error.push(v.email + "は他校で登録済みのメールアドレスです。COCOOサポート事務局にお問い合わせください");
            } else {
              this.notice.push(v.email + "はこの学校に既存の先生のメールアドレスです。この行は登録をスキップされます。");
              skipEmails.push(v.email);
            }
          });
          this.skipEmails = skipEmails;
        }
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    saveData() {
      this.isLoading = true;
      const classroomsMap = {};
      this.classrooms.forEach(v => classroomsMap[v.name] = v.id);
      const teachers = []; //ヘッダー行の除去

      this.csv.shift();
      this.csv.forEach(v => {
        if (!this.skipEmails.find(w => w === v[1])) {
          teachers.push({
            name: v[0],
            email: v[1],
            role_id: v[2] === "管理者" ? 11 : 12,
            classrooms: classroomsMap[v[3]] ? [classroomsMap[v[3]]] : []
          });
        }
      });
      createData("writer/bulk_write_teacher", {
        teachers: teachers,
        notifyRegistered: this.notifyRegistered
      }).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "先生情報一括登録が完了しました",
          title: "登録完了"
        });
        this.showModal = true;
        this.isLoading = false;
      }).catch(error => {
        this.$store.dispatch("commitModalMessage", {
          message: "先生情報一括登録に失敗しました",
          title: "登録失敗"
        });
        console.log(error);
        this.showModal = true;
        this.isLoading = false;
      });
    },

    completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "teachers"
      });
    },

    open(value) {
      if (value === "section1") {
        this.section1 = true;
      } else if (value === "section2") {
        this.section2 = true;
      } else if (value === "section3") {
        this.section3 = true;
      }
    },

    close(value) {
      if (value === "section1") {
        this.section1 = false;
      } else if (value === "section2") {
        this.section2 = false;
      } else if (value === "section3") {
        this.section3 = false;
      }
    }

  }
};