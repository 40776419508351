var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', [_vm.isLoading.status ? _c(_setup.LoadingMessage, {
    attrs: {
      "loading-message": _vm.isLoading.message
    }
  }) : _c('main', {
    staticClass: "main page_student_delete"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("お知らせ配信 - 予約編集")]), _c('section', {
    staticClass: "confirm_text"
  }, [_c('p', [_vm._v("「" + _vm._s(_vm.subject) + "」を編集します。")]), _c('p', [_c('span', {
    staticClass: "alert_text"
  }, [_vm._v(_vm._s(_vm.scheduled_for) + "の予約")]), _vm._v("がキャンセルされますがよろしいですか？ ")]), _c('div', {
    staticClass: "process_message"
  }, [_vm.processMessage ? _c('p', [_vm._v(" " + _vm._s(_vm.processMessage) + " ")]) : _vm._e()])]), _c('section', {
    staticClass: "button_wrapper tac"
  }, [_c('router-link', {
    staticClass: "cancel_button",
    attrs: {
      "to": {
        name: 'messages'
      }
    }
  }, [_vm._v(" 一覧にもどる ")]), _c('button', {
    staticClass: "edit_button",
    on: {
      "click": _vm.openEditor
    }
  }, [_vm._v(" お知らせを編集する ")])], 1)])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };