import "core-js/modules/es.array.push.js";
import { ref } from "vue";
import { useRouter } from "vue-router/composables";
import moment from "moment";
import ConfirmSlotModal from "@/components/common/ConfirmSlotModal.vue";
import RowItem from "../RowItem.vue";
export default {
  __name: 'ConfirmParticipantQr',
  props: {
    clickCancel: {
      type: Function,
      required: true
    }
  },

  setup(__props) {
    // @ts-check
    const router = useRouter();
    const belong = ref("");
    const number = ref("");

    const moveGeneratePage = () => {
      const num = Number(number.value);

      if (num && num > 0 && num <= 100) {
        router.push({
          name: "participants.qr_generate",
          params: {
            belong: belong.value,
            number: number.value
          }
        });
      }
    };

    return {
      __sfc: true,
      router,
      belong,
      number,
      moveGeneratePage,
      moment,
      ConfirmSlotModal,
      RowItem
    };
  }

};