export const attendanceSelection = [
  {
    label: "欠席",
    value: "欠席",
    children: [
      {
        label: "病気やケガ",
        value: "",
        children: [
          {
            label: "風邪",
            value: "風邪",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "発熱",
            value: "発熱",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "頭痛",
            value: "頭痛",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "腹痛",
            value: "腹痛",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "嘔吐下痢",
            value: "嘔吐下痢",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "咳・息苦しさ",
            value: "咳・息苦しさ",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "受診・通院",
            value: "受診・通院",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "発疹",
            value: "発疹",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "ケガ",
            value: "ケガ",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "その他",
            value: "その他病気",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "体調不良",
        value: "体調不良",
        children: [
          {
            label: "気分がすぐれない",
            value: "気分がすぐれない",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "疲れ・倦怠感など",
            value: "疲れ倦怠感",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "ストレスや悩み・不安感",
            value: "ストレス悩み不安感",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "吐き気",
            value: "吐き気",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "生理痛",
            value: "生理痛",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "コロナ関連",
        value: "コロナ",
        children: [
          {
            label: "新型コロナの診断を受けている方",
            admin: "新型コロナ診断",
            value: "診断",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "ご本人が濃厚接触者",
            admin: "本人が濃厚接触者",
            value: "本人濃厚接触",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "家族が濃厚接触者",
            value: "家族濃厚接触",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "感染予防・登校自粛",
            value: "感染予防・登校自粛",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "ワクチン接種",
            value: "ワクチン接種",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "ワクチン副反応など",
            value: "ワクチン副反応など",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "感染症の診断を受けた方",
        value: "感染症",
        children: [
          {
            label: "インフルエンザ",
            value: "インフルエンザ",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "感染性胃腸炎",
            value: "感染性胃腸炎",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "溶連菌",
            value: "溶連菌",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "水ぼうそう",
            value: "水ぼうそう",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "おたふくかぜ",
            value: "おたふくかぜ",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "ふうしん",
            value: "ふうしん",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "はしか（麻しん）",
            value: "はしか",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "百日咳",
            value: "百日咳",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "結核",
            value: "結核",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "マイコプラズマ感染症",
            value: "マイコプラズマ",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "りんご病",
            admin: "りんご病（伝染性紅班）",
            value: "りんご病",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "手足口病",
            value: "手足口病",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "プール熱",
            admin: "プール熱（咽頭結膜熱）",
            value: "プール熱",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "流行性角結膜炎",
            value: "流行性角結膜炎",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "特別支援校の方",
        value: "特支",
        children: [
          {
            label: "受診",
            value: "受診",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "術後訓練",
            value: "術後訓練",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "リハビリ訓練",
            value: "リハビリ訓練",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "定期通院",
            value: "定期通院",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "入所",
            value: "入所",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "レスパイト",
            value: "レスパイト",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "37.5度以上",
                value: "37.5度超",
              },
              {
                label: "37.5度未満",
                value: "37.5度未満",
              },
              {
                label: "わからない",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "介護・家の手伝い",
        value: "介護・手伝い",
      },
      {
        label: "忌引",
        value: "忌引",
      },
      {
        label: "家の都合",
        value: "家の都合",
      },
      {
        label: "その他",
        value: "その他",
        children: [
          {
            label: "37.5度以上",
            value: "37.5度超",
          },
          {
            label: "37.5度未満",
            value: "37.5度未満",
          },
          {
            label: "わからない",
            value: "",
          },
        ],
      },
    ],
  },
  {
    label: "遅刻",
    value: "遅刻",
    children: [
      {
        label: "病気やケガ",
        value: "",
        children: [
          {
            label: "風邪",
            value: "風邪",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "発熱",
            value: "発熱",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "頭痛",
            value: "頭痛",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "腹痛",
            value: "腹痛",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "嘔吐下痢",
            value: "嘔吐下痢",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "咳・息苦しさ",
            value: "咳・息苦しさ",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "受診・通院",
            value: "受診・通院",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "発疹",
            value: "発疹",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "ケガ",
            value: "ケガ",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "その他",
            value: "その他病気",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "体調不良",
        value: "体調不良",
        children: [
          {
            label: "気分がすぐれない",
            value: "気分がすぐれない",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "疲れ・倦怠感など",
            value: "疲れ倦怠感",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "ストレスや悩み・不安感",
            value: "ストレス悩み不安感",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "吐き気",
            value: "吐き気",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "生理痛",
            value: "生理痛",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "特別支援校の方",
        value: "特支",
        children: [
          {
            label: "受診",
            value: "受診",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "術後訓練",
            value: "術後訓練",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "リハビリ訓練",
            value: "リハビリ訓練",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "定期通院",
            value: "定期通院",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "入所",
            value: "入所",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "レスパイト",
            value: "レスパイト",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "午前登校",
                value: "午前遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校",
                value: "午後遅",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "介護・家の手伝い",
        value: "介護・手伝い",
        children: [
          {
            label: "午前登校",
            value: "午前遅",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後登校",
            value: "午後遅",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "忌引",
        value: "忌引",
        children: [
          {
            label: "午前登校",
            value: "午前遅",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後登校",
            value: "午後遅",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "家の都合",
        value: "家の都合",
        children: [
          {
            label: "午前登校",
            value: "午前遅",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後登校",
            value: "午後遅",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "その他",
        value: "その他",
        children: [
          {
            label: "午前登校",
            value: "午前遅",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後登校",
            value: "午後遅",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "早退",
    value: "早退",
    children: [
      {
        label: "病気やケガ",
        value: "",
        children: [
          {
            label: "風邪",
            value: "風邪",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "発熱",
            value: "発熱",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "頭痛",
            value: "頭痛",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "腹痛",
            value: "腹痛",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "嘔吐下痢",
            value: "嘔吐下痢",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "咳・息苦しさ",
            value: "咳・息苦しさ",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "受診・通院",
            value: "受診・通院",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "発疹",
            value: "発疹",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "ケガ",
            value: "ケガ",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "その他",
            value: "その他病気",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "体調不良",
        value: "体調不良",
        children: [
          {
            label: "気分がすぐれない",
            value: "気分がすぐれない",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "疲れ・倦怠感など",
            value: "疲れ倦怠感",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "ストレスや悩み・不安感",
            value: "ストレス悩み不安感",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "吐き気",
            value: "吐き気",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "生理痛",
            value: "生理痛",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "特別支援校の方",
        value: "特支",
        children: [
          {
            label: "受診",
            value: "受診",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "術後訓練",
            value: "術後訓練",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "リハビリ訓練",
            value: "リハビリ訓練",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "定期通院",
            value: "定期通院",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "入所",
            value: "入所",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "レスパイト",
            value: "レスパイト",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "午前下校",
                value: "午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後下校",
                value: "午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "介護・家の手伝い",
        value: "介護・手伝い",
        children: [
          {
            label: "午前下校",
            value: "午前早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後下校",
            value: "午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "忌引",
        value: "忌引",
        children: [
          {
            label: "午前下校",
            value: "午前早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後下校",
            value: "午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "家の都合",
        value: "家の都合",
        children: [
          {
            label: "午前下校",
            value: "午前早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後下校",
            value: "午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "その他",
        value: "その他",
        children: [
          {
            label: "午前下校",
            value: "午前早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後下校",
            value: "午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "遅刻と早退",
    value: "遅刻早退",
    children: [
      {
        label: "病気やケガ",
        value: "",
        children: [
          {
            label: "風邪",
            value: "風邪",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "発熱",
            value: "発熱",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "頭痛",
            value: "頭痛",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "腹痛",
            value: "腹痛",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "嘔吐下痢",
            value: "嘔吐下痢",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "咳・息苦しさ",
            value: "咳・息苦しさ",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "受診・通院",
            value: "受診・通院",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "発疹",
            value: "発疹",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "ケガ",
            value: "ケガ",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "その他",
            value: "その他病気",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "体調不良",
        value: "体調不良",
        children: [
          {
            label: "気分がすぐれない",
            value: "気分がすぐれない",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "疲れ・倦怠感など",
            value: "疲れ倦怠感",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "ストレスや悩み・不安感",
            value: "ストレス悩み不安感",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "吐き気",
            value: "吐き気",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "生理痛",
            value: "生理痛",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "特別支援校の方",
        value: "特支",
        children: [
          {
            label: "受診",
            value: "受診",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "術後訓練",
            value: "術後訓練",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "リハビリ訓練",
            value: "リハビリ訓練",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "定期通院",
            value: "定期通院",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "入所",
            value: "入所",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "レスパイト",
            value: "レスパイト",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            label: "その他",
            value: "その他",
            children: [
              {
                label: "午前登校・午前早退",
                value: "午前遅-午前早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午前登校・午後早退",
                value: "午前遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
              {
                label: "午後登校・午後早退",
                value: "午後遅-午後早",
                children: [
                  {
                    label: "給食いる",
                    value: "給食○",
                  },
                  {
                    label: "給食いらない",
                    value: "給食×",
                  },
                  {
                    label: "給食のない学校",
                    value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "介護・家の手伝い",
        value: "介護・手伝い",
        children: [
          {
            label: "午前登校・午前早退",
            value: "午前遅-午前早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午前登校・午後早退",
            value: "午前遅-午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後登校・午後早退",
            value: "午後遅-午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "忌引",
        value: "忌引",
        children: [
          {
            label: "午前登校・午前早退",
            value: "午前遅-午前早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午前登校・午後早退",
            value: "午前遅-午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後登校・午後早退",
            value: "午後遅-午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "家の都合",
        value: "家の都合",
        children: [
          {
            label: "午前登校・午前早退",
            value: "午前遅-午前早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午前登校・午後早退",
            value: "午前遅-午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後登校・午後早退",
            value: "午後遅-午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
      {
        label: "その他",
        value: "その他",
        children: [
          {
            label: "午前登校・午前早退",
            value: "午前遅-午前早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午前登校・午後早退",
            value: "午前遅-午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
          {
            label: "午後登校・午後早退",
            value: "午後遅-午後早",
            children: [
              {
                label: "給食いる",
                value: "給食○",
              },
              {
                label: "給食いらない",
                value: "給食×",
              },
              {
                label: "給食のない学校",
                value: "",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "登校方法の変更",
    value: "登校",
    children: [
      { label: "スクールバス", value: "スクールバス" },
      { label: "自家用車", value: "自家用車" },
      { label: "福祉車両", value: "福祉車両" },
      { label: "自主登校", value: "自主登校" },
      { label: "その他", value: "その他" },
    ],
  },
  {
    label: "下校方法の変更",
    value: "下校",
    children: [
      { label: "スクールバス", value: "スクールバス" },
      { label: "自家用車", value: "自家用車" },
      { label: "福祉車両", value: "福祉車両" },
      { label: "自主下校", value: "自主下校" },
      { label: "放課後デイサービス", value: "放課後デイ" },
      { label: "その他", value: "その他" },
    ],
  },
  {
    label: "未登校",
    value: "未登校",
  },
];
