// ie11対応
import { ScalingSquaresSpinner } from "epic-spinners/dist/lib/epic-spinners.min.js";
import "epic-spinners/dist/lib/epic-spinners.min.css"; // ie11が無視できるようになれば以下のように読み込めばよい（上記2行は不要）
// import { ScalingSquaresSpinner } from "epic-spinners";

export default {
  name: "LoadingMessage",
  components: {
    ScalingSquaresSpinner
  },
  props: {
    loadingMessage: {
      type: String,
      default: ""
    }
  }
};