import store from "../../services/store.js";
import { getData } from "@/services/axios";
import moment from "moment";
export default {
  store,
  name: "SelectEnquete",

  data() {
    return {
      enquetes: [],
      selectedId: false,
      isLoading: true
    };
  },

  created() {
    this.setData();
  },

  methods: {
    handleClick(mode) {
      this.$emit("select", {
        mode: mode,
        enquete_id: this.selectedId
      });
    },

    setData() {
      getData("enquetes?locale=ja-JP&for_copy=1").then(data => {
        this.enquetes = data.items;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading = false;
      });
    },

    getJPTime(datetime) {
      return moment(datetime).isValid() ? moment(datetime).format("llll") : "";
    },

    isInProgress(datetime) {
      return moment().startOf("day").isBefore(moment(datetime).startOf("day"));
    }

  }
};