var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main qr_gen"
  }, [_vm.isLoading ? _c('div', [_vm._v(" QRコード生成中... "), _vm._l(3, function (n) {
    return _c('content-placeholders', {
      key: n,
      staticClass: "placeholder"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    }), _c('content-placeholders-img')], 1);
  })], 2) : _vm._l(_vm.students, function (student, i) {
    return _c('div', {
      key: i,
      staticClass: "qr_display tac"
    }, [_c('p', {
      staticClass: "student_name"
    }, [_vm._v(" " + _vm._s(student ? student.grade.name : "") + " " + _vm._s(student.name) + " ")]), _c('qriously', {
      attrs: {
        "value": _vm.QRdata(student),
        "size": 400,
        "level": "H"
      }
    }), _c('a', {
      staticClass: "student_link",
      attrs: {
        "href": _vm.QRdata(student),
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(_vm.QRdata(student)) + " ")]), _c('div', {
      staticClass: "comment"
    }, [_c('p', {
      staticClass: "expire_on"
    }, [_vm._v("有効期限　" + _vm._s(student.expire_on))]), _c('br'), _vm._v(" 前日までにご登録ください。"), _c('br'), _vm._v(" 期限切れの場合、学校様へ再発行をご依頼ください。 ")])], 1);
  }), _vm.showNotice ? _c('div', {
    staticClass: "notice"
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + "一覧ページに戻り、QRコードを発行する" + _vm._s(_vm.studentAddress) + "を指定してください。 ")]) : _vm._e(), _c('div', {
    staticClass: "button_wrapper tac"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'students'
      }
    }
  }, [_vm._v(" 戻る ")])], 1)], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };