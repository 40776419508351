import "core-js/modules/es.array.push.js";
// @ts-check
import moment from "moment";
import { useStore } from "vue2-helpers/vuex";
export const useMonthlySummary = () => {
  const route = useRoute();
  const store = useStore();
  /** @type Ref<AttendanceFilter> */

  const filter = ref({
    classroomId: route.query.classroom_id ? Number(route.query.classroom_id) ?? 0 : 0,
    groupId: route.query.group_id ? Number(route.query.group_id) ?? 0 : 0,
    yearMonth: route.query.year_month ? String(route.query.year_month) : moment().format("YYYY-MM")
  });

  const updateFilter = () => {
    filter.value.classroomId = route.query.classroom_id ? Number(route.query.classroom_id) ?? 0 : 0;
    filter.value.groupId = route.query.group_id ? Number(route.query.group_id) ?? 0 : 0;
    filter.value.yearMonth = route.query.year_month ? String(route.query.year_month) : moment().format("YYYY-MM");
  };

  watch(route, () => {
    updateFilter();
  });
  /** @type ComputedRef<string[]> */

  const holidays = computed(() => store.state.holidays);
  /**
   * 1か月の日ごとの情報
   *
   * @type ComputedRef<MonthData[]>
   */

  const monthData = computed(() => {
    const monthDataTemp = [];
    const fdOm = moment(filter.value.yearMonth).startOf("month");
    const days = fdOm.daysInMonth();

    for (let i = 0; i < days; i++) {
      monthDataTemp.push({
        "YYYY-MM-DD": fdOm.format("YYYY-MM-DD"),
        d: fdOm.format("d"),
        D: fdOm.format("D"),
        isHoliday: holidays.value.includes(fdOm.format("YYYY-MM-DD")),
        weekName: fdOm.format("ddd"),
        isSchoolDayOff: false
      });
      monthDataTemp[i].isSchoolDayOff = monthDataTemp[i].isHoliday || monthDataTemp[i].d === "0" || monthDataTemp[i].d === "6";
      fdOm.add(1, "d");
    }

    return monthDataTemp;
  });
  return {
    filter,
    monthData
  };
};