var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _setup.schoolPlan.forum ? _c('main', {
    staticClass: "main page_information_index"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("掲示板")]), _setup.schoolPlan.forum && _vm.school.government && _vm.school.government.length > 0 ? _c('ForumSwitch') : _vm._e(), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "create_button",
    attrs: {
      "to": {
        name: 'forums.create'
      }
    }
  }, [_vm._v(" 新規作成 ")])], 1), _c('section', {
    staticClass: "forum"
  }, _vm._l(_setup.forumList, function (forum, index) {
    return _c(_setup.ForumArticle, {
      key: index,
      attrs: {
        "forum": forum
      }
    });
  }), 1)], 1) : _vm._e();
};

var staticRenderFns = [];
export { render, staticRenderFns };