import "core-js/modules/es.array.push.js";
import moment from "moment";
import { getData } from "@/services/axios";
import store from "../../services/store.js";
import PageSwitch from "./PageSwitch";
import RowItem from "../RowItem";
import { mapState } from "vuex";
export default {
  name: "EnqueteDetail",
  components: {
    RowItem,
    PageSwitch
  },
  store,
  data: () => {
    return {
      enquete: {},
      isLoading: true,
      statuses: [],
      participantStatuses: []
    };
  },
  computed: { ...mapState(["studentAddress"]),
    isOpen: function () {
      return moment().isBefore(this.enquete.implement_to);
    }
  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      getData(["reader/get_enquete_detail/" + this.$route.params.id, "reader/get_enquete_statuses/" + this.$route.params.id]).then(data => {
        if (data) {
          this.enquete = data[0].items;
          this.statuses = data[1].items.students;
          this.participantStatuses = data[1].items.participants;
        }
      }).catch(error => {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る

        if (error.response) {
          this.$router.push({
            name: "enquetes"
          });
        }
      }).then(() => {
        this.isLoading = false;
      });
    },

    getDateJP(datetime) {
      return moment(datetime).format("YYYY年MM月DD日 HH:mm");
    }

  }
};