var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', [_setup.isLoading ? _c(_setup.LoadingMessage, {
    attrs: {
      "loading-message": _setup.loadMessage
    }
  }) : _c('main', {
    staticClass: "main page_information_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("掲示板 - " + _vm._s(_setup.pageTitle))]), _c('form', {
    staticClass: "common_form",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_c('section', {
    staticClass: "gray_frame"
  }, [_c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("記事のタイトル")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _setup.title,
      expression: "title",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _setup.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _setup.title = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _setup.error.get('title') ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.error.get("title")) + " ")]) : _vm._e()])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("メッセージ本文")]), _c('div', {
    staticClass: "col_9"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message,
      expression: "message"
    }],
    staticClass: "common_form--textarea",
    attrs: {
      "id": "",
      "name": "body",
      "rows": "10"
    },
    domProps: {
      "value": _setup.message
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _setup.message = $event.target.value;
      }
    }
  }), _vm._v(" "), _setup.error.get('message') ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.error.get("message")) + " ")]) : _vm._e(), !_setup.isUrlValidate ? _c('p', {
    staticClass: "warning"
  }, [_vm._v(" URLは1行に1つずつ記載してください。URLの前後に文字や記号、行内に複数のURLが含まれるとリンクが正しく機能しません。 ")]) : _vm._e()])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("添付ファイル")]), _c('div', {
    staticClass: "col_9"
  }, [_setup.s3AttachedPath ? _c('div', [_c('button', {
    staticClass: "status_button",
    attrs: {
      "disabled": _setup.getS3UrlStatusRunning
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.getS3SignedUrl.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_setup.getS3UrlStatus) + " ")]), _c('button', {
    staticClass: "file_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.showUnsetAttachmentConfirmModal.apply(null, arguments);
      }
    }
  }, [_vm._v(" ファイルを差し替え ")]), _c('span', [_vm._v(_vm._s(" " + _setup.fileName) + " ")])]) : _c('div', [_c('button', {
    staticClass: "file_button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _setup.open();
      }
    }
  }, [_vm._v(" 参照 ")]), _setup.attachment ? _c('span', [_vm._v(_vm._s(_setup.attachment.name) + " ["), _c('a', {
    attrs: {
      "href": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.unsetAttachment.apply(null, arguments);
      }
    }
  }, [_vm._v("x")]), _vm._v("] ")]) : _vm._e()]), _setup.attachmentError ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.attachmentError) + " ")]) : _vm._e(), _c('p', [_vm._v(" 1件のPDF・画像（pdf,jpg,jpeg,png,gif）ファイルを添付できます（5MBまで） ")])])])], 1), _setup.errorTotalText ? _c('pre', {
    staticClass: "error pre"
  }, [_vm._v("        " + _vm._s(_setup.errorTotalText.trim()) + "\n      ")]) : _vm._e(), _c('div', {
    staticClass: "tac"
  }, [_c('input', {
    staticClass: "submit_button",
    attrs: {
      "type": "button",
      "value": _setup.isCreate ? '記事投稿' : _setup.pageTitle
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _setup.isCreate ? _setup.checkMessageConfirm() : _setup.createEditForum();
      }
    }
  })])]), _setup.isShowUnsetAttachmentConfirmModal ? _c(_setup.ConfirmSlotModal, {
    attrs: {
      "click-ok": _setup.unsetS3Attachment,
      "click-cancel": _setup.hideUnsetAttachmentConfirmModal
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("ファイル差し替え")];
      },
      proxy: true
    }], null, false, 1157849864)
  }, [_vm._v(" ファイルを差し替えると、現在添付されているファイルは確認できなくなります。 実行してもよろしいですか？ ")]) : _vm._e(), _setup.isShowConfirmModal ? _c(_setup.ConfirmSlotModal, {
    attrs: {
      "click-ok": _setup.createEditForum,
      "click-cancel": _setup.hideConfirmModal
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("記事投稿")];
      },
      proxy: true
    }], null, false, 351958628)
  }, [_vm._v(" 記事を投稿してもよろしいですか ")]) : _vm._e(), _setup.isShowCompleteModal ? _c(_setup.MessageModal, {
    on: {
      "close": _setup.completeCreate
    }
  }) : _vm._e()], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };