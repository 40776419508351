var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "button_wrap"
  }, [_vm.messageId ? _c('router-link', {
    staticClass: "result_button",
    attrs: {
      "to": {
        name: _vm.messageLink,
        params: {
          id: _vm.messageId
        }
      }
    }
  }, [_vm._v(" お知らせ配信状況 ")]) : _vm._e(), _c('section', {
    staticClass: "switch_button"
  }, [_c('router-link', {
    staticClass: "item switch_button__left",
    class: {
      active: _vm.active === 'detail'
    },
    attrs: {
      "to": {
        name: 'enquetes.detail',
        params: {
          id: _vm.$route.params.id
        }
      }
    }
  }, [_vm._v(" 質問内容 ")]), _c('router-link', {
    staticClass: "item",
    class: {
      active: _vm.active === 'status'
    },
    attrs: {
      "to": {
        name: 'enquetes.status',
        params: {
          id: _vm.$route.params.id
        }
      }
    }
  }, [_vm._v(" 回答状況 ")]), _c('router-link', {
    staticClass: "item switch_button__right",
    class: {
      active: _vm.active === 'result'
    },
    attrs: {
      "to": {
        name: 'enquetes.result',
        params: {
          id: _vm.$route.params.id
        }
      }
    }
  }, [_vm._v(" 回答結果 ")])], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };