import { getData } from "@/services/axios";
import { mapState } from "vuex";
import RowItem from "../RowItem";
import ConfirmParticipantQr from "./ConfirmParticipantQr.vue";
export default {
  name: "ParticipantsIndex",
  components: {
    RowItem,
    ConfirmParticipantQr
  },
  data: () => ({
    isLoading: {
      participants: true
    },
    filterKeyword: "",
    participants: [],
    // API からとってきたオリジナルデータ
    visibleParticipants: [],
    // 加工表示用
    showConfirmModal: false
  }),
  head: {
    title: () => ({
      inner: "関係者一覧"
    }),
    meta: []
  },
  computed: { ...mapState(["grades", "me"])
  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      getData("participants").then(data => {
        this.participants = this.visibleParticipants = data.items;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.participants = false;
      });
    },

    filterData: function (e) {
      e.preventDefault();
      let participants = this.participants;
      const keyword = this.filterKeyword;

      if (keyword) {
        participants = participants.filter(v => Object.keys(v).some(k => String(v[k]).indexOf(keyword) > -1));
      }

      this.visibleParticipants = participants;
    },

    confirmGetQrCode() {
      this.showConfirmModal = true;
    },

    cancelConfirm() {
      this.showConfirmModal = false;
    }

  }
};