import { storeToRefs } from "pinia";
import { useAbsentAlertStore } from "@/stores/absentAlert";
import moment from "moment";
export default {
  __name: 'AbsentAlertList',

  setup(__props) {
    // @ts-check
    const absentAlertStore = useAbsentAlertStore();
    const {
      absentList3,
      absentList20,
      absentList30,
      latestDekitusByStudent
    } = storeToRefs(absentAlertStore);
    return {
      __sfc: true,
      absentAlertStore,
      absentList3,
      absentList20,
      absentList30,
      latestDekitusByStudent,
      moment
    };
  }

};