var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_school_grade_index"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("学校管理 - 学年一覧")]), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "add_button",
    attrs: {
      "to": {
        name: 'grades.create'
      }
    }
  }, [_vm._v(" 学年登録 ")])], 1), !_setup.isLoading ? _c(_setup.sortableList, {
    attrs: {
      "items": _setup.gradeSortList,
      "built-in-save-button": true,
      "built-in-edit-button": true,
      "built-in-edit-link": 'grades.edit',
      "column-label": "学年"
    },
    on: {
      "save": _setup.saveOrder,
      "cancel": _setup.cancelOrder
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };