var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    attrs: {
      "id": "loadingMsg"
    }
  }, [_c('scaling-squares-spinner', {
    attrs: {
      "animation-duration": 1200,
      "size": 60,
      "color": "#0064b5"
    }
  }), _vm.loadingMessage ? _c('div', {
    staticClass: "message"
  }, [_vm._v(" " + _vm._s(_vm.$props.loadingMessage) + " ")]) : _c('div', {
    staticClass: "message"
  }, [_vm._v("読み込み中")])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };