export const attendanceLabels = {
  "欠席-風邪-37.5度超": {
    book: "風邪",
    code: "×",
  },
  "欠席-風邪-37.5度未満": {
    book: "風邪",
    code: "×",
  },
  "欠席-風邪": {
    book: "風邪",
    code: "×",
  },
  "欠席-発熱-37.5度超": {
    book: "発熱",
    code: "×",
  },
  "欠席-発熱-37.5度未満": {
    book: "発熱",
    code: "×",
  },
  "欠席-発熱": {
    book: "発熱",
    code: "×",
  },
  "欠席-頭痛-37.5度超": {
    book: "頭痛",
    code: "×",
  },
  "欠席-頭痛-37.5度未満": {
    book: "頭痛",
    code: "×",
  },
  "欠席-頭痛": {
    book: "頭痛",
    code: "×",
  },
  "欠席-腹痛-37.5度超": {
    book: "腹痛",
    code: "×",
  },
  "欠席-腹痛-37.5度未満": {
    book: "腹痛",
    code: "×",
  },
  "欠席-腹痛": {
    book: "腹痛",
    code: "×",
  },
  "欠席-感染性胃腸炎-37.5度超": {
    book: "感胃腸炎",
    code: "×",
  },
  "欠席-感染性胃腸炎-37.5度未満": {
    book: "感胃腸炎",
    code: "×",
  },
  "欠席-感染性胃腸炎": {
    book: "感胃腸炎",
    code: "×",
  },
  "欠席-嘔吐下痢-37.5度超": {
    book: "嘔吐",
    code: "×",
  },
  "欠席-嘔吐下痢-37.5度未満": {
    book: "嘔吐",
    code: "×",
  },
  "欠席-嘔吐下痢": {
    book: "嘔吐",
    code: "×",
  },
  "欠席-ケガ": {
    book: "ケガ",
    code: "×",
  },
  "欠席-その他病気": {
    book: "他病気",
    code: "×",
  },
  "欠席-体調不良": {
    book: "体調",
    code: "×",
  },
  "欠席-感染症-インフルエンザ-37.5度超": {
    book: "インフル",
    code: "テ",
  },
  "欠席-感染症-インフルエンザ-37.5度未満": {
    book: "インフル",
    code: "テ",
  },
  "欠席-感染症-インフルエンザ": {
    book: "インフル",
    code: "テ",
  },
  "欠席-感染症-新型コロナ-診断-37.5度超": {
    book: "コロナ診断",
    code: "テ",
  },
  "欠席-感染症-新型コロナ-診断-37.5度未満": {
    book: "コロナ診断",
    code: "テ",
  },
  "欠席-感染症-新型コロナ-診断": {
    book: "コロナ診断",
    code: "テ",
  },
  "欠席-感染症-新型コロナ-濃厚接触-37.5度超": {
    book: "コ濃厚接触",
    code: "テ",
  },
  "欠席-感染症-新型コロナ-濃厚接触-37.5度未満": {
    book: "コ濃厚接触",
    code: "テ",
  },
  "欠席-感染症-新型コロナ-濃厚接触": {
    book: "コ濃厚接触",
    code: "テ",
  },
  "欠席-感染症-新型コロナ-その他-37.5度超": {
    book: "コその他",
    code: "／",
  },
  "欠席-感染症-新型コロナ-その他-37.5度未満": {
    book: "コその他",
    code: "／",
  },
  "欠席-感染症-新型コロナ-その他": {
    book: "コその他",
    code: "／",
  },
  "欠席-感染症-水ぼうそう-37.5度超": {
    book: "水疱瘡",
    code: "テ",
  },
  "欠席-感染症-水ぼうそう-37.5度未満": {
    book: "水疱瘡",
    code: "テ",
  },
  "欠席-感染症-水ぼうそう": {
    book: "水疱瘡",
    code: "テ",
  },
  "欠席-感染症-おたふくかぜ-37.5度超": {
    book: "おたふく",
    code: "テ",
  },
  "欠席-感染症-おたふくかぜ-37.5度未満": {
    book: "おたふく",
    code: "テ",
  },
  "欠席-感染症-おたふくかぜ": {
    book: "おたふく",
    code: "テ",
  },
  "欠席-感染症-ふうしん-37.5度超": {
    book: "風疹",
    code: "テ",
  },
  "欠席-感染症-ふうしん-37.5度未満": {
    book: "風疹",
    code: "テ",
  },
  "欠席-感染症-ふうしん": {
    book: "風疹",
    code: "テ",
  },
  "欠席-感染症-はしか-37.5度超": {
    book: "麻疹",
    code: "テ",
  },
  "欠席-感染症-はしか-37.5度未満": {
    book: "麻疹",
    code: "テ",
  },
  "欠席-感染症-はしか": {
    book: "麻疹",
    code: "テ",
  },
  "欠席-感染症-その他-37.5度超": {
    book: "他感染",
    code: "テ",
  },
  "欠席-感染症-その他-37.5度未満": {
    book: "他感染",
    code: "テ",
  },
  "欠席-感染症-その他": {
    book: "他感染",
    code: "テ",
  },
  "欠席-忌引": {
    book: "忌引",
    code: "≠",
  },
  "欠席-特支-受診": {
    book: "受診",
    code: "×",
  },
  "欠席-特支-術後訓練": {
    book: "術後訓練",
    code: "×",
  },
  "欠席-特支-リハビリ訓練": {
    book: "リハビリ訓練",
    code: "／",
  },
  "欠席-特支-定期通院": {
    book: "定期通院",
    code: "／",
  },
  "欠席-特支-入所": {
    book: "入所",
    code: "／",
  },
  "欠席-特支-レスパイト": {
    book: "レスパイト",
    code: "／",
  },
  "欠席-特支-その他": {
    book: "特支他",
    code: "／",
  },
  "欠席-家の都合": {
    book: "都合",
    code: "／",
  },
  "欠席-その他": {
    book: "その他",
    code: "ト",
  },
  "遅刻-午前": {
    book: "遅刻",
    code: "y",
  },
  "遅刻-午前-給食○": {
    book: "遅刻",
    code: "y",
  },
  "遅刻-午前-給食×": {
    book: "遅刻",
    code: "y",
  },
  "遅刻-午後": {
    book: "遅刻",
    code: "y",
  },
  "遅刻-午後-給食○": {
    book: "遅刻",
    code: "y",
  },
  "遅刻-午後-給食×": {
    book: "遅刻",
    code: "y",
  },
  "早退-午前": {
    book: "早退",
    code: "○",
  },
  "早退-午前-給食○": {
    book: "早退",
    code: "○",
  },
  "早退-午前-給食×": {
    book: "早退",
    code: "○",
  },
  "早退-午後": {
    book: "早退",
    code: "○",
  },
  "早退-午後-給食○": {
    book: "早退",
    code: "○",
  },
  "早退-午後-給食×": {
    book: "早退",
    code: "○",
  },
  "登校-スクールバス": {
    book: "登バス",
    code: "",
  },
  "登校-自家用車": {
    book: "登自家用車",
    code: "",
  },
  "登校-福祉車両": {
    book: "登福祉車両",
    code: "",
  },
  "登校-自主登校": {
    book: "登自主",
    code: "",
  },
  "登校-その他": {
    book: "登他",
    code: "",
  },
  "下校-スクールバス": {
    book: "下バス",
    code: "",
  },
  "下校-自家用車": {
    book: "下自家用車",
    code: "",
  },
  "下校-福祉車両": {
    book: "下福祉車両",
    code: "",
  },
  "下校-自主下校": {
    book: "下自主",
    code: "",
  },
  "下校-放課後デイ": {
    book: "下放デイ",
    code: "",
  },
  "下校-その他": {
    book: "下他",
    code: "",
  },
  // 以下2019年度の旧欠席リスト
  "欠席-病気-風邪": {
    book: "風邪",
    code: "×",
  },
  "欠席-病気-インフルエンザ": {
    book: "インフル",
    code: "テ",
  },
  "欠席-病気-発熱": {
    book: "発熱",
    code: "×",
  },
  "欠席-病気-頭痛": {
    book: "頭痛",
    code: "×",
  },
  "欠席-病気-腹痛": {
    book: "腹痛",
    code: "×",
  },
  "欠席-病気-嘔吐・下痢-感染性胃腸炎": {
    book: "感胃腸炎",
    code: "×",
  },
  "欠席-病気-嘔吐・下痢-その他": {
    book: "嘔吐",
    code: "×",
  },
  "欠席-病気-その他": {
    book: "他病気",
    code: "×",
  },
  "欠席-怪我": {
    book: "怪我",
    code: "×",
  },
  "欠席-その他-家の都合": {
    book: "都合",
    code: "／",
  },
  "欠席-その他-忌引き": {
    book: "忌引",
    code: "≠",
  },
  "欠席-その他-その他": {
    book: "その他",
    code: "ト",
  },
  // '遅刻-午前登校'
  // '遅刻-午後登校'
  // '早退-午前下校'
  // '早退-午後下校'
  // '未登校-連絡なし'
};
