import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import { getData, createData } from "@/services/axios";
import { downloadStudentsCSV } from "@/services/common";
import RowItem from "../RowItem";
import moment from "moment";
import MessageModal from "../common/MessageModal.vue";
import Encoding from "encoding-japanese";
export default {
  name: "ElevateIndex",
  components: {
    RowItem,
    MessageModal
  },

  data() {
    return {
      graduatingLabel: "卒業学年",
      nextYear: moment().add(9, "month").year(),
      grades: [],
      graduatingGrades: [],
      newGrades: {},
      newClassrooms: {},
      isLoading: {
        classroomData: true,
        postData: false
      },
      showModal: false,
      error: [],
      date: null,
      done: false,
      isConfigured: false
    };
  },

  computed: { ...mapState(["studentAddress"]),
    fiscalDate: function () {
      return this.date ? moment(this.date).format("YYYY年MM月DD日") : "";
    }
  },

  created() {
    this.setData();
  },

  methods: {
    downloadStudentsCSV,

    setData() {
      getData(["grades?with=classroom", "reader/get_classroom_with_students_count", "schools"]).then(data => {
        let elevating = data[2].items[0].elevating; // 去年の年次処理であればリセット

        if (elevating && elevating.date) {
          const firstDateOfYear = moment().startOf("year");

          if (moment(elevating.date).isBefore(firstDateOfYear)) {
            elevating = null;
          }
        }

        this.isConfigured = Boolean(elevating);

        if (elevating && elevating.done) {
          this.done = elevating.done;
        }

        this.date = elevating && elevating.date ? elevating.date : this.nextYear + "-04-01";
        this.grades = data[0].items;
        this.classrooms = data[1].items;
        const newGrades = {};
        const newClassrooms = {};
        this.grades.forEach(v => {
          if (elevating && elevating.newGrades[v.id]) {
            newGrades[v.id] = elevating.newGrades[v.id];
          } else {
            const d = {
              name: v.name,
              beGraduating: false
            }; // 自動持ち上げ処理

            if (v.name.match(/^\d+年/)) {
              const maybeCurrentGradeNumber = v.name.match(/^\d+年/)[0].replace("年", "");

              if (Number(maybeCurrentGradeNumber)) {
                const postfix = v.name.match(/^\d+年/)[1] ? v.name.match(/^\d+年/)[1] : "";
                const newGradeName = Number(maybeCurrentGradeNumber) + 1 + "年" + postfix;

                if (this.grades.find(g => g.name === newGradeName)) {
                  d.name = newGradeName;
                } else {
                  d.name = this.graduatingLabel;
                  d.beGraduating = true;
                }
              }
            }

            newGrades[v.id] = d;
          }

          v.classroom.forEach(c => {
            if (elevating && elevating.newClassrooms[c.id]) {
              newClassrooms[c.id] = elevating.newClassrooms[c.id];
            } else {
              const d = {
                name: c.name,
                grade: c.pivot.grade_id
              }; // 自動持ち上げ処理

              if (newGrades[v.id].beGraduating) {
                d.name = d.name.replace(/^.+年/, "卒業学年");
              } else if (newGrades[v.id].name.match(/^\d+年/)) {
                const maybeNewGrade = newGrades[v.id].name.match(/^\d+年/)[0];
                d.name = d.name.replace(/^\d+年/, maybeNewGrade);
              }

              newClassrooms[c.id] = d;
            }
          });
        });
        this.classrooms.forEach(v => {
          this.grades = this.grades.map(g => {
            g.classroom = g.classroom.map(c => {
              if (v.id === c.id) {
                c.studentCount = v.students_count;
              }

              return c;
            }); //todo並び順の保存処理が動作したら有効にする
            // g.classroom.sort((a,b) => {
            //   if (a.index < b.index) return -1;
            //   if (a.index > b.index) return 1;
            //   return 0;
            // })

            return g;
          });
        });
        this.newGrades = newGrades;
        this.newClassrooms = newClassrooms;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.classroomData = false;
      });
    },

    handleChangeGrade(e, grade) {
      if (e.target.value === this.graduatingLabel) {
        this.newGrades[grade.id].beGraduating = true;
        return;
      } else {
        this.newGrades[grade.id].beGraduating = false;
      }

      const newGrade = e.target.value;
      const renameTargetClassroomIds = grade.classroom.map(c => c.id);
      Object.keys(this.newClassrooms).forEach(k => {
        if (renameTargetClassroomIds.indexOf(Number(k)) > -1) {
          const r = new RegExp(`^${grade.name}`);
          this.newClassrooms[k].name = this.newClassrooms[k].name.replace(r, newGrade);
        }
      });
    },

    handleChangeName(e, model) {
      model.name = Encoding.toHankakuCase(e.target.value).trim();
    },

    handleSave() {
      this.error = [];
      const gradeEmpty = Object.keys(this.newGrades).find(k => {
        return !this.newGrades[k].beGraduating && !this.newGrades[k].name;
      });

      if (gradeEmpty) {
        this.error.push("次年度の学年表記を選択してください");
      } // const classroomEmpty = Object.keys(this.newClassrooms).find(k =>{
      //   return !this.newClassrooms[k].name
      // })
      // if(classroomEmpty){
      //   this.error.push('次年度のクラス表記を入力してください')
      // }


      const classroomDuplicate = Object.keys(this.newClassrooms).find(k => {
        if (!this.newClassrooms[k].name) return false;
        return Object.keys(this.newClassrooms).find(k2 => k !== k2 && this.newClassrooms[k].name === this.newClassrooms[k2].name);
      });

      if (classroomDuplicate) {
        this.error.push("次年度の組表記が重複しています");
      }

      if (this.error.length > 0) return;
      this.isLoading.postData = false;
      createData("writer/write_school_elevating", {
        date: this.date,
        newGrades: this.newGrades,
        newClassrooms: this.newClassrooms
      }).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "年次処理の設定を保存しました",
          title: "保存完了"
        });
        this.isConfigured = true;
        this.showModal = true;
        this.isLoading.postData = false;
      }).catch(error => {
        console.log(error);
      });
    },

    saved() {
      this.showModal = false;
    }

  }
};