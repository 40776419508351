import axios from "axios";
import { getIdToken, logout } from "@/services/auth";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // デフォルトのエラーハンドラを設定する。詳細なエラーメッセージをコンソールに出力する
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    console.log(error.toJSON());
    throw error;
  }
);

export const getData = (target) => {
  const p = (t) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_API_ENDPOINT_BASE + "/" + t, {
          headers: {
            Authorization: `Bearer ${getIdToken()}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            logout();
          }
          reject(error);
        });
    });
  };
  if (typeof target === "string") {
    return p(target);
  } else if (Array.isArray(target)) {
    return Promise.all(target.map((t) => p(t)));
  } else {
    return new Promise((resolve, reject) => {
      reject(null);
    });
  }
};

export const createData = (target, payload, extraHeader = null) => {
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${getIdToken()}`,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT_BASE + "/" + target,
        payload,
        Object.assign(requestHeader, extraHeader)
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout();
        }
        reject(error);
      });
  });
};

export const updateData = (target, payload, extraHeader = null) => {
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${getIdToken()}`,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .put(
        process.env.VUE_APP_API_ENDPOINT_BASE + "/" + target,
        payload,
        Object.assign(requestHeader, extraHeader)
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout();
        }

        reject(error);
      });
  });
};

export const deleteData = (target) => {
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${getIdToken()}`,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .delete(
        process.env.VUE_APP_API_ENDPOINT_BASE + "/" + target,
        requestHeader
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout();
        }

        reject(error);
      });
  });
};

export const downloadData = (target, params, filename) => {
  return axios
    .post(process.env.VUE_APP_API_ENDPOINT_BASE + "/" + target, params, {
      headers: {
        Authorization: `Bearer ${getIdToken()}`,
      },
      responseType: "blob",
    })
    .then((response) => {
      const blob = new Blob([response.data]);
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getDataByPostMethod = (target, payload) => {
  const p = (t) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_ENDPOINT_BASE + "/" + t, payload, {
          headers: {
            Authorization: `Bearer ${getIdToken()}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            logout();
          }
          reject(error);
        });
    });
  };
  if (typeof target === "string") {
    return p(target);
  } else if (Array.isArray(target)) {
    return Promise.all(target.map((t) => p(t)));
  } else {
    return new Promise((resolve, reject) => {
      reject(null);
    });
  }
};
