var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('section', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    staticClass: "scroll_frame grab-bing"
  }, [_c('table', [_c('thead', [_c('tr', [_vm._m(0), _vm._l(_setup.dashboardTableList.firstLineHeader, function (item, i) {
    return _c('th', {
      key: i,
      attrs: {
        "rowspan": item.rowspan,
        "colspan": item.colspan
      }
    }, [_c('p', {
      staticClass: "side"
    }, [_vm._v(" " + _vm._s(item.label) + " ")])]);
  })], 2), _c('tr', _vm._l(_setup.dashboardTableList.secondLineHeader, function (item, i) {
    return _c('th', {
      key: i,
      attrs: {
        "rowspan": item.rowspan,
        "colspan": item.colspan
      }
    }, [_c('p', {
      class: {
        side: item.rowspan === 1
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")])]);
  }), 0), _c('tr', _vm._l(_setup.dashboardTableList.thirdLineHeader, function (item, i) {
    return _c('th', {
      key: i
    }, [_c('p', [_vm._v(_vm._s(item.label))])]);
  }), 0)]), _c('tfoot', [_c('tr', [_vm._m(1), _vm._l(_setup.dashboardTableList.firstLineHeader, function (item, i) {
    return _c('th', {
      key: i,
      attrs: {
        "rowspan": item.rowspan,
        "colspan": item.colspan
      }
    }, [_c('p', {
      staticClass: "side"
    }, [_vm._v(" " + _vm._s(item.label) + " ")])]);
  })], 2), _c('tr', _vm._l(_setup.dashboardTableList.secondLineHeader, function (item, i) {
    return _c('th', {
      key: i,
      attrs: {
        "rowspan": item.rowspan,
        "colspan": item.colspan
      }
    }, [_c('p', {
      class: {
        side: item.rowspan === 1
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")])]);
  }), 0), _c('tr', _vm._l(_setup.dashboardTableList.thirdLineHeader, function (item, i) {
    return _c('th', {
      key: i
    }, [_c('p', [_vm._v(_vm._s(item.label))])]);
  }), 0)]), _vm.isLoading ? _c('tbody', _vm._l(5, function (n) {
    return _c('tr', {
      key: n
    }, [_c('td', {
      attrs: {
        "colspan": "28"
      }
    }, [_c('content-placeholders', [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)], 1)]);
  }), 0) : _c('tbody', _vm._l(_vm.absenceByClassroom, function (classroom, index) {
    return _c('tr', {
      key: index,
      class: {
        gray_span: index % 2 !== 0
      }
    }, [_c('td', [classroom.id ? _c('router-link', {
      attrs: {
        "to": {
          name: 'attendances',
          query: {
            classroom_id: classroom.id
          }
        }
      }
    }, [_c('p', [_vm._v(_vm._s(classroom.name))])]) : _c('router-link', {
      attrs: {
        "to": {
          name: 'attendances'
        }
      }
    }, [_c('p', [_vm._v(_vm._s(classroom.name))])])], 1), _c('td', {
      staticClass: "tac"
    }, [_c('p', [_vm._v(_vm._s(classroom.students_count))])]), _vm._l(_setup.dashboardTableList.key, function (item, i) {
      return _c('td', {
        key: i,
        staticClass: "tac"
      }, [_c('p', [_vm._v(_vm._s(_vm._f("zeroFallback")(classroom.absence[item.label])))]), item.percent ? _c('p', [_vm._v(" " + _vm._s(_vm.decimalAlignmentPercent(classroom.absence[item.label], classroom.students_count)) + " ")]) : _vm._e()]);
    })], 2);
  }), 0)])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_c('p', {
    staticClass: "side"
  }, [_vm._v("クラス")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_c('p', {
    staticClass: "side"
  }, [_vm._v("クラス")])]);
}];
export { render, staticRenderFns };