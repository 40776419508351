import { mapState } from "vuex";
import { getData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import RowItem from "../RowItem";
export default {
  name: "SelectStudent",
  components: {
    RowItem,
    LoadingMessage
  },

  data() {
    return {
      students: [],
      filteredStudents: [],
      selectedClassroom: false,
      selectedStudent: {},
      filterKeyword: "",
      isFetching: false
    };
  },

  head: {
    title: function () {
      return {
        inner: this.pageTitle
      };
    },
    meta: []
  },
  computed: { ...mapState(["grades", "classrooms", "studentAddress"]),
    pageTitle: function () {
      return "欠席登録 - " + this.studentAddress + "選択";
    }
  },

  created() {
    this.setData();
  },

  methods: {
    setData: function () {},
    fetchStudents: function () {
      this.isFetching = true;
      this.students = [];
      this.selectedStudent = {};
      getData("students?classrooms[]=" + this.selectedClassroom).then(data => {
        this.students = this.filteredStudents = data.items;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isFetching = false;
      });
    },
    filterStudents: function () {
      let students = this.students;
      let keyword = this.filterKeyword;

      if (keyword) {
        students = students.filter(v => v.name.indexOf(keyword) > -1 || v.ruby.indexOf(keyword) > -1);
      }

      this.selectedStudent = {};
      this.filteredStudents = students;
    }
  }
};