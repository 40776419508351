import { ref } from "vue";
import { getData, createData } from "@/services/axios";
import { useStore } from "vue2-helpers/vuex";
import SettingRow from "@/components/dekitus/SettingRow.vue";
import LoadingMessage from "@/components/common/LoadingMessage.vue";
import DekitusIntroduction from "@/components/common/DekitusIntroduction";
export default {
  __name: 'Setting',

  setup(__props) {
    const dekitusIds = ref([]);
    const dekitusStudents = ref([]);
    const grades = ref([]);
    const dekitusIdUpdate = ref({});
    const isLoading = ref(false);
    const isShowCompleteModal = ref(false);
    const store = useStore();

    const handleStudentChange = data => {
      dekitusIdUpdate.value[data.dekitusId] = data.studentId;
    };

    const handleSubmit = async () => {
      try {
        isLoading.value = true;
        await createData("dekitus_student", dekitusIdUpdate.value);
        store.dispatch("commitModalMessage", {
          message: "学習支援ツールIDの紐づけを登録しました",
          title: "登録完了"
        });
      } catch (error) {
        store.dispatch("commitModalMessage", {
          message: "学習支援ツールIDの紐づけに失敗しました",
          title: "登録失敗"
        });
        console.log(error);
      }

      isShowCompleteModal.value = true;
      isLoading.value = false;
      setData();
    };

    const setData = async () => {
      try {
        isLoading.value = true;
        const data = await getData(["dekitus_id_stocks", "dekitus_student?with=student.classroom", "grades?with=classroom.student"]);

        if (data) {
          dekitusIds.value = data[0].items[0].dekitus_ids;
          dekitusStudents.value = data[1].items;
          grades.value = data[2].items;
        }

        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
        console.log(error);
      }
    };

    setData();
    return {
      __sfc: true,
      dekitusIds,
      dekitusStudents,
      grades,
      dekitusIdUpdate,
      isLoading,
      isShowCompleteModal,
      store,
      handleStudentChange,
      handleSubmit,
      setData,
      SettingRow,
      LoadingMessage,
      DekitusIntroduction
    };
  }

};