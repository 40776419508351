import "core-js/modules/es.array.push.js";
import Vue from "vue";
import { updateData, getDataByPostMethod } from "@/services/axios";
import moment from "moment";
import VueQriously from "vue-qriously";
import { mapState } from "vuex";
Vue.use(VueQriously);
export default {
  name: "StudentsIndex",
  data: () => ({
    env: process.env,
    isLoading: true,
    showNotice: false,
    students: []
  }),
  head: {
    title: () => ({
      inner: "QR Code"
    }),
    meta: []
  },
  computed: { ...mapState(["studentAddress"])
  },

  created() {
    if (this.$route.params.student_id) {
      this.setData();
    } else {
      this.isLoading = false;
      this.showNotice = true;
    }
  },

  methods: {
    setData() {
      updateData("writer/update_student_qrcode", {
        student_id: this.$route.params.student_id
      }).then(() => {
        return getDataByPostMethod("reader/get_students_qrcode", {
          student_id: this.$route.params.student_id
        });
      }).then(data => {
        data.items.forEach(v => {
          v.expire_on = moment(v.gca_entry_key.expire_on).format("YYYY/MM/DD");
          this.students.push(v);
        });
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading = false;
      });
    },

    QRdata(student) {
      return process.env.VUE_APP_GUARDIAN_APP_URL + "/entry/" + student.gca_entry_key.key;
    }

  }
};