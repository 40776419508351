import "core-js/modules/es.array.push.js";
import MessageModal from "../common/MessageModal.vue";
import store from "../../services/store.js";
import { mapState } from "vuex";
import { createData, getData, updateData, deleteData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import emailValidator from "email-validator";
import RowItem from "../RowItem";
import ConfirmModal from "@/components/common/ConfirmModal";
export default {
  store,
  name: "ParticipantEdit",
  components: {
    RowItem,
    MessageModal,
    LoadingMessage,
    ConfirmModal
  },

  data() {
    return {
      id: this.$route.params.id,
      showModal: false,
      participant: {
        name: "",
        ruby: "",
        belonging: "",
        locale: "ja-JP",
        email: "",
        phone: "",
        normal_receive_method: "email",
        emergency_receive_method: "email",
        memo: "",
        role_id: 41
      },
      isLoading: {
        getData: true,
        postData: false,
        phoneExists: false
      },
      loadingMessage: "",
      error: {
        name: null,
        phone: null,
        email: null
      },
      isNewNumber: false,
      showConfirmModal: false,
      confirmMessageTitle: "",
      confirmMessage: ""
    };
  },

  computed: { ...mapState(["classrooms", "me", "capabilities"])
  },

  created() {
    if (this.$store.state.me.role_id > 11) {
      this.$router.push({
        name: "dashboard"
      });
    }

    this.setData();
  },

  methods: {
    checkPhoneExists() {
      this.isNewNumber = false;

      if (this.participant.phone !== "") {
        this.isLoading.phoneExists = true;
        getData("reader/get_participant_from_phone?phone=" + this.participant.phone + (this.id ? "&participant_id=" + this.id : "")).then(data => {
          if (data.items[0]) {
            this.participant.phone = "";
            this.error.phone = "既にこの電話番号は他の関係者または保護者に登録されています。別の番号を入力してください。";
          } else {
            this.isNewNumber = true;
          }
        }).catch(error => {
          console.log(error);
        }).then(() => {
          this.isLoading.phoneExists = false;
        });
      }
    },

    setData: function () {
      // params :id が来ている場合は編集なので編集データを取り出す
      if (this.id) {
        getData("participants/" + this.id + "?with=user").then(data => {
          this.participant = data.items.map(v => {
            v.email = v.user.email;
            return v;
          });
          this.participant = this.participant[0];
        }).catch(error => {
          console.log(error);
        }).then(() => {
          this.isLoading.getData = false;
        });
      } else {
        this.isLoading.getData = false;
      }
    },

    postParticipant() {
      createData("writer/write_participant", this.participant).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "関係者情報登録が完了しました",
          title: "登録完了"
        });
        this.showModal = true;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.postData = false;
      });
    },

    putParticipant() {
      updateData("writer/write_participant/" + this.id, this.participant).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "関係者情報更新が完了しました",
          title: "更新完了"
        });
        this.showModal = true;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.postData = false;
      });
    },

    saveData: function () {
      let valid = true;
      this.error = {
        name: null,
        phone: null
      };

      if (!this.participant.name) {
        this.error.name = "名前を入力してください。";
        valid = false;
      } else {
        this.participant.name = this.participant.name.replace(/\s+/, " ");
      }

      if (this.participant.ruby) {
        this.participant.ruby = this.participant.ruby.replace(/\s+/, " ");
      }

      if (this.participant.phone) {
        const regex = new RegExp(/^[0-9]{10,11}$/);

        if (!regex.test(this.participant.phone)) {
          this.error.phone = "半角数字のみ、10桁あるいは11桁で入力してください。";
          valid = false;
        }
      } //緊急のお知らせ無しのプランで、メールアドレスが存在せず電話番号のみのときはバリデーションで止まらないように便宜上emergency_receive_methodをphoneにしてあげる


      if (!this.capabilities.enableEmergencyCall && !this.participant.email && this.participant.emergency_receive_method === "email") {
        this.participant.emergency_receive_method = "phone";
      }

      if (this.participant.normal_receive_method === "email" || this.participant.emergency_receive_method === "email" || this.participant.emergency_receive_method === "both") {
        if (!this.participant.email) {
          this.error.email = "お知らせ方法にメールを指定している場合はメールアドレスを入力してください。";
          valid = false;
        } else if (!emailValidator.validate(this.participant.email)) {
          this.error.email = "メールアドレスの形式が正しくありません。";
          valid = false;
        }
      }

      if (this.participant.normal_receive_method === "phone" || this.participant.emergency_receive_method === "phone" || this.participant.emergency_receive_method === "phone") {
        if (!this.participant.phone) {
          this.error.phone = "お知らせ方法に電話を指定している場合は電話番号を入力してください。";
          valid = false;
        }
      }

      if (!valid) {
        return;
      }

      this.isLoading.postData = true;

      if (this.id) {
        this.loadingMessage = "関係者情報を更新しています。";
        this.putParticipant();
      } else {
        this.loadingMessage = "関係者情報を登録しています。";
        this.postParticipant();
      }
    },

    modalClosed() {
      this.showModal = false;
      this.$router.push({
        name: "participants"
      });
    },

    confirmDelete() {
      this.confirmMessageTitle = "関係者情報削除";
      this.confirmMessage = "関係者情報を削除します。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
    },

    removeData() {
      this.showConfirmModal = false;
      this.buttonDisabled = true;
      deleteData("participants/" + this.$route.params.id).then(result => {
        if (result.error_code) {
          switch (result.error_code) {
            case 404:
              this.$store.dispatch("commitModalMessage", {
                message: "関係者の更新に失敗しました (Auth0 user not found)",
                title: "エラー"
              });
              break;

            default:
              this.$store.dispatch("commitModalMessage", {
                message: "関係者の更新に失敗しました (" + result.errors + ")",
                title: "エラー"
              });
              break;
          }
        } else {
          this.$store.dispatch("commitModalMessage", {
            message: "関係者データを削除しました",
            title: "削除完了"
          });
        }
      }).catch(error => {
        this.$store.dispatch("commitModalMessage", {
          message: "関係者データの削除に失敗しました",
          title: "エラー"
        });
        console.log(error);
      }).then(() => {
        this.showModal = true;
        this.buttonDisabled = false;
      });
    },

    cancelConfirm() {
      this.showConfirmModal = false;
    }

  }
};