import moment from "moment";
import { getData } from "@/services/axios";

import { useRoute } from 'vue-router/composables';
import { ref, watch } from 'vue';
export default {
  __name: 'GovForumShow',

  setup(__props) {
    // @ts-check
    const route = useRoute();
    const forum = ref({});
    const forumList = ref([]);

    const setData = async () => {
      let data = await getData(`government_forums/${route.params.id}`);
      forum.value = data.items;
      const params = new URLSearchParams({
        target: forum?.value.target,
        exclude: route.params.id
      });
      data = await getData(`government_forums?${params.toString()}`);
      forumList.value = data.items;
    }; // created


    setData();
    watch(() => route.params.id, async () => {
      await setData();
    });
    return {
      __sfc: true,
      route,
      forum,
      forumList,
      setData,
      moment
    };
  }

};