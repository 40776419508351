import { getData } from "@/services/axios";
import RowItem from "../../RowItem";
export default {
  name: "TemplateIndex",
  components: {
    RowItem
  },
  data: () => ({
    filterOptions: {
      keyword: ""
    },
    templates: {},
    filteredTemplates: [],
    isLoading: true
  }),
  head: {
    title: () => ({
      inner: "テンプレート一覧"
    }),
    meta: []
  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      getData("messages/templates").then(data => {
        this.templates = this.filteredTemplates = data.items;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading = false;
      });
    },

    listFilter() {
      let newList = this.templates;
      const keyword = this.filterOptions.keyword;

      if (keyword) {
        newList = newList.filter(v => Object.keys(v).some(k => String(v[k]).indexOf(keyword) > -1));
      }

      this.filteredTemplates = newList;
    },

    getTitle(message) {
      return message.template_name ? message.template_name : message.subject;
    }

  }
};