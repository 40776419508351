var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_absence_monthly"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("全校" + _vm._s(_setup.studentAddress) + " 学習履歴一覧 -月別")]), _c(_setup.MonthlyDekitus), _c('section', {
    staticClass: "tac"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'dekitus'
      }
    }
  }, [_vm._v(" 学習履歴一覧に戻る ")])], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };