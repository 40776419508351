import { handleAuthentication } from "@/services/auth";
export default {
  name: "",

  created() {
    this.$nextTick(() => {
      handleAuthentication();
    });
  }

};