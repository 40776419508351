import moment from "moment";
import { getData } from "@/services/axios";
import { mapState } from "vuex";
import { getAbsentAlert, assignAbsentBudge, downloadStudentsCSV } from "@/services/common";
import RowItem from "../RowItem";
import { iconColor } from "@/consts/iconColor";
export default {
  name: "AttendanceClassroom",
  components: {
    RowItem
  },

  data() {
    return {
      date: "",
      days: moment().daysInMonth(),
      students: [],
      checkedClassrooms: [],
      byClassrooms: [],
      isLoading: true
    };
  },

  computed: { ...mapState(["classrooms", "grades", "studentAddress"]),
    formattedDate: function () {
      return moment(this.date).format("YYYY年MM月DD日(ddd)");
    }
  },

  created() {
    let ids = this.$route.query.id;

    if (ids) {
      //前のページからrouterで遷移してきた場合と、画面再読込の場合でパラメータの解釈が変わる
      if (!Array.isArray(this.$route.query.id)) {
        ids = [this.$route.query.id];
      }

      this.checkedClassrooms = ids;
    }

    this.date = this.$route.query.date ? this.$route.query.date : moment().format("YYYY-MM-DD");
    this.setData();
  },

  methods: {
    iconColor,
    downloadStudentsCSV,

    async setData() {
      this.isLoading = true;
      let query = "date=" + this.date;
      this.checkedClassrooms.forEach(v => query += "&classrooms[]=" + v);
      const a = await getAbsentAlert();
      const absentList3 = a.absentList3;
      const absentList20 = a.absentList20;
      const absentList30 = a.absentList30;
      const d = await getData("reader/get_classroom_students_with_attendance?" + query);
      this.byClassrooms = d.items.map(v => {
        v.students.forEach((student, i) => {
          if (student.attendance[0]) {
            student.attendanceType = student.attendance[0].type;
          } else {
            student.attendanceType = "";
          }

          student.absents = assignAbsentBudge(student, absentList3, absentList20, absentList30);
          v.students[i] = student;
        });
        return v;
      });
      this.isLoading = false;
    },

    getAttendanceClass(current, attendances) {
      let index = attendances.findIndex(({
        date
      }) => current.isSame(moment(date)));

      if (index > -1) {
        if (attendances[index].type.indexOf("欠席") > -1) {
          return "type_absence";
        }

        if (attendances[index].type.indexOf("早退") > -1) {
          return "type_early";
        }

        if (attendances[index].type.indexOf("遅刻") > -1) {
          return "type_late";
        }
      }
    }

  }
};