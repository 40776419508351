var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main"
  }, [_c('h1', [_vm._v(" " + _vm._s(_vm.news.title) + " ")]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.news.contents)
    }
  }), _c('div', {
    staticClass: "button_wrapper tac"
  }, [_c('button', {
    staticClass: "return_button",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("戻る")])])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };