export default {
  __name: 'MonthlySchoolDay',
  props: {
    mode: {
      type: String,
      default: "attendance"
    },
    days: {
      type: Number,
      required: true
    },
    monthData: {
      /** @type {PropType<Object<string, any>[]>} */
      type: Array,
      required: true
    }
  },

  setup(__props) {
    // @ts-check
    return {
      __sfc: true
    };
  }

};