var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "calendar__heading"
  }, [_c('a', {
    staticClass: "prev",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleMonthPager('sub');
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-angle-left"
  })]), _c('p', {
    staticClass: "month"
  }, [_vm._v(" " + _vm._s(_vm.localYearMonth) + " ")]), _c('a', {
    staticClass: "next",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleMonthPager('add');
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-angle-right"
  })])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };