var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "button_wrap"
  }, [_c('section', {
    staticClass: "switch_button"
  }, [_c('router-link', {
    staticClass: "item switch_button__left",
    class: {
      active: _setup.route.name === 'forums'
    },
    attrs: {
      "to": {
        name: 'forums'
      }
    }
  }, [_vm._v(" 学校内 ")]), _c('router-link', {
    staticClass: "item",
    class: {
      active: _setup.route.name === 'gov.forum' && _setup.route.query.target === 'school'
    },
    attrs: {
      "to": {
        name: 'gov.forum',
        query: {
          target: 'school'
        }
      }
    }
  }, [_vm._v(" 自治体→先生 ")]), _c('router-link', {
    staticClass: "item switch_button__right",
    class: {
      active: _setup.route.name === 'gov.forum' && _setup.route.query.target === 'guardian'
    },
    attrs: {
      "to": {
        name: 'gov.forum',
        query: {
          target: 'guardian'
        }
      }
    }
  }, [_vm._v(" 自治体→保護者 ")])], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };