export default {
  __name: 'ConfirmSlotModal',
  props: {
    clickOk: Function,
    clickCancel: Function
  },

  setup(__props) {
    return {
      __sfc: true
    };
  }

};