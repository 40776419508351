import "core-js/modules/es.array.push.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_student_data"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v(_vm._s(_vm.studentAddress) + "・保護者情報")]), _vm.isLoading.getData ? _c('loading-message') : _vm._e(), _vm.isLoading.handleData ? _c('loading-message', {
    attrs: {
      "loading-message": _vm.loadingMessage
    }
  }) : _c('div', [_c('div', {
    staticClass: "data_wrap"
  }, [_c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("クラス")]), _c('p', {
    staticClass: "col_10"
  }, [_vm._v(" " + _vm._s(_vm.student.classroom ? _vm.student.classroom.name : "") + " ")])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("氏名")]), _c('p', {
    staticClass: "col_10"
  }, [_vm._v(" " + _vm._s(_vm.student.name) + " ")])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("ふりがな")]), _c('p', {
    staticClass: "col_10"
  }, [_vm._v(" " + _vm._s(_vm.student.ruby) + " ")])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("性別")]), _c('p', {
    staticClass: "col_10"
  }, [_vm._v(" " + _vm._s(["", "男性", "女性"][_vm.student.gender]) + " ")])]), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "edit_button",
    attrs: {
      "to": {
        name: 'students.edit',
        params: {
          id: _vm.student.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + "情報編集 ")])], 1)], 1), _vm._l(_vm.student.guardian, function (guardian, i) {
    return _c('section', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.me.role_id <= 11,
        expression: "me.role_id <= 11"
      }],
      key: i,
      staticClass: "data_wrap"
    }, [_c('h2', {
      staticClass: "data_wrap__heading"
    }, [_vm._v("保護者情報")]), _c('row-item', {
      staticClass: "item"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v("氏名")]), _c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s(guardian.name) + " "), guardian.user.role_id === 21 ? _c('span', {
      staticClass: "is_questionee"
    }, [_c('i', {
      staticClass: "fas fa-clipboard-check"
    }), _vm._v("アンケート回答者")]) : _vm._e()])]), _c('row-item', {
      staticClass: "item"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v("ふりがな")]), _c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s(guardian.ruby) + " ")])]), _c('row-item', {
      staticClass: "item"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v("続柄")]), _c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s(guardian.relationship) + " ")])]), _c('row-item', {
      staticClass: "item"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v("言語")]), _c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s(guardian.locale ? _vm.labelLocale(guardian.locale) : "") + " ")])]), _c('row-item', {
      staticClass: "item"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v("メールアドレス")]), _c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s(guardian.user ? guardian.user.email : "") + " ")])]), _c('row-item', {
      staticClass: "item"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v("電話番号")]), _c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s(guardian.phone) + " ")])]), _c('row-item', {
      staticClass: "item"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v("お知らせ方法")]), _c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s({
      both: "両方",
      email: "メールのみ",
      phone: "電話のみ"
    }[guardian.normal_receive_method]) + " ")])]), _vm.capabilities.enableEmergencyCall ? _c('row-item', {
      staticClass: "item"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v("緊急のお知らせ")]), _c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s({
      both: "両方",
      email: "メールのみ",
      phone: "電話のみ"
    }[guardian.emergency_receive_method]) + " ")])]) : _vm._e(), _c('row-item', {
      staticClass: "item"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v("メモ")]), _c('p', {
      staticClass: "col_10"
    }, [_vm._v(" " + _vm._s(guardian.memo) + " ")])]), _c('div', {
      staticClass: "gray_frame common_form__button_wrap"
    }, [_c('router-link', {
      staticClass: "small_edit_button",
      attrs: {
        "to": {
          name: 'guardians.edit',
          params: {
            id: guardian.id
          },
          query: {
            student_id: _vm.student.id
          }
        }
      }
    }, [_vm._v(" 保護者編集 ")]), _c('button', {
      staticClass: "small_delete_button",
      on: {
        "click": function ($event) {
          return _vm.confirmRemoveGuardian(guardian.id);
        }
      }
    }, [_vm._v(" 保護者削除 ")]), guardian.user.role_id !== 21 ? _c('button', {
      staticClass: "small_edit_button",
      on: {
        "click": function ($event) {
          return _vm.confirmSwitchingPrimaryGuardian(guardian.id);
        }
      }
    }, [_vm._v(" アンケート回答者に指定する ")]) : _vm._e()], 1)], 1);
  }), _c('div', {
    staticClass: "tac"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'students'
      }
    }
  }, [_vm._v(" 一覧に戻る ")]), _vm.me.role_id <= 11 ? _c('button', {
    staticClass: "add_button",
    attrs: {
      "disabled": _vm.student.guardian.length >= _vm.guardiansLimit
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'guardians.create',
          query: {
            student_id: _vm.student.id
          },
          params: {
            guardians: _vm.student.guardian.length
          }
        });
      }
    }
  }, [_vm._v(" 保護者追加 ")]) : _vm._e(), _c('button', {
    staticClass: "delete_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.confirmRemoveStudent(_vm.student.id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + "削除 ")])], 1)], 2), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.closeModal
    }
  }) : _vm._e(), _vm.showConfirmModal ? _c('confirm-modal', {
    attrs: {
      "confirm-message": _vm.confirmMessage,
      "confirm-message-title": _vm.confirmMessageTitle
    },
    on: {
      "clickOK": _vm.handleData,
      "clickCancel": _vm.cancelConfirm
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };