var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_absence_index"
  }, [_c('div', {
    staticClass: "page-header"
  }, [_c('h1', {
    staticClass: "page-header__title"
  }, [_vm._v(" " + _vm._s(_vm.fiscalYear) + "年度の欠席履歴/学習履歴 ")]), _c('div', {
    staticClass: "page-header__button"
  }, [_vm.school.meta && _vm.school.meta.showAttendanceCSVDownload ? _c('button', {
    staticClass: "download_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.downloadCSV.apply(null, arguments);
      }
    }
  }, [_vm._v(" 出欠席一覧ダウンロード ")]) : _vm._e()])]), _c(_setup.AbsentAlertList), _c('h1', {
    staticClass: "title--page"
  }, [_vm._v(_vm._s(_vm.formattedDate) + "の出欠席一覧")]), _c('form', {
    staticClass: "gray_frame",
    attrs: {
      "action": "",
      "method": "get"
    }
  }, [_c(_setup.RowItem, [_c('div', {
    staticClass: "col_4"
  }, [_c('label', {
    staticClass: "common_form--heading"
  }, [_vm._v(_vm._s(_vm.studentAddress) + "氏名")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterStatus.keyword,
      expression: "filterStatus.keyword"
    }],
    staticClass: "common_form--input col_8",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.filterStatus.keyword
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.filterStatus, "keyword", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col_3"
  }, [_c('label', {
    staticClass: "common_form--heading",
    attrs: {
      "for": "select_class"
    }
  }, [_vm._v("クラス")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterStatus.classroom,
      expression: "filterStatus.classroom"
    }],
    staticClass: "common_form--select",
    attrs: {
      "id": "select_class",
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.filterStatus, "classroom", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("すべて")]), _vm._l(_setup.classrooms, function (classroom, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": classroom.id
      }
    }, [_vm._v(" " + _vm._s(classroom.name) + " ")]);
  })], 2)]), _c('div', {
    staticClass: "col_2"
  }, [_c('label', {
    staticClass: "common_form--heading",
    attrs: {
      "for": "select_type"
    }
  }, [_vm._v("事由")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterStatus.type,
      expression: "filterStatus.type"
    }],
    staticClass: "common_form--select",
    attrs: {
      "id": "select_type",
      "name": "select_type"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.filterStatus, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("すべて")]), _c('option', {
    attrs: {
      "value": "欠席"
    }
  }, [_vm._v("欠席")]), _c('option', {
    attrs: {
      "value": "遅刻"
    }
  }, [_vm._v("遅刻")]), _c('option', {
    attrs: {
      "value": "早退"
    }
  }, [_vm._v("早退")]), _c('option', {
    attrs: {
      "value": "登下校"
    }
  }, [_vm._v("登下校")])])]), _c('div', {
    staticClass: "col_2"
  }, [_c('VueCtkDateTimePicker', {
    attrs: {
      "label": "日付",
      "no-button-now": "",
      "only-date": "",
      "formatted": "LL",
      "locale": "ja",
      "output-format": "YYYY-MM-DD",
      "no-clear-button": ""
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c('div', {
    staticClass: "col_1 tar"
  }, [_c('input', {
    staticClass: "submit_button",
    attrs: {
      "type": "submit",
      "value": "検索"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.filterAttendances.apply(null, arguments);
      }
    }
  })])])], 1), _c('section', {
    staticClass: "border_heading_frame wide_layout"
  }, [_c('h3', {
    staticClass: "heading"
  }, [_vm._v("出欠席一覧")]), _c('div', {
    staticClass: "content"
  }, [_c('dl', {
    staticClass: "data_box"
  }, [_c('dt', [_vm._v("欠席")]), _c('dd', [_vm._v(" " + _vm._s(_vm.calcAttendance(_vm.attendances, "absent")) + "名 (" + _vm._s(Math.round(_vm.calcAttendance(_vm.attendances, "absent") / _setup.totalStudentCount * 1000) / 10) + "%) ")]), _c('dt', [_vm._v("遅刻")]), _c('dd', [_vm._v(_vm._s(_vm.calcAttendance(_vm.attendances, "late")) + "名")]), _c('dt', [_vm._v("早退")]), _c('dd', [_vm._v(_vm._s(_vm.calcAttendance(_vm.attendances, "leave")) + "名")]), _c('dt', [_vm._v("未登校")]), _c('dd', [_vm._v(_vm._s(_vm.calcAttendance(_vm.attendances, "not_attend")) + "名")]), _c('dt', [_vm._v("登下校")]), _c('dd', [_vm._v(_vm._s(_vm.calcAttendance(_vm.attendances, "way")) + "名")])])])]), _setup.schoolPlan.temperature ? _c('section', {
    staticClass: "border_heading_frame wide_layout"
  }, [_c('h3', {
    staticClass: "heading"
  }, [_vm._v("検温一覧")]), _c('div', {
    staticClass: "content"
  }, [_c('dl', {
    staticClass: "data_box"
  }, [_c('dt', [_vm._v("全校")]), _c('dd', [_vm._v(_vm._s(_setup.totalStudentCount) + "名")]), _c('dt', [_vm._v("検温者数")]), _c('dd', [_vm._v(" " + _vm._s(_setup.totalTemperatureCount) + "名 (" + _vm._s(Math.round(_setup.totalTemperatureCount / _setup.totalStudentCount * 1000) / 10) + "%) ")])])])]) : _vm._e(), _c('section', {
    staticClass: "fake_scroll_table"
  }, [_vm.isLoading ? _c('content-placeholders', _vm._l(5, function (n) {
    return _c('div', {
      key: n
    }, [_c('article', {
      staticClass: "fake_scroll_table__body"
    }, [_c('p', {
      staticClass: "fake_scroll_table__span"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "fake_scroll_table__body"
  }, _vm._l(_vm.byClassrooms, function (value, i) {
    return _c('div', {
      key: i
    }, [_c('article', {
      staticClass: "fake_scroll_table__tr"
    }, [_c('p', {
      staticClass: "fake_scroll_table__heading col_2 tal"
    }, [_c('span', [_vm._v(_vm._s(value.classroom.name))]), _c('span', [_vm._v("生徒数:" + _vm._s(value.classroom.students_count) + "名")])]), _c('p', {
      staticClass: "fake_scroll_table__heading all_data col_7"
    }, [_c('span', [_vm._v("欠席:" + _vm._s(_vm.calcAttendance(value.attendances, "absent")) + "名 (" + _vm._s(value.classroom.students_count > 0 ? Math.round(_vm.calcAttendance(value.attendances, "absent") / value.classroom.students_count * 1000) / 10 : 0) + "%)")]), _c('span', [_vm._v("遅刻:" + _vm._s(_vm.calcAttendance(value.attendances, "late")) + "名")]), _c('span', [_vm._v("早退:" + _vm._s(_vm.calcAttendance(value.attendances, "leave")) + "名")]), _c('span', [_vm._v("未登校:" + _vm._s(_vm.calcAttendance(value.attendances, "not_attend")) + "名 ")]), _c('span', [_vm._v("登下校:" + _vm._s(_vm.calcAttendance(value.attendances, "way")) + "名")]), _setup.schoolPlan.temperature ? _c('span', [_vm._v(" 検温:" + _vm._s(_setup.temperatureCount[value.classroom.id] ?? 0) + "名 (" + _vm._s(_setup.temperatureCount[value.classroom.id] && _setup.temperatureCount[value.classroom.id] > 0 ? Math.round(_setup.temperatureCount[value.classroom.id] / value.classroom.students_count * 1000) / 10 : 0) + "%) ")]) : _vm._e()]), _c('p', {
      staticClass: "fake_scroll_table__heading col_3 tar"
    }, [_c('router-link', {
      staticClass: "show_button",
      attrs: {
        "to": {
          name: 'attendances.classroom',
          query: {
            id: [value.classroom.id],
            date: _vm.date
          }
        }
      }
    }, [_vm._v(" 名簿 ")]), _c('router-link', {
      staticClass: "show_button",
      attrs: {
        "to": {
          name: 'attendances.monthly',
          query: {
            classroom_id: value.classroom.id,
            year_month: _vm.currentYM
          }
        }
      }
    }, [_vm._v(" カレンダー ")]), _setup.schoolPlan.temperature ? _c('router-link', {
      staticClass: "show_button",
      attrs: {
        "to": {
          name: 'attendances.monthly',
          query: {
            classroom_id: value.classroom.id,
            year_month: _vm.currentYM,
            temperature: '1'
          }
        }
      }
    }, [_vm._v(" 検温 ")]) : _vm._e()], 1)]), _vm._l(value.attendances, function (attendance, index) {
      return _c('article', {
        key: index,
        staticClass: "fake_scroll_table__tr"
      }, [_c('div', {
        staticClass: "fake_scroll_table__span col_2"
      }, [index === 0 || attendance.student_id !== value.attendances[index - 1].student_id ? _c('span', {
        staticClass: "child-col"
      }, [_c('router-link', {
        attrs: {
          "to": {
            name: 'attendances.detail',
            query: {
              student_id: attendance.student_id
            }
          }
        }
      }, [_vm._v(" " + _vm._s(attendance.student ? attendance.student.name : "") + " ")]), _setup.absentcontinuouslyObj[attendance.student_id] ? _c('div', [_vm._m(0, true)]) : _vm._e(), _setup.absentFrequentlyObj[attendance.student_id] ? _c('div', [_c('p', {
        staticClass: "icon--date"
      }, [_c('span', {
        staticClass: "num",
        staticStyle: {
          "padding-left": "0px",
          "padding-right": "0px"
        }
      }, [_vm._v(_vm._s(_setup.absentFrequentlyObj[attendance.student_id].absence_count))]), _vm._v("欠 ")])]) : _vm._e()], 1) : _vm._e(), (index === 0 || attendance.student_id !== value.attendances[index - 1].student_id) && !attendance.is_daily_latest ? _c('span', {
        staticClass: "history-icon"
      }, [_vm._v(" 削除 ")]) : !attendance.is_daily_latest ? _c('span', {
        staticClass: "history-icon"
      }, [_c('img', {
        attrs: {
          "src": require("@/assets/images/history.svg"),
          "alt": "history"
        }
      }), _vm._v(" 履歴 ")]) : _vm._e()]), _c('div', {
        staticClass: "fake_scroll_table__span col_3",
        class: {
          deleted: !attendance.is_daily_latest
        }
      }, [_c('p', {
        class: `icon--${_setup.iconColor(attendance.type)}`
      }, [attendance.is_daily_latest ? _c('router-link', {
        attrs: {
          "to": {
            name: 'attendances.edit',
            params: {
              id: attendance.id
            }
          }
        }
      }, [_vm._v(" " + _vm._s(attendance.type) + " ")]) : _c('span', [_vm._v(" " + _vm._s(attendance.type) + " ")])], 1)]), _c('p', {
        staticClass: "fake_scroll_table__span col_3"
      }, [_vm._v(" " + _vm._s(attendance.guardian_memo) + " ")]), _c('p', {
        staticClass: "fake_scroll_table__span col_2"
      }, [_vm._v(" " + _vm._s(attendance.comment ? "先生用メモ:" + attendance.comment : "") + " ")]), attendance.is_daily_latest ? _c('div', {
        staticClass: "fake_scroll_table__span col_2 tar"
      }, [_c('router-link', {
        staticClass: "edit_button",
        attrs: {
          "to": {
            name: 'attendances.edit',
            params: {
              id: attendance.id
            }
          }
        }
      }, [_vm._v(" 編集 ")]), _c('router-link', {
        staticClass: "show_button",
        attrs: {
          "to": {
            name: 'attendances.detail',
            query: {
              student_id: attendance.student_id
            }
          }
        }
      }, [_vm._v(" 詳細 ")])], 1) : _vm._e()]);
    })], 2);
  }), 0)], 1), _vm.showModal ? _c('section', {
    staticClass: "common_modal"
  }) : _vm._e()], 1);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('p', {
    staticClass: "icon--date"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v("3")]), _vm._v(" 欠 ")]);
}];
export { render, staticRenderFns };