import store from "./services/store.js";
import axios from "axios";
export default {
  store,
  name: "App",

  created() {
    axios.get("/news.json").then(response => {
      this.$store.dispatch("commitNews", response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  methods: {}
};