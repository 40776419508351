import "core-js/modules/es.array.push.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_information_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("テンプレート管理")]), _vm.isLoading ? _c('loading-message', {
    attrs: {
      "loading-message": "テンプレート取得中"
    }
  }) : _c('form', {
    staticClass: "common_form",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_c('section', {
    staticClass: "gray_frame"
  }, [_c('p', {
    staticClass: "gray_frame__heading"
  }, [_vm._v("テンプレート設定")]), _c('row-item', [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("テンプレート名")]), _c('div', {
    staticClass: "col_10"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editData.template_name,
      expression: "editData.template_name"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.editData.template_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.editData, "template_name", $event.target.value);
      }
    }
  }), _vm.error.template_name ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.template_name) + " ")]) : _vm._e()])]), _c('p', {
    staticClass: "gray_frame__heading"
  }, [_vm._v("メールの設定")]), _c('row-item', [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("メールの件名")]), _c('div', {
    staticClass: "col_10"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editData.subject,
      expression: "editData.subject"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.editData.subject
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.editData, "subject", $event.target.value);
      }
    }
  }), _vm.error.subject ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.subject) + " ")]) : _vm._e()])]), _c('row-item', {
    staticClass: "start"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("メッセージ本文")]), _c('div', {
    staticClass: "col_10"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editData.mail_body,
      expression: "editData.mail_body"
    }],
    staticClass: "common_form--textarea",
    attrs: {
      "name": "body",
      "rows": "10"
    },
    domProps: {
      "value": _vm.editData.mail_body
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.editData, "mail_body", $event.target.value);
      }
    }
  }), _vm._v(" "), _vm.error.mail_body ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.mail_body) + " ")]) : _vm._e()])]), _c('p', {
    staticClass: "gray_frame__heading"
  }, [_vm._v("電話連絡の設定")]), _c('row-item', {
    staticClass: "start"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("メッセージ本文")]), _c('div', {
    staticClass: "col_10"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editData.call_body,
      expression: "editData.call_body"
    }],
    staticClass: "common_form--textarea",
    attrs: {
      "id": "tel_body",
      "name": "tel_body",
      "rows": "10"
    },
    domProps: {
      "value": _vm.editData.call_body
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.editData, "call_body", $event.target.value);
      }
    }
  }), _vm._v(" "), _vm.error.call_body ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.call_body) + " ")]) : _vm._e()])])], 1), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('button', {
    staticClass: "cancel_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push({
          name: 'messages.templates'
        });
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('button', {
    staticClass: "save_button",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.doSave();
      }
    }
  }, [_vm._v(" 保存 ")]), _vm.$route.params.id !== undefined ? _c('button', {
    staticClass: "delete_button",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.confirmDelete();
      }
    }
  }, [_vm._v(" 削除 ")]) : _vm._e()])]), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.modalClose
    }
  }) : _vm._e(), _vm.showConfirmModal ? _c('confirm-modal', {
    attrs: {
      "confirm-message": _vm.confirmMessage,
      "confirm-message-title": _vm.confirmMessageTitle
    },
    on: {
      "clickOK": _vm.doDelete,
      "clickCancel": _vm.cancelConfirm
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };