import "core-js/modules/es.array.push.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import store from "../../services/store.js";
import { mapState } from "vuex";
import { createData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import LoadingMessage from "@/components/common/LoadingMessage";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import Encoding from "encoding-japanese";
export default {
  store,
  name: "StudentCSVUpload",
  components: {
    MessageModal,
    LoadingMessage
  },

  data() {
    return {
      csv: [],
      error: [],
      warning: [],
      tableError: [],
      tableWarning: [],
      countByGrades: [],
      isLoading: false,
      showModal: false,
      section1: false,
      section2: false,
      section3: false
    };
  },

  computed: { ...mapState(["classrooms", "grades", "studentAddress", "me"])
  },

  created() {
    if (this.$store.state.me.role_id > 11) {
      this.$router.push({
        name: "dashboard"
      });
    }
  },

  methods: {
    downloadTemplateCSV() {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      saveAs(new Blob([bom, Papa.unparse([["学年", "学年組", "氏名", "氏名かな", "性別"]])], {
        type: "text/csv;charset=utf-8"
      }), "student_template.csv");
    },

    setAttachment(e) {
      const csvFile = e.target.files[0];
      if (!csvFile) return;
      this.error = [];
      this.warning = [];
      this.tableError = [];
      this.tableWarning = [];

      if (!csvFile.name.endsWith(".csv")) {
        this.error.push("ファイルの種類が不正です。CSVをアップロードしてください");
      }

      if (csvFile.size > 2097152) {
        this.error.push("ファイルサイズが5MBを超えています。添付できるCSVは5MB以下です。");
      }

      if (this.error.length > 0) return;
      const reader = new FileReader();

      reader.onload = e => {
        const codes = new Uint8Array(e.target.result);
        const encoding = Encoding.detect(codes); // SJISの検知は間違う場合があるので、確実にUTF8でなければSJISとする

        const from = encoding === "UTF8" ? "UTF8" : "SJIS";
        let csvString = Encoding.convert(codes, {
          to: "UNICODE",
          from: from,
          type: "string"
        }); //UTF-8 with BOMのときにBOM除去

        if (csvString.charCodeAt(0) === 0xfeff) {
          csvString = csvString.substr(1);
        }

        const csv = Papa.parse(csvString);
        this.csv = this.csvDataCleaning(csv);
        this.calculateCountByGrades(); //同名ファイルの再アップロードを検知するためにリセット

        document.getElementById("file").value = "";
      };

      reader.readAsArrayBuffer(csvFile);
    },

    csvDataCleaning(csv) {
      //エラーテーブルを初期化
      csv.data.forEach(v => {
        const row = [];
        v.forEach(() => row.push(false));
        this.tableError.push(row);
        this.tableWarning.push(row);
      });

      if (csv.errors.length > 0) {
        this.error = csv.errors.map(v => v.message);
      }

      const data = [];
      csv.data.forEach((row, i) => {
        if (i === 0) {
          if (row.join() !== "学年,学年組,氏名,氏名かな,性別") {
            this.error.push("見出し行が「学年」「学年組」「氏名」「氏名かな」「性別」と異なります");
            this.tableError[i] = this.tableError[i].map(() => true);
          }

          return data.push(row);
        }

        if (row.length !== 5) {
          //ExcelのCSVは最終行に空行を作るので許容する
          if (!(row.length === 1 && i === csv.data.length - 1)) {
            this.error.push(i + 1 + "行目の値が5列ではありません");
            this.tableError[i] = this.tableError[i].map(() => true);
            this.tableWarning[i] = this.tableWarning[i].map(() => true);
            return data.push(row);
          } else {
            return;
          }
        }

        row = row.map((cell, j) => {
          cell = Encoding.toHankakuCase(cell).trim();
          let valid = true;

          if (j === 0) {
            if (!this.grades.find(g => g.name === cell)) {
              this.error.push(i + 1 + "行目の学年はCOCOOに登録されていない値です");
              valid = false;
            }
          }

          if (j === 1) {
            if (!this.classrooms.find(c => c.name === cell)) {
              this.error.push(i + 1 + "行目の学年組はCOCOOに登録されている学年組(クラス名)と異なります");
              valid = false;
            } else if (!this.grades.find(g => {
              return g.classroom.find(c => c.name === cell) && g.name === Encoding.toHankakuCase(row[0]).trim();
            })) {
              this.error.push(i + 1 + "行目の学年組は学年との組み合わせが間違っています");
              valid = false;
            }
          }

          if (j === 2) {
            if (!cell) {
              this.error.push(i + 1 + "行目の氏名を入力してください");
              valid = false;
            } else if (!cell.match(/\s/)) {
              this.error.push(i + 1 + "行目の氏名の姓と名の間に半角スペースを入れてください");
              valid = false;
            }

            if (cell && this.error.length === 0) {
              if (cell.match(/\?/) || cell.match(/__/)) {
                this.warning.push(i + 1 + "行目の氏名に文字化けが含まれる可能性があります");
                valid = false;
              }
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 3) {
            if (!cell) {
              this.error.push(i + 1 + "行目の氏名かなを入力してください");
              valid = false;
            } else if (!cell.match(/\s/)) {
              this.error.push(i + 1 + "行目の氏名かなの姓と名の間に半角スペースを入れてください");
              valid = false;
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 4 && cell && !(cell === "男" || cell === "女")) {
            this.error.push(i + 1 + "行目の性別は男あるいは女を入力するか、空欄としてください");
            valid = false;
          }

          if (!valid) this.tableError[i][j] = true;
          return cell;
        });
        data.push(row);
      });
      return data;
    },

    calculateCountByGrades() {
      const gradesGroup = {};
      this.csv.forEach((row, i) => {
        if (i === 0) return;

        if (gradesGroup[row[0]]) {
          gradesGroup[row[0]]++;
        } else {
          gradesGroup[row[0]] = 1;
        }
      });
      const a = []; //学年順に並び替え

      Object.keys(gradesGroup).forEach(k => {
        a.push([k, gradesGroup[k]]);
      });

      const compare = (a, b) => {
        if (a[0] < b[0]) {
          return -1;
        }

        if (a[0] > b[0]) {
          return 1;
        }

        return 0;
      };

      a.sort(compare);
      this.countByGrades = a;
    },

    saveData() {
      this.isLoading = true;
      const gradesMap = {};
      const classroomsMap = {};
      const genderMap = {
        男: 1,
        女: 2
      };
      this.grades.forEach(v => gradesMap[v.name] = v.id);
      this.classrooms.forEach(v => classroomsMap[v.name] = v.id); //ヘッダー行の除去

      this.csv.shift();
      const students = this.csv.map(v => {
        return {
          grade_id: gradesMap[v[0]],
          class_id: classroomsMap[v[1]],
          name: v[2],
          ruby: v[3],
          gender: genderMap[v[4]]
        };
      });
      console.log(students);
      createData("writer/bulk_write_student", {
        students: students
      }).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: this.studentAddress + "情報一括登録が完了しました",
          title: "登録完了"
        });
        this.showModal = true;
        this.isLoading = false;
      }).catch(error => {
        this.$store.dispatch("commitModalMessage", {
          message: this.studentAddress + "情報一括登録に失敗しました",
          title: "登録失敗"
        });
        console.log(error);
        this.showModal = true;
        this.isLoading = false;
      });
    },

    completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "students"
      });
    },

    open(value) {
      if (value === "section1") {
        this.section1 = true;
      } else if (value === "section2") {
        this.section2 = true;
      } else if (value === "section3") {
        this.section3 = true;
      }
    },

    close(value) {
      if (value === "section1") {
        this.section1 = false;
      } else if (value === "section2") {
        this.section2 = false;
      } else if (value === "section3") {
        this.section3 = false;
      }
    }

  }
};