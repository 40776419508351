var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "page-header"
  }, [_c('h1', {
    staticClass: "page-header__title"
  }, [_vm._v("記事詳細")]), _c('div', {
    staticClass: "page-header__button"
  }, [_c('router-link', {
    staticClass: "button-back",
    attrs: {
      "to": {
        name: 'gov.forum',
        query: {
          target: _setup.forum?.target ?? 'school'
        }
      }
    }
  }, [_vm._v(" 一覧に戻る ")])], 1)]), _setup.forum ? _c('div', {
    staticClass: "forum"
  }, [_c('div', {
    staticClass: "forum__data"
  }, [_c('div', {
    staticClass: "icon-wrapper"
  }, [_setup.forum.target === 'school' ? _c('span', {
    staticClass: "icon school"
  }, [_vm._v("先生")]) : _vm._e(), _setup.forum.target === 'guardian' ? _c('span', {
    staticClass: "icon guardian"
  }, [_vm._v(" 保護者 ")]) : _vm._e(), _setup.forum.is_important ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 512 512"
    }
  }, [_c('path', {
    attrs: {
      "d": "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z",
      "fill": "#AB001C"
    }
  })]) : _vm._e(), _setup.forum.email_status === 'not_sent' && _setup.forum.created_at !== _setup.forum.updated_at ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  }, [_c('path', {
    attrs: {
      "id": "update_FILL0_wght400_GRAD0_opsz48",
      "d": "M129.075-822a8.8,8.8,0,0,1-3.525-.713,9.3,9.3,0,0,1-2.887-1.937,9.048,9.048,0,0,1-1.95-2.875A8.8,8.8,0,0,1,120-831.05a8.619,8.619,0,0,1,.713-3.5,8.978,8.978,0,0,1,1.95-2.837,9.237,9.237,0,0,1,2.887-1.912,8.931,8.931,0,0,1,3.525-.7,8.5,8.5,0,0,1,3.787.875,9.514,9.514,0,0,1,3.088,2.4v-2.65h1.5v5.2h-5.225v-1.5h2.625a8.694,8.694,0,0,0-2.587-2.05,6.8,6.8,0,0,0-3.188-.775,7.447,7.447,0,0,0-5.35,2.137,6.982,6.982,0,0,0-2.225,5.238,7.4,7.4,0,0,0,2.2,5.4,7.271,7.271,0,0,0,5.375,2.225,7.09,7.09,0,0,0,5.275-2.2,7.339,7.339,0,0,0,2.15-5.325H138a8.764,8.764,0,0,1-2.6,6.387A8.548,8.548,0,0,1,129.075-822Zm3.05-4.925-3.85-3.8v-5.35h1.5v4.725L133.2-828Z",
      "transform": "translate(-120 840)",
      "fill": "#464646"
    }
  })]) : _vm._e(), _setup.forum.email_status !== 'not_sent' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 -960 960 960",
      "width": "18",
      "height": "18",
      "fill": "#464646"
    }
  }, [_c('path', {
    attrs: {
      "d": "M172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31ZM480-457.69 160-662.31v410q0 5.39 3.46 8.85t8.85 3.46h615.38q5.39 0 8.85-3.46t3.46-8.85v-410L480-457.69Zm0-62.31 313.85-200h-627.7L480-520ZM160-662.31V-720v467.69q0 5.39 3.46 8.85t8.85 3.46H160v-422.31Z"
    }
  })]) : _vm._e()]), _c('a', {
    staticClass: "link",
    attrs: {
      "href": ""
    }
  }, [_vm._v(_vm._s(_setup.forum.title))]), _setup.forum.attachment?.length ? _c('i', {
    staticClass: "fas fa-paperclip"
  }) : _vm._e()]), _c('div', {
    staticClass: "forum__header"
  }, [_c('time', {
    staticClass: "date",
    attrs: {
      "datetime": _setup.moment(_setup.forum.published_at).format('YYYY-MM-DD')
    }
  }, [_vm._v(" " + _vm._s(_setup.moment(_setup.forum.published_at).format("YYYY年MM月DD日")) + " ")]), _c('p', {
    staticClass: "division"
  }, [_vm._v(_vm._s(_setup.forum.author_name))])]), _c('p', {
    staticClass: "forum__period"
  }, [_vm._v(" 表示期間： "), _c('time', {
    staticClass: "date",
    attrs: {
      "datetime": _setup.moment(_setup.forum.published_at).format('YYYY-MM-DD')
    }
  }, [_vm._v(_vm._s(_setup.moment(_setup.forum.published_at).format("YYYY年MM月DD日(ddd)")))]), _vm._v("〜 "), _c('time', {
    staticClass: "date",
    attrs: {
      "datetime": _setup.moment(_setup.forum.expire_on).format('YYYY-MM-DD')
    }
  }, [_vm._v(_vm._s(_setup.moment(_setup.forum.expire_on).format("YYYY年MM月DD日(ddd)")))])]), _c('div', {
    staticClass: "forum__content"
  }, _vm._l(_setup.forum.message?.split('\n'), function (text, i) {
    return _c('span', {
      key: `${i}-${_setup.forum.id}`,
      style: {
        'white-space': 'pre-line'
      }
    }, [_c('UrlToLink', {
      attrs: {
        "text": text
      }
    }), _vm._v(_vm._s("\n") + " ")], 1);
  }), 0), _setup.forum.attachment?.length ? _c('div', {
    staticClass: "forum__attachments"
  }, _vm._l(_setup.forum.attachment, function (attachment) {
    return _c('ForumAttachmentArticle', {
      key: attachment.id,
      attrs: {
        "attachment": attachment
      }
    });
  }), 1) : _vm._e()]) : _vm._e(), _c('section', {
    staticClass: "other"
  }, [_vm._m(0), _c('div', {
    staticClass: "other__forums"
  }, _vm._l(_setup.forumList, function (item) {
    return _c('GovForumArticle', {
      key: item.id,
      attrs: {
        "forum": item
      }
    });
  }), 1)])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "other__header"
  }, [_c('h2', {
    staticClass: "other__header__title"
  }, [_vm._v("その他の記事一覧")])]);
}];
export { render, staticRenderFns };