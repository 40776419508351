import auth0 from "auth0-js";
import router from "../router";
import store from "@/services/store";

const ID_TOKEN_KEY = "id_token";
const ACCESS_TOKEN_KEY = "access_token";
const EXPIRES_AT_KEY = "expires_at";
const AUTH0_ID_KEY = "auth0id";

const auth = new auth0.WebAuth({
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
  domain: process.env.VUE_APP_AUTH0_CLIENT_DOMAIN,
  redirectUri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
  responseType: "token id_token",
  scope: "openid email profile",
});

export function login(code) {
  localStorage.removeItem("FORCE_LOGOUT");
  const param = { prompt: "login" };
  if (code) {
    param.state = code;
  }
  auth.authorize(param);
}

export function handleAuthentication() {
  auth.parseHash((err, authResult) => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
      localStorage.setItem(ACCESS_TOKEN_KEY, authResult.accessToken);
      localStorage.setItem(ID_TOKEN_KEY, authResult.idToken);
      localStorage.setItem(EXPIRES_AT_KEY, expiresAt);

      auth.client.userInfo(authResult.accessToken, (err, user) => {
        if (user && user.sub) {
          localStorage.setItem(AUTH0_ID_KEY, user.sub);
          store.dispatch("commitLoginStatus", true);
          router.push({ name: "appInit" });
        } else if (err) {
          console.log(err);
          alert(err.errorDescription);
        }
      });
    } else if (err) {
      console.log(err);
      alert(err.errorDescription);
    }
  });
}

export function logout() {
  [ACCESS_TOKEN_KEY, ID_TOKEN_KEY, EXPIRES_AT_KEY, AUTH0_ID_KEY].forEach(
    (key) => {
      localStorage.removeItem(key);
    }
  );
  auth.logout();

  store.dispatch("commitLoginStatus", false);
  location.reload(true);
}

export function isLoggedIn() {
  const expiresAt = parseInt(localStorage.getItem(EXPIRES_AT_KEY), 10);
  return new Date().getTime() < expiresAt;
}

export function getIdToken() {
  return localStorage.getItem(ID_TOKEN_KEY);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}
