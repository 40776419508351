var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "agreements common_width"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "agreements__content"
  }, [_c('div', {
    staticClass: "agreements__content_text"
  }, [_c('TOS')], 1), _c('div', {
    staticClass: "button_wrap"
  }, [_c('button', {
    staticClass: "dont_button",
    class: {
      'button--color_main': _vm.agreements.toc === false,
      'button--color_main_border': _vm.agreements.toc === true || _vm.agreements.toc === null
    },
    on: {
      "click": function ($event) {
        _vm.agreements.toc = false;
      }
    }
  }, [_vm._v(" 同意しない ")]), _c('button', {
    staticClass: "do_button",
    class: {
      'button--color_main_border': _vm.agreements.toc === false || _vm.agreements.toc === null,
      start_button: _vm.agreements.toc === true
    },
    on: {
      "click": function ($event) {
        _vm.agreements.toc = true;
      }
    }
  }, [_vm._v(" 同意する ")])])]), _vm._m(2), _c('div', {
    staticClass: "agreements__content"
  }, [_c('div', {
    staticClass: "agreements__content_text"
  }, [_c('PrivacyPolicy')], 1), _c('div', {
    staticClass: "button_wrap"
  }, [_c('button', {
    staticClass: "dont_button",
    class: {
      'button--color_main': _vm.agreements.pp === false,
      'button--color_main_border': _vm.agreements.pp === true || _vm.agreements.pp === null
    },
    on: {
      "click": function ($event) {
        _vm.agreements.pp = false;
      }
    }
  }, [_vm._v(" 同意しない ")]), _c('button', {
    staticClass: "do_button",
    class: {
      'button--color_main_border': _vm.agreements.pp === false || _vm.agreements.pp === null,
      start_button: _vm.agreements.pp === true
    },
    on: {
      "click": function ($event) {
        _vm.agreements.pp = true;
      }
    }
  }, [_vm._v(" 同意する ")])])]), _c('div', {
    staticClass: "next_button_wrap"
  }, [_c('button', {
    staticClass: "start_button",
    attrs: {
      "disabled": !_vm.agreementCheck || _vm.isWriting
    },
    on: {
      "click": _vm.appStart
    }
  }, [_vm._v(" 利用開始 ")]), _vm.isWriting ? _c('p', [_vm._v("しばらくお待ち下さい...")]) : _vm._e()])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "guide"
  }, [_c('p', [_vm._v("COCOOをご利用いただきありがとうございます。")]), _c('p', [_vm._v(" サービスの利用を開始するためには、サービス利用規約及びプライバシーポリシーに同意していただく必要があります。 ")]), _c('p', [_vm._v(" 以下の内容をよくお読みになり、同意頂ける場合は、「同意する」ボタンをクリックしてください。 ")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('h2', {
    staticClass: "title--page"
  }, [_c('span', [_vm._v("利用規約")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('h2', {
    staticClass: "title--page"
  }, [_c('span', [_vm._v("プライバシーポリシー")])]);
}];
export { render, staticRenderFns };