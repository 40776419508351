import "core-js/modules/es.array.push.js";
import RowItem from "../RowItem";
import moment from "moment";
import { getData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import { mapState } from "vuex";
export default {
  name: "Events",
  components: {
    RowItem,
    LoadingMessage
  },
  filters: {
    trim: function (value) {
      if (!value) return "";
      if (value.length <= 10) return value;
      return value.slice(0, 10) + "..";
    }
  },
  data: () => ({
    isLoading: false,
    events: [],
    filteredEvents: [],
    futureEvents: [],
    dailyClassroomEvents: {},
    monthData: [],
    yearMonth: null,
    keyword: "",
    date: null,
    myEventOnly: false,
    showPast: false,
    visibleClassroomId: false,
    filteredClassrooms: []
  }),
  computed: { ...mapState(["grades", "classrooms", "holidays", "studentAddress", "me"]),
    fiscalYear: function () {
      return moment(this.yearMonth).add(-3, "month").year();
    },
    localYearMonth: function () {
      return moment(this.yearMonth).format("YYYY年MM月");
    }
  },

  created() {
    this.yearMonth = this.$route.query.year_month ? this.$route.query.year_month : moment().format("YYYY-MM");

    if (this.$route.query.date) {
      this.date = this.$route.query.date;
    }

    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }

    this.filteredClassrooms = this.classrooms;
    const date = moment(this.yearMonth).startOf("month");
    this.monthlyDates(date);
    this.setData().then(() => {
      this.search();
    });
  },

  methods: {
    setData() {
      this.isLoading = true;
      return getData("events?with[]=classroom&with[]=teacher&orderby=updated_at&order=desc").then(data => {
        this.filteredEvents = this.events = data.items.map(v => {
          let target = "";
          v.classroom.forEach((v2, i) => {
            const c = this.classrooms.find(v3 => v3.id === v2.classroom_id);

            if (c) {
              if (i < 3) {
                target += (i === 0 ? "" : ", ") + c.name;
              } else if (i === 3) {
                target += "ほか";
              }
            }
          });
          v.target = target;

          if (v.is_all_day) {
            v.formattedDate = moment(v.from).format("MM月DD日(ddd)");

            if (moment(v.from).format("YYYY-MM-DD") !== moment(v.to).format("YYYY-MM-DD")) {
              v.formattedDate += " - " + moment(v.to).format("MM月DD日(ddd)");
            }
          } else {
            v.formattedDate = moment(v.from).format("MM月DD日(ddd) HH:mm");

            if (moment(v.from).format("YYYY-MM-DD") === moment(v.to).format("YYYY-MM-DD")) {
              v.formattedDate += " - " + moment(v.to).format("HH:mm");
            } else {
              v.formattedDate += " - " + moment(v.to).format("MM月DD日(ddd) HH:mm");
            }
          }

          v.fromDate = moment(v.from).format("YYYY-MM-DD");
          v.toDate = moment(v.to).format("YYYY-MM-DD");
          v.formattedUpdatedAt = moment(v.updated_at).format("MM月DD日(ddd) HH:mm");
          return v;
        });
        this.assignDailyClassroomEvents();
        this.futureEvents = this.filteredEvents.filter(v => {
          return moment(v.to) >= moment().startOf("day");
        });
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading = false;
      });
    },

    assignDailyClassroomEvents() {
      this.dailyClassroomEvents = {};
      this.filteredEvents.forEach(v => {
        v.classroom.forEach(c => {
          if (!this.dailyClassroomEvents[c.classroom_id]) {
            this.dailyClassroomEvents[c.classroom_id] = {};
            this.dailyClassroomEvents[c.classroom_id][v.fromDate] = [v];

            if (v.fromDate !== v.toDate) {
              const from = moment(v.fromDate);
              const to = moment(v.toDate);

              while (from < to) {
                from.add(1, "day");
                this.dailyClassroomEvents[c.classroom_id][from.format("YYYY-MM-DD")] = [v];
              }
            }
          } else {
            if (!this.dailyClassroomEvents[c.classroom_id][v.fromDate]) {
              this.dailyClassroomEvents[c.classroom_id][v.fromDate] = [v];
            } else {
              this.dailyClassroomEvents[c.classroom_id][v.fromDate].push(v);
            }

            if (v.fromDate !== v.toDate) {
              const from = moment(v.fromDate);
              const to = moment(v.toDate);

              while (from < to) {
                from.add(1, "day");
                const d = from.format("YYYY-MM-DD");

                if (!this.dailyClassroomEvents[c.classroom_id][d]) {
                  this.dailyClassroomEvents[c.classroom_id][d] = [v];
                } else {
                  this.dailyClassroomEvents[c.classroom_id][d].push(v);
                }
              }
            }
          }
        });
      });
    },

    monthlyDates(date) {
      const days = date.daysInMonth();
      this.monthData = [];

      for (let i = 0; i < days; i++) {
        this.monthData.push({
          ymd: date.format("YYYY-MM-DD"),
          d: Number(date.format("d")),
          D: Number(date.format("D")),
          isHoliday: this.holidays.findIndex(v => v === date.format("YYYY-MM-DD")) > -1,
          weekName: this.getWeekNameJP(date.format("d"))
        });
        date.add(1, "d");
      }
    },

    monthChange(mode) {
      if (!this.isLoading) {
        const date = moment(this.yearMonth);

        if (mode === "add") {
          date.add(1, "months");
        } else if (mode === "sub") {
          date.subtract(1, "months");
        }

        this.yearMonth = date.format("YYYY-MM");
        this.monthlyDates(date);
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            yearMonth: this.yearMonth
          })
        });
      }
    },

    getWeekNameJP: function (id) {
      return ["日", "月", "火", "水", "木", "金", "土"][id];
    },

    search(push) {
      let events = this.events;

      if (this.date) {
        events = events.filter(v => {
          return moment(v.fromDate) <= moment(this.date) && moment(this.date) <= moment(v.toDate);
        });
      }

      if (this.keyword) {
        events = events.filter(v => {
          return v.title.indexOf(this.keyword) > -1;
        });
      }

      if (this.myEventOnly) {
        events = events.filter(v => v.teacher && v.teacher.user_id === this.me.user_id);
      }

      if (this.visibleClassroomId) {
        events = events.filter(v => {
          return v.classroom.find(c => {
            return c.classroom_id === this.visibleClassroomId;
          });
        });
      }

      this.filteredEvents = events;
      this.assignDailyClassroomEvents();

      if (this.showPast) {
        this.futureEvents = this.filteredEvents.filter(v => {
          return moment(v.to) < moment().startOf("day");
        });
      } else {
        this.futureEvents = this.filteredEvents.filter(v => {
          return moment(v.to) >= moment().startOf("day");
        });
      }

      if (this.visibleClassroomId) {
        this.filteredClassrooms = this.classrooms.filter(v => v.id === this.visibleClassroomId);
      } else {
        this.filteredClassrooms = this.classrooms;
      }

      if (push) {
        this.$router.push({
          query: {
            date: this.date,
            keyword: this.keyword,
            visibleClassroomId: this.visibleClassroomId
          }
        });
      }
    }

  }
};