import "core-js/modules/es.array.push.js";
import { getData, deleteData } from "@/services/axios";
export default {
  name: "AttendanceIndex",

  data() {
    return {
      student: {}
    };
  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      getData("students/" + this.$route.params.id).then(data => {
        console.log(data);
        this.student = {
          id: data.id,
          classroom: "",
          name: data.name,
          ruby: data.ruby,
          gender: data.gender
        };
      });
    },

    deleteStudent() {
      deleteData("students/" + this.student.id).then(() => {
        this.$router.push({
          name: "students"
        });
      });
    }

  }
};