var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_questionnaire_data result"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("アンケート詳細 - 回答結果")]), _c('page-switch', {
    attrs: {
      "message-id": _vm.enquete.message_id,
      "active": "result",
      "message-link": "messages.status"
    }
  }), _c('div', {
    staticClass: "button_wrap"
  }, [_c('section', {
    staticClass: "switch_button"
  }, [_c('a', {
    class: 'switch_button__left item ' + (_vm.to === 'students' ? 'active' : ''),
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleTo('students');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + " ")]), _c('a', {
    class: 'switch_button__right item ' + (_vm.to === 'participants' ? 'active' : ''),
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleTo('participants');
      }
    }
  }, [_vm._v(" 関係者 ")])])]), _c('div', {
    staticClass: "result_frame"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("アンケートの名前")]), _vm.isLoading ? _c('td', [_c('content-placeholders', [_c('content-placeholders-text', {
    attrs: {
      "lines": 1
    }
  })], 1)], 1) : _c('td', [_vm._v(" " + _vm._s(_vm.enquete.subject) + " ")])]), _c('tr', [_c('td', [_vm._v("回答期限")]), _vm.isLoading ? _c('td', [_c('content-placeholders', [_c('content-placeholders-text', {
    attrs: {
      "lines": 1
    }
  })], 1)], 1) : _c('td', [_vm._v(" " + _vm._s(_vm.getDateJP(_vm.enquete.implement_to)) + " "), _vm.inProgress ? _c('span', {
    staticClass: "icon_status"
  }, [_vm._v("実施中")]) : _vm._e()])]), _c('tr', [_c('td', [_vm._v("回答率")]), _vm.isLoading ? _c('td', [_c('content-placeholders', [_c('content-placeholders-text', {
    attrs: {
      "lines": 1
    }
  })], 1)], 1) : _c('td', [_vm._v(" " + _vm._s(_vm.getAnswerRate()) + "% (回答数 " + _vm._s(_vm.answers[0] ? _vm.answers[0].length : 0) + "人 / 送信数 " + _vm._s(_vm.targetCount) + "人) ")])]), _c('tr', [_c('td', [_vm._v("CSVダウンロード")]), _vm.isLoading ? _c('td', [_c('content-placeholders', [_c('content-placeholders-text', {
    attrs: {
      "lines": 1
    }
  })], 1)], 1) : _c('td', [_vm.isProcessing ? _c('span', [_c('i', {
    staticClass: "fas fa-sync-alt rotate_icon"
  }), _vm._v("しばらくお待ち下さい")]) : _c('button', {
    staticClass: "download_button",
    attrs: {
      "disabled": _vm.isProcessing
    },
    on: {
      "click": _vm.getResultCSV
    }
  }, [_vm._v(" ダウンロード実行 ")])])])])])]), _vm.isLoading ? _c('content-placeholders', [_c('section', [_c('div', {
    staticClass: "result_frame"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("選択肢")]), _c('td', [_vm._v("回答数")]), _c('td', [_vm._v("回答率")])]), _vm._l(2, function (n2) {
    return _c('tr', {
      key: n2
    }, [_c('td', [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1), _c('td', [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1), _c('td', [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)]);
  })], 2)])])])]) : _vm._l(_vm.questions, function (question, index) {
    return _c('section', {
      key: index
    }, [_c('section', {
      staticClass: "questionnaire_content"
    }, [_c('row-item', {
      staticClass: "text_type"
    }, [_c('p', {
      staticClass: "col_1"
    }, [_vm._v("質問" + _vm._s(index + 1))]), _c('p', {
      staticClass: "col_6 questionnaire_subject"
    }, [_vm._v(" " + _vm._s(question.subject) + " ")]), _c('p', {
      staticClass: "col_5 tar"
    }, [_c('router-link', {
      staticClass: "show_button",
      staticStyle: {
        "float": "right"
      },
      attrs: {
        "to": {
          name: 'questions.summary',
          query: {
            question_id: question.id,
            to: _vm.to
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.to === "students" ? _vm.studentAddress : "関係者") + "別回答一覧 ")])], 1)])], 1), _c('div', {
      staticClass: "result_frame"
    }, [question.type === 'text' ? _c('div', [_vm._v(" (自由記述形式で回答されました) "), _vm.answers[index].length > 0 ? _c('ul', [_vm._l(_vm.answers[index], function (answer, i) {
      return [answer.body ? _c('li', {
        key: i
      }, [_vm._v(" " + _vm._s(answer.body) + " ")]) : _vm._e()];
    })], 2) : _vm._e()]) : _c('table', {
      staticClass: "table"
    }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("選択肢")]), _c('td', [_vm._v("回答数")]), _c('td', [_vm._v("回答率")])]), _vm._l(question.options, function (option, index2) {
      return _c('tr', {
        key: index2
      }, [_c('td', [_vm._v(_vm._s(option))]), _c('td', [_vm._v(_vm._s(_vm.getAnswerSummary(question.id, index2)) + "人")]), _c('td', [_vm._v(" " + _vm._s(_vm.getAnswerGraph(question.id, index2)) + "% "), _c('span', {
        class: 'graph graph_' + _vm.getAnswerGraph(question.id, index2)
      })])]);
    })], 2)]), _vm.inProgress ? _c('p', {
      staticClass: "alert_text"
    }, [_vm._v(" ※アンケート実施期間中のため結果は変動する場合があります。 ")]) : _vm._e()])]);
  }), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'enquetes'
      }
    }
  }, [_vm._v(" 一覧に戻る ")])], 1)], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };