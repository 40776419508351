import "core-js/modules/es.array.push.js";
import { useRoute, useRouter } from "vue-router/composables";
import moment from "moment";
export default {
  __name: 'AttendanceTemperatureSwitch',
  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  setup(__props) {
    const props = __props; // @ts-check

    const route = useRoute();
    const router = useRouter();

    const switchRoute = mode => {
      const date = moment(props.filter.yearMonth);

      if (mode === "temperature") {
        router.push({
          name: "attendances.monthly",
          query: {
            classroom_id: props.filter.classroomId,
            group_id: props.filter.groupId,
            year_month: date.format("YYYY-MM"),
            temperature: "1"
          }
        });
      } else if (mode === "attendance") {
        router.push({
          name: "attendances.monthly",
          query: {
            classroom_id: props.filter.classroomId,
            group_id: props.filter.groupId,
            year_month: date.format("YYYY-MM")
          }
        });
      } else {
        router.push({
          name: "dekitus.monthly",
          query: {
            classroom_id: props.filter.classroomId,
            year_month: date.format("YYYY-MM")
          }
        });
      }
    };

    return {
      __sfc: true,
      route,
      router,
      props,
      switchRoute
    };
  }

};