import "core-js/modules/es.array.push.js";
import moment from "moment";
import { getData } from "@/services/axios";
import { mapState } from "vuex";
import RowItem from "../RowItem";
export default {
  name: "AnswerSummary",
  components: {
    RowItem
  },

  data() {
    return {
      question: {},
      enquete_subject: "",
      question_subject: "",
      options: [],
      answers: [],
      filteredAnswers: [],
      filterOptions: {
        classroom: false,
        answer: false
      },
      isLoading: true
    };
  },

  head: {
    title: function () {
      return {
        inner: "アンケート詳細 - 個別回答結果"
      };
    },
    meta: []
  },
  computed: { ...mapState(["studentAddress"]),
    toStudents: function () {
      return this.$route.query.to === "students";
    },
    classroomsOption: function () {
      let newList = [];

      if (this.toStudents) {
        // 重複を消す
        let c = this.answers.filter((v, i, a) => a.findIndex(w => v.student.classroom.id === w.student.classroom.id) === i);
        c.forEach(v => {
          newList.push({
            id: v.student.classroom.id,
            name: v.student.classroom.name
          });
        });
      }

      return newList;
    }
  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      getData("reader/get_enquete_answers_by_question?question_id=" + this.$route.query.question_id).then(data => {
        this.question = data.items.question;
        const enqueteContents = this.question.enquete.enquete_contents_i18n[0];

        if (enqueteContents) {
          this.enquete_subject = enqueteContents.subject;
        }

        const questionContents = this.question.question_contents_i18n[0];

        if (questionContents) {
          this.question_subject = questionContents.subject;
          this.options = questionContents.options;
        }

        this.answers = this.filteredAnswers = this.$route.query.to === "students" ? data.items.answers.students : data.items.answers.participants;
      }).catch(error => {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る
        // if (error.response) {
        //   this.$router.push({ name: "enquetes" });
        // }
      }).then(() => {
        this.isLoading = false;
      });
    },

    getAnswerText(answer) {
      return this.options[answer.body];
    },

    getJPTime(datetime) {
      return moment(datetime).isValid() ? moment(datetime).format("llll") : "";
    },

    listFilter() {
      let newList = this.answers;

      if (this.toStudents) {
        if (this.filterOptions.classroom !== false) {
          newList = newList.filter(v => v.student.classroom.id === this.filterOptions.classroom);
        }
      }

      if (this.filterOptions.answer !== false) {
        newList = newList.filter(v => parseInt(v.body) === this.filterOptions.answer);
      }

      this.filteredAnswers = newList;
    },

    resetFilter() {
      this.filterOptions = {
        classroom: false,
        answer: false
      };
    }

  }
};