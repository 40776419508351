import "core-js/modules/es.array.push.js";
import { createData } from "@/services/axios";
import TOS from "@/components/agreements/TOS";
import PrivacyPolicy from "@/components/agreements/PrivacyPolicy";
export default {
  name: "Agreements",
  components: {
    TOS,
    PrivacyPolicy
  },
  data: () => ({
    agreements: {
      toc: null,
      pp: null
    },
    isWriting: false
  }),
  computed: {
    agreementCheck: function () {
      return !!(this.agreements.toc && this.agreements.pp);
    }
  },
  methods: {
    appStart() {
      this.isWriting = true;
      createData("writer/write_last_login").then(() => {
        this.$router.push({
          name: "dashboard"
        });
      }).catch(error => {
        this.isWriting = false;
        alert("初回データ保存に失敗しました");
        console.log(error);
      });
    }

  }
};