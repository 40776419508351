import { ref } from "vue";
import { useIntervalFn } from "@vueuse/core";
import moment from "moment";
import { getData } from "@/services/axios";
export default {
  __name: 'LatestSchoolForum',

  setup(__props) {
    // @ts-check
    const fiscalYear = moment().add(-3, "month").year();
    const forumList = ref([]);

    const setData = async () => {
      try {
        const data = await getData(`school_forums?from=${fiscalYear}-04-01&to=${fiscalYear + 1}-03-31&limit=10`);
        forumList.value = data.items;
      } catch (error) {
        console.log(error);
      }
    }; // 毎分更新


    useIntervalFn(() => {
      setData();
    }, 60000);
    setData();
    return {
      __sfc: true,
      fiscalYear,
      forumList,
      setData,
      moment
    };
  }

};