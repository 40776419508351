var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', [_c('section', {
    staticClass: "switch switch_button__small"
  }, [_c('button', {
    staticClass: "switch_button__left item",
    class: {
      active: _setup.route.name === 'attendances.monthly' && !_setup.route.query.temperature
    },
    on: {
      "click": function ($event) {
        return _setup.switchRoute('attendance');
      }
    }
  }, [_vm._v(" 欠席 ")]), _c('button', {
    staticClass: "switch_button__center item",
    class: {
      active: _setup.route.name === 'attendances.monthly' && _setup.route.query.temperature
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.switchRoute('temperature');
      }
    }
  }, [_vm._v(" 検温 ")]), _c('button', {
    staticClass: "switch_button__right item",
    class: {
      active: _setup.route.name === 'dekitus.monthly'
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.switchRoute('dekitus');
      }
    }
  }, [_vm._v(" 学習履歴 ")])])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };