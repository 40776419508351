import { getData } from "@/services/axios";

import { ref } from 'vue';
export default {
  __name: 'ForumAttachmentArticle',
  props: {
    attachment: {
      type: Object,
      required: true
    }
  },

  setup(__props) {
    const props = __props; // @ts-check

    const isGetS3UrlRunning = ref(false);

    const getS3SignedUrl = async () => {
      if (isGetS3UrlRunning.value) {
        // 重複実行は行わせない
        return;
      }

      isGetS3UrlRunning.value = true; //secure browserのpopup block 対策

      const newWindow = window.open();

      try {
        const data = await getData("reader/get_s3_signed_url?path=" + encodeURIComponent(props.attachment.object_key));
        newWindow.location = data.items.s3url;
        isGetS3UrlRunning.value = false;
      } catch (error) {
        console.log(error);
      }
    };

    return {
      __sfc: true,
      props,
      isGetS3UrlRunning,
      getS3SignedUrl
    };
  }

};