var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_class_divide"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")]), _vm.isLoading.postData ? _c('loading-message', {
    attrs: {
      "loading-message": "クラス分けデータを保存しています"
    }
  }) : _vm._e(), _c('section', {
    staticClass: "gray_frame"
  }, [_c('row-item', {
    staticClass: "common_form"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v(" 学年 "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentGradeId,
      expression: "currentGradeId"
    }],
    staticClass: "common_form--select",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.currentGradeId = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.filterList]
    }
  }, [_c('option', {
    domProps: {
      "value": null
    }
  }, [_vm._v("すべて")]), _vm._l(_vm.grades, function (grade, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": grade.id
      }
    }, [_vm._v(" " + _vm._s(grade.name) + " ")]);
  })], 2)]), _c('p', {
    staticClass: "col_4"
  }, [_vm._v(" 検索 "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterKeyword,
      expression: "filterKeyword"
    }],
    staticClass: "common_form--input",
    attrs: {
      "type": "text",
      "placeholder": "名前で絞り込む"
    },
    domProps: {
      "value": _vm.filterKeyword
    },
    on: {
      "keyup": _vm.filterList,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.filterKeyword = $event.target.value;
      }
    }
  })]), _c('p', {
    staticClass: "col_4"
  }, [_vm._v(" 現在のクラス "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentClassroomId,
      expression: "currentClassroomId"
    }],
    staticClass: "common_form--select",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.currentClassroomId = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.filterList]
    }
  }, [_c('option', {
    domProps: {
      "value": null
    }
  }, [_vm._v("すべて")]), _vm._l(_vm.filteredClassrooms, function (classroom, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": classroom.id
      }
    }, [_vm._v(" " + _vm._s(classroom.name) + " ")]);
  })], 2)]), _c('p', {
    staticClass: "col_2"
  }, [_c('button', {
    staticClass: "reset_button",
    on: {
      "click": _vm.resetFilter
    }
  }, [_vm._v("リセット")])])]), _vm.isLoading.students ? _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + "データを取得しています… ")]) : _c('div', {
    staticClass: "text"
  }, [_c('p', {
    staticClass: "class_data"
  }, [_vm._v(" 総数 "), _c('span', {
    staticClass: "counter"
  }, [_vm._v(_vm._s(_vm.filteredStudents.length))]), _vm._v(" 人 (設定済 "), _c('span', {
    staticClass: "counter was_set"
  }, [_vm._v(_vm._s(_vm.filteredStudents.length - _vm.notSetStudents))]), _vm._v(" 人 未設定 "), _c('span', {
    staticClass: "counter",
    class: {
      not_set: _vm.notSetStudents !== 0
    }
  }, [_vm._v(_vm._s(_vm.notSetStudents))]), _vm._v("人) ")]), _vm._l(_vm.filteredClassrooms, function (classroom, index) {
    return _c('p', {
      key: index,
      staticClass: "class_data"
    }, [_vm._v(" " + _vm._s(classroom.name) + " "), _c('span', {
      staticClass: "counter",
      class: {
        not_set: _vm.classMemberCounts[classroom.id] === 0
      }
    }, [_vm._v(_vm._s(_vm.classMemberCounts[classroom.id]))]), _vm._v("人 ")]);
  })], 2)], 1), _c('div', {
    staticClass: "tac"
  }, [_c('input', {
    staticClass: "save_button",
    attrs: {
      "type": "button",
      "value": "保存する"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.saveAssignment.apply(null, arguments);
      }
    }
  })]), _c('div', {
    staticClass: "table__wrapper"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th'), _vm._l(_vm.filteredClassrooms, function (classroom, i) {
    return _c('th', {
      key: i
    }, [_vm._v(" " + _vm._s(classroom.name) + " ")]);
  }), _c('th', {
    class: 'col_' + (8 - _vm.filteredClassrooms.length)
  })], 2)]), _vm.isLoading.students ? _c('tbody', [_c('tr', _vm._l(10, function (n) {
    return _c('td', {
      key: n,
      attrs: {
        "colspan": "",
        "lines": 1
      }
    }, [_c('content-placeholders-text')], 1);
  }), 0)]) : _c('tbody', _vm._l(_vm.filteredNameList, function (student, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(student.name))]), _vm._l(_vm.filteredClassrooms, function (classroom, i) {
      return _c('td', {
        key: i
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: student.class_id,
          expression: "student.class_id"
        }],
        staticClass: "common_form--radio",
        attrs: {
          "id": 'c' + classroom.id + 's' + student.id,
          "type": "radio"
        },
        domProps: {
          "value": classroom.id,
          "checked": _vm._q(student.class_id, classroom.id)
        },
        on: {
          "change": function ($event) {
            return _vm.$set(student, "class_id", classroom.id);
          }
        }
      }), _c('label', {
        attrs: {
          "for": 'c' + classroom.id + 's' + student.id
        }
      })]);
    }), _c('td', {
      staticClass: "scroll_bar"
    }), _vm.showSmallWindow === student.id ? _c('td', [_c('p', {
      staticClass: "small_window__heading"
    }, [_vm._v("他の学年に割り当て")]), _c('div', {
      staticClass: "small_window__input"
    }, [_c('p', {
      staticClass: "text"
    }, [_vm._v("学年")]), _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.otherClassPullDown.grade,
        expression: "otherClassPullDown.grade"
      }],
      staticClass: "common_form__select",
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.otherClassPullDown, "grade", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, _vm._l(_vm.grades, function (grade, i) {
      return _c('option', {
        key: i,
        domProps: {
          "value": grade.id
        }
      }, [_vm._v(" " + _vm._s(grade.name) + " ")]);
    }), 0)]), _c('div', {
      staticClass: "small_window__input"
    }, [_c('p', {
      staticClass: "text"
    }, [_vm._v("クラス")]), _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.otherClassPullDown.class,
        expression: "otherClassPullDown.class"
      }],
      staticClass: "common_form--select",
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.otherClassPullDown, "class", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, _vm._l(_vm.miniWinClassList, function (classroom, i) {
      return _c('option', {
        key: i,
        domProps: {
          "value": classroom.id
        }
      }, [_vm._v(" " + _vm._s(classroom.name) + " ")]);
    }), 0)]), _c('div', {
      staticClass: "small_window__button_wrap"
    }, [_c('button', {
      staticClass: "apply_button",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.setOtherClass(student.id);
        }
      }
    }, [_vm._v(" 決定 ")]), _c('button', {
      staticClass: "cancel_button",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          _vm.showSmallWindow = false;
        }
      }
    }, [_vm._v(" キャンセル ")])])]) : _vm._e()], 2);
  }), 0)])]), _vm.showModal ? _c('section', {
    staticClass: "common_modal"
  }, [_c('div', {
    staticClass: "common_modal__inner"
  }, [_c('div', {
    staticClass: "common_modal__white_frame",
    attrs: {
      "id": "targetFrame"
    }
  }, [_c('div', {
    staticClass: "common_modal__inner_frame",
    attrs: {
      "id": "modal_sentSettled"
    }
  }, [_c('h3', {
    staticClass: "common_modal__title"
  }, [_vm._v("クラス分けを保存しました")]), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "ok_button",
    attrs: {
      "to": {
        name: 'classrooms'
      }
    },
    on: {
      "click": function ($event) {
        _vm.showModal = false;
      }
    }
  }, [_vm._v(" OK ")])], 1)])])])]) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };