var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('tr', {
    staticClass: "table__tr"
  }, [_c('td', [_vm._v(_vm._s(_vm.index + 1))]), _c('td', {
    staticClass: "table__span name"
  }, [_c('div', {
    staticClass: "name_wrapper"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'attendances.detail',
        query: {
          student_id: _vm.student.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.student.name) + " "), _vm._l(_setup.absentAlertStore.assignAbsentBudge(_vm.student), function (count, i) {
    return _c('span', {
      key: i
    }, [_c('p', {
      staticClass: "icon--date"
    }, [_c('span', {
      staticClass: "num"
    }, [_vm._v(_vm._s(count))]), _vm._v("欠 ")])]);
  })], 2), _c('button', {
    staticClass: "edit_icon",
    on: {
      "click": function ($event) {
        return _vm.handleCommentEdit(_vm.student);
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-pen"
  })])], 1)]), _vm._l(_vm.monthData, function (d, i) {
    return _c('td', {
      key: i,
      class: ['table__span', 'date_' + _vm.days, 'date_' + _vm.days + ' week_' + d.d, {
        holiday: d.isHoliday
      }, _vm.getAttendanceClass(_vm.student.attendanceByDate[d['YYYY-MM-DD']])]
    }, [_vm.student.attendanceByDate[d['YYYY-MM-DD']] ? _c('router-link', {
      attrs: {
        "to": {
          name: 'attendances.edit',
          params: {
            id: _vm.student.attendanceByDate[d['YYYY-MM-DD']].id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.getAttendanceLabel(_vm.student.attendanceByDate[d["YYYY-MM-DD"]])) + " ")]) : _vm._e()], 1);
  }), _c('td', {
    staticClass: "table__span sum"
  }, [_vm._v(" " + _vm._s(_vm.student.monthlySummary["停忌等"]) + " ")]), _c('td', {
    staticClass: "table__span sum"
  }, [_vm._v(" " + _vm._s(_vm.student.monthlySummary["要出席日数"]) + " ")]), _c('td', {
    staticClass: "table__span sum"
  }, [_vm._v(" " + _vm._s(_vm.student.monthlySummary["欠席（病気）"]) + " ")]), _c('td', {
    staticClass: "table__span sum"
  }, [_vm._v(" " + _vm._s(_vm.student.monthlySummary["欠席（事故）"]) + " ")]), _c('td', {
    staticClass: "table__span sum"
  }, [_vm._v(" " + _vm._s(_vm.student.monthlySummary["出席日数"]) + " ")]), _c('td', {
    staticClass: "table__span sum"
  }, [_vm._v(" " + _vm._s(_vm.student.monthlySummary["遅刻日数"]) + " ")]), _c('td', {
    staticClass: "table__span sum"
  }, [_vm._v(" " + _vm._s(_vm.student.monthlySummary["早退日数"]) + " ")]), _c('td', {
    staticClass: "table__span sum"
  }, [_vm._v(" " + _vm._s(_vm.student.dekitusByMonth) + " ")])], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };