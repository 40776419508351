export default {
  name: "PageSwitch",
  props: {
    messageId: {
      type: Number,
      default: null
    },
    messageLink: {
      type: String,
      default: "messages.detail"
    },
    active: {
      type: String,
      default: "detail"
    }
  }
};