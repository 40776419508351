import { ref } from "vue";
export default {
  __name: 'MonthDailyEvent',
  props: {
    mode: {
      type: String,
      default: "attendance"
    },
    days: {
      type: Number,
      required: true
    },
    monthData: {
      /** @type {PropType<Object<string, any>[]>} */
      type: Array,
      required: true
    },
    dailyEvents: {
      type: Object,
      default: () => {}
    },
    classroom: {
      type: Object,
      default: () => {}
    }
  },

  setup(__props) {
    // @ts-check
    const isOmitted = ref(true);

    const handleOmitted = () => {
      isOmitted.value = !isOmitted.value;
    };

    return {
      __sfc: true,
      isOmitted,
      handleOmitted
    };
  }

};