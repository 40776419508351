import moment from "moment";
export default {
  __name: 'ForumArticle',
  props: {
    forum: {
      type: Object,
      required: true
    }
  },

  setup(__props) {
    // @ts-check
    return {
      __sfc: true,
      moment
    };
  }

};