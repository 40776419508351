/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./MonthlyStudentTable.vue?vue&type=template&id=b5242ada&scoped=true&"
import script from "./MonthlyStudentTable.vue?vue&type=script&setup=true&lang=js&"
export * from "./MonthlyStudentTable.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./MonthlyStudentTable.vue?vue&type=style&index=0&id=b5242ada&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5242ada",
  null
  
)

export default component.exports