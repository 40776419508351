import moment from "moment";
import { computed, toRefs } from "vue";
export default {
  __name: 'StatusRate',
  props: {
    to: String,
    statuses: Array,
    message: Object,
    studentAddress: String
  },

  setup(__props) {
    const props = __props;
    const {
      message,
      statuses
    } = toRefs(props);
    const studentMessageCountObj = computed(() => {
      return statuses.value.reduce((sum, current) => {
        return {
          total: sum.total + current.guardian.length,
          delivered: sum.delivered + current.guardian.filter(w => w.status === "opened" || w.status === "reached").length
        };
      }, {
        total: 0,
        delivered: 0
      });
    });
    const studentDeliverRate = computed(() => {
      return studentMessageCountObj.value.total ? measureRate(studentMessageCountObj.value.delivered, studentMessageCountObj.value.total) : 0;
    });
    const studentOpenRate = computed(() => {
      return message.value.student_sending_count ? measureRate(message.value.student_opened_count, message.value.student_sending_count) : 0;
    });
    const participantDelivered = computed(() => {
      return statuses.value.filter(w => w.final_status === "opened" || w.final_status === "reached").length;
    });
    const participantDeliverRate = computed(() => {
      return message.value.participant_sending_count ? measureRate(participantDelivered.value, message.value.participant_sending_count) : 0;
    });
    const participantOpenRate = computed(() => {
      return message.value.participant_sending_count ? measureRate(message.value.participant_opened_count, message.value.participant_sending_count) : 0;
    });
    const isPastYearMessage = computed(() => {
      return moment(message.value.message_scheduled_for).add(-3, "month").year() < moment().add(-3, "month").year();
    });

    const measureRate = (molecule, denominator) => {
      return Math.round(molecule / denominator * 1000) / 10;
    };

    return {
      __sfc: true,
      props,
      message,
      statuses,
      studentMessageCountObj,
      studentDeliverRate,
      studentOpenRate,
      participantDelivered,
      participantDeliverRate,
      participantOpenRate,
      isPastYearMessage,
      measureRate
    };
  }

};