// @ts-check
import { ref } from "vue";
import { watchDebounced } from "@vueuse/core";

const urlRegex = /(https?:\/\/[^\s]+)/g;
const oneLineUrlRegex = /^(https?:\/\/[^\s]+)$/;

/**
 * メッセージ内にURLが含まれた時、URLと同じ行に他の要素が含まれないかをチェックする
 *
 * @param {Ref<string>} message
 */
export const useMessageUrlValidator = (message) => {
  const isUrlValidate = ref(true);

  const validate = () => {
    if (message.value.match(urlRegex)) {
      isUrlValidate.value = message.value.split("\n").every((v) => {
        v = v.trim();
        return !v.match(urlRegex) || v.match(oneLineUrlRegex);
      });
    } else {
      isUrlValidate.value = true;
    }
  };

  watchDebounced(message, () => validate(), { debounce: 250, maxWait: 1000 });

  return { isUrlValidate };
};
