import { saveAs } from "file-saver";
import Papa from "papaparse";
import { getData, getDataByPostMethod } from "@/services/axios";
import moment from "moment";

export const getAbsentAlert = async () => {
  const data = await getData([
    "reader/get_continuously_absent-students",
    "reader/get_frequently_absent-students",
  ]);
  const absentList3 = data[0].items;
  const absentList20 = data[1].items[0];
  const absentList30 = data[1].items[1];
  let latestDekitusByStudent = {};

  // 長期欠席者ごとのデキタス最新学習履歴を取得
  // todo 取得の要不要を判定
  let absentListIds = [];
  if (data[0].items) {
    absentListIds = absentListIds.concat(
      data[0].items.map((v) => v.student_id)
    );
  }
  if (data[1].items[0]) {
    absentListIds = absentListIds.concat(
      data[1].items[0].map((v) => v.student_id)
    );
  }
  if (data[1].items[1]) {
    absentListIds = absentListIds.concat(
      data[1].items[1].map((v) => v.student_id)
    );
  }
  if (absentListIds.length > 0) {
    const dekitusData = await getDataByPostMethod(
      "reader/get_latest_dekitus_result_by_student",
      {
        student_id: absentListIds,
      }
    );
    if (dekitusData) {
      latestDekitusByStudent = dekitusData.items;
    }
  }

  return { absentList3, absentList20, absentList30, latestDekitusByStudent };
};

export const assignAbsentBudge = (
  student,
  absentList3,
  absentList20,
  absentList30
) => {
  const absents = [];
  if (absentList3 && absentList3.length > 0) {
    if (absentList3.some((v) => v.student_id === student.id)) {
      absents.push(3);
    }
  }
  if (absentList20 && absentList20.length > 0) {
    if (absentList20.some((v) => v.student_id === student.id)) {
      absents.push(20);
    }
  }
  if (absentList30 && absentList30.length > 0) {
    if (absentList30.some((v) => v.student_id === student.id)) {
      absents.push(30);
    }
  }
  return absents;
};

export const downloadStudentsCSV = (classroom, grades, studentAddress) => {
  getData("students?classrooms=" + classroom.id)
    .then((data) => {
      const csvArray = [["学年", "学年組", "氏名", "氏名かな", "性別"]];
      const genderMap = { 1: "男", 2: "女" };
      data.items.forEach((v) => {
        const gradeName = grades.find((g) => g.id === v.grade_id).name;
        csvArray.push([
          gradeName,
          classroom.name,
          v.name,
          v.ruby,
          genderMap[v.gender],
        ]);
      });
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      saveAs(
        new Blob([bom, Papa.unparse(csvArray)], {
          type: "text/csv;charset=utf-8",
        }),
        classroom.name +
          studentAddress +
          "名簿_" +
          moment().format("YYYY年MM月DD日") +
          "時点.csv"
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

export const formatPhoneNumber = (str) => {
  if (str) {
    str = String(str);
    return `0${str.slice(3, 5)}-${str.slice(5, 9)}-${str.slice(9, 13)}`;
  } else {
    return "";
  }
};

export const sumTimes = (times) => {
  let totalSeconds = 0;

  times.forEach((time) => {
    const parts = time.split(":");
    totalSeconds += parseInt(parts[0], 10) * 3600; // 時間を秒に変換
    totalSeconds += parseInt(parts[1], 10) * 60; // 分を秒に変換
    totalSeconds += parseInt(parts[2], 10); // 秒を加算
  });

  return totalSeconds;
};

export const showSumHoursAndMinutes = (times) => {
  let totalSeconds = sumTimes(times);
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);

  return `${padZero(hours)}時間${padZero(minutes)}分`;
};

export const showSumHoursMinutesAndSeconds = (times) => {
  let totalSeconds = sumTimes(times);
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${padZero(hours)}時間${padZero(minutes)}分${seconds}秒`;
};

export const showSumMinutes = (times) => {
  let totalSeconds = sumTimes(times);
  const minutes = Math.floor(totalSeconds / 60);
  return `${padZero(minutes)}分`;
};

const padZero = (number) => {
  return number < 10 ? "0" + number : number;
};
