var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('section', {
    staticClass: "common_modal"
  }, [_c('div', {
    staticClass: "common_modal__inner"
  }, [_c('div', {
    staticClass: "common_modal__white_frame",
    attrs: {
      "id": "targetFrame"
    }
  }, [_c('div', {
    staticClass: "common_modal__inner_frame",
    attrs: {
      "id": "modal_sentSettled"
    }
  }, [_c('h3', {
    staticClass: "common_modal__title"
  }, [_vm._v(" " + _vm._s(_vm.getTitle) + " ")]), _c('p', {
    staticClass: "common_modal__text"
  }, [_vm._v(" " + _vm._s(_vm.getMessage) + " ")]), _vm.useSubButton ? _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    staticClass: "close_button",
    attrs: {
      "type": "button",
      "value": _vm.subButtonMessage
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('click-sub-button');
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    staticClass: "close_button",
    attrs: {
      "type": "button",
      "disabled": _vm.buttonDisabled,
      "value": "OK"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('close');
      }
    }
  })])])])])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };