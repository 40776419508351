var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_absence_monthly"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("全校" + _vm._s(_setup.studentAddress) + " 出欠席一覧 -月別")]), _setup.isLoading ? _c(_setup.LoadingMessage) : _vm._e(), _setup.route.query.temperature ? _c(_setup.MonthlyTemperature) : _c(_setup.MonthlyAttendance), _c('section', {
    staticClass: "tac"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'attendances'
      }
    }
  }, [_vm._v(" 出欠席一覧に戻る ")])], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };