import useSWRV from "swrv";
import { getData } from "@/services/axios";

import { useRoute } from 'vue-router/composables';
import { ref, watch } from 'vue';
export default {
  __name: 'GovForumIndex',

  setup(__props) {
    // @ts-check
    const route = useRoute();
    const payload = ref({
      target: Array.isArray(route.query.target) ? route.query.target[0] : route.query.target
    });
    watch(() => route.query.target, () => {
      payload.value.target = Array.isArray(route.query.target) ? route.query.target[0] : route.query.target;
    });
    const {
      data: forumList
    } = useSWRV(() => `forum-${payload.value.target}`, async () => {
      const params = new URLSearchParams(payload.value);
      const result = await getData(`government_forums?${params.toString()}`);
      return result.items;
    }, {
      revalidateOnFocus: false
    });
    return {
      __sfc: true,
      route,
      payload,
      forumList
    };
  }

};