import { computed } from "vue";
import { useStore } from "vue2-helpers/vuex";
import MonthlyDekitus from "@/components/dekitus/monthly/MonthlyDekitus.vue";
export default {
  __name: 'Monthly',

  setup(__props) {
    // @ts-check
    const store = useStore();
    const studentAddress = computed(() => store.state.studentAddress);
    return {
      __sfc: true,
      store,
      studentAddress,
      MonthlyDekitus
    };
  }

};