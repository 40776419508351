import { ref } from "vue";
import { useRoute } from "vue-router/composables";
import moment from "moment";
import GroupClassSelectForm from "@/components/attendances/monthly/GroupClassSelectForm.vue";
import CalendarHeading from "@/components/attendances/monthly/CalendarHeading.vue";
import MonthlyTemperatureCalendar from "@/components/temperatures/MonthlyTemperatureCalendar.vue";
import AttendanceTemperatureSwitch from "@/components/attendances/monthly/AttendanceTemperatureSwitch.vue";
export default {
  __name: 'MonthlyTemperature',

  setup(__props) {
    // @ts-check
    const route = useRoute();
    /**
     * 検索条件
     *
     * @type {Ref<{ classroomId: any; groupId: any; yearMonth: any }>} filter
     */

    const filter = ref({
      classroomId: 0,
      groupId: 0,
      yearMonth: null
    });

    const init = () => {
      filter.value.yearMonth = route.query.year_month ?? moment().format("YYYY-MM");
      filter.value.classroomId = route.query.classroom_id ?? 0;
      filter.value.groupId = route.query.group_id ?? 0;
    }; // クラスを変更した時はinitを経由せずに必要な部分だけ初期化


    const handleClassroomChange = classroomId => {
      filter.value.classroomId = classroomId;
    }; // グループを変更した時もinitを経由せずに必要な部分だけ初期化


    const handleGroupChange = groupId => {
      filter.value.groupId = groupId;
      filter.value.classroomId = 0;
    }; // created


    init();
    return {
      __sfc: true,
      route,
      filter,
      init,
      handleClassroomChange,
      handleGroupChange,
      GroupClassSelectForm,
      CalendarHeading,
      MonthlyTemperatureCalendar,
      AttendanceTemperatureSwitch
    };
  }

};