var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm._m(0);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', [_vm._v(" 学習支援ツール（デキタス）をご利用の場合にご確認いただくことが可能です。"), _c('br'), _c('br'), _vm._v(" デキタスについては"), _c('a', {
    attrs: {
      "href": "https://youtu.be/9mFMJsOi0vk",
      "target": "_blank"
    }
  }, [_vm._v("こちら")]), _vm._v("の紹介動画をご覧ください。"), _c('br'), _vm._v(" デキタス運営会社、城南進学研究社は"), _c('a', {
    attrs: {
      "href": "https://edu-biz.johnan.jp/",
      "target": "_blank"
    }
  }, [_vm._v("こちら")]), _vm._v("からご確認いただけます。 ")]);
}];
export { render, staticRenderFns };