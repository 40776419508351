import "core-js/modules/es.array.push.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import store from "../../services/store.js";
import { mapState } from "vuex";
import { createData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import LoadingMessage from "@/components/common/LoadingMessage";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import Encoding from "encoding-japanese";
import emailValidator from "email-validator";
export default {
  store,
  name: "ParticipantCSVUpload",
  components: {
    MessageModal,
    LoadingMessage
  },

  data() {
    return {
      csv: [],
      error: [],
      warning: [],
      tableError: [],
      tableWarning: [],
      isLoading: false,
      showModal: false,
      section1: false,
      section2: false,
      section3: false
    };
  },

  computed: { ...mapState(["me"])
  },

  created() {
    if (this.$store.state.me.role_id > 11) {
      this.$router.push({
        name: "dashboard"
      });
    }
  },

  methods: {
    downloadTemplateCSV() {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      saveAs(new Blob([bom, Papa.unparse([["氏名", "氏名かな", "所属", "言語", "メールアドレス", "電話番号", "お知らせ方法", "メモ"]])], {
        type: "text/csv;charset=utf-8"
      }), "participant_template.csv");
    },

    setAttachment(e) {
      const csvFile = e.target.files[0];
      if (!csvFile) return;
      this.error = [];
      this.warning = [];
      this.tableError = [];
      this.tableWarning = [];

      if (!csvFile.name.endsWith(".csv")) {
        this.error.push("ファイルの種類が不正です。CSVをアップロードしてください");
      }

      if (csvFile.size > 2097152) {
        this.error.push("ファイルサイズが5MBを超えています。添付できるCSVは5MB以下です。");
      }

      if (this.error.length > 0) return;
      const reader = new FileReader();

      reader.onload = e => {
        const codes = new Uint8Array(e.target.result);
        const encoding = Encoding.detect(codes); // SJISの検知は間違う場合があるので、確実にUTF8でなければSJISとする

        const from = encoding === "UTF8" ? "UTF8" : "SJIS";
        let csvString = Encoding.convert(codes, {
          to: "UNICODE",
          from: from,
          type: "string"
        }); //UTF-8 with BOMのときにBOM除去

        if (csvString.charCodeAt(0) === 0xfeff) {
          csvString = csvString.substr(1);
        }

        const csv = Papa.parse(csvString);
        this.csv = this.csvDataCleaning(csv); //同名ファイルの再アップロードを検知するためにリセット

        document.getElementById("file").value = "";
      };

      reader.readAsArrayBuffer(csvFile);
    },

    csvDataCleaning(csv) {
      //エラーテーブルを初期化
      csv.data.forEach(v => {
        const row = [];
        v.forEach(() => row.push(false));
        this.tableError.push(row);
        this.tableWarning.push(row);
      });

      if (csv.errors.length > 0) {
        this.error = csv.errors.map(v => v.message);
      }

      const data = [];
      csv.data.forEach((row, i) => {
        if (i === 0) {
          if (row.join() !== "氏名,氏名かな,所属,言語,メールアドレス,電話番号,お知らせ方法,メモ") {
            this.error.push("見出し行が「氏名」「氏名かな」「所属」「言語」「メールアドレス」「電話番号」「お知らせ方法」「メモ」と異なります");
            this.tableError[i] = this.tableError[i].map(() => true);
          }

          return data.push(row);
        }

        if (row.length !== 8) {
          //ExcelのCSVは最終行に空行を作るので許容する
          if (!(row.length === 1 && i === csv.data.length - 1)) {
            this.error.push(i + 1 + "行目の値が9列ではありません");
            this.tableError[i] = this.tableError[i].map(() => true);
            this.tableWarning[i] = this.tableWarning[i].map(() => true);
            return data.push(row);
          } else {
            return;
          }
        }

        row = row.map((cell, j) => {
          cell = Encoding.toHankakuCase(cell).trim();
          let valid = true;

          if (j === 0) {
            if (!cell) {
              this.error.push(i + 1 + "行目の氏名を入力してください。");
              valid = false;
            } else if (!cell.match(/\s/)) {
              this.error.push(i + 1 + "行目の氏名の姓と名の間に半角スペースを入れてください。");
              valid = false;
            }

            if (cell) {
              if (cell.match(/\?/) || cell.match(/__/)) {
                this.warning.push(i + 1 + "行目の氏名に文字化けが含まれる可能性があります。");
                valid = false;
              }
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 1) {
            if (cell && !cell.match(/\s/)) {
              this.error.push(i + 1 + "行目の氏名かなの姓と名の間に半角スペースを入れてください。");
              valid = false;
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 2 && cell) {
            if (cell.match(/\?/) || cell.match(/__/)) {
              this.warning.push(i + 1 + "行目の所属に文字化けが含まれる可能性があります。");
              valid = false;
            }
          }

          if (j === 3 && cell && !(cell === "日本語" || cell === "英語" || cell === "スペイン語" || cell === "中国語")) {
            this.error.push(i + 1 + "行目の言語は「日本語」「英語」「スペイン語」「中国語」のいずれかを入力するか、空欄(日本語になります)としてください。");
            valid = false;
          }

          if (j === 4 && cell) {
            if (!emailValidator.validate(cell)) {
              this.error.push(i + 1 + "行目のメールアドレスの形式が正しくありません。");
              valid = false;
            }
          } else if (j === 4 && !cell && row[6] === "メール") {
            this.error.push(i + 1 + "行目のお知らせ方法に「メール」が指定されています。メールアドレスを入力してください。");
            valid = false;
          }

          if (j === 5 && cell) {
            if (!/^0/.test(cell)) {
              cell = "0" + cell;
            }

            const regex = new RegExp(/^[0-9]{10,11}$/);

            if (!regex.test(cell)) {
              this.error.push(i + 1 + "行目の電話番号を半角数字のみ、10桁あるいは11桁で入力してください。");
              valid = false;
            }

            if (data.find(v => v[5] === cell)) {
              this.error.push(i + 1 + "行目の電話番号が重複しています");
              valid = false;
            }
          } else if (j === 5 && !cell && row[6] === "電話") {
            this.error.push(i + 1 + "行目のお知らせ方法に「電話」が指定されています。メールアドレスを入力してください。");
            valid = false;
          }

          if (j === 6) {
            if (!cell || cell && cell !== "電話" && cell !== "メール") {
              this.error.push(i + 1 + "行目のお知らせ方法は「メール」か「電話」のいずれかを入力してください。");
              valid = false;
            }
          }

          if (j === 8 && cell && this.error.length === 0) {
            if (cell.match(/\?/) || cell.match(/__/)) {
              this.warning.push(i + 1 + "行目のメモに文字化けが含まれる可能性があります。");
              valid = false;
            }
          }

          if (!valid) this.tableError[i][j] = true;
          return cell;
        });
        data.push(row);
      });
      return data;
    },

    saveData() {
      this.isLoading = true;
      const localeMap = {
        日本語: "ja-JP",
        英語: "en-US",
        スペイン語: "es-US",
        中国語: "zh-CN"
      };
      const normalReceiveMethodMap = {
        電話: "phone"
      }; //ヘッダー行の除去

      this.csv.shift();
      const participants = this.csv.map(v => {
        return {
          name: v[0],
          ruby: v[1],
          belonging: v[2],
          locale: localeMap[v[3]],
          email: v[4],
          phone: v[5],
          normal_receive_method: normalReceiveMethodMap[v[6]],
          memo: v[7]
        };
      });
      createData("writer/bulk_write_participant", {
        participants: participants
      }).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "関係者情報一括登録が完了しました",
          title: "登録完了"
        });
        this.showModal = true;
        this.isLoading = false;
      }).catch(error => {
        let message = "関係者情報一括登録に失敗しました";

        if (error.response) {
          if (error.response.status === 409) {
            message = "登録済みの電話番号が含まれています。";
          } else if (error.response.status === 401) {
            message = "重複した電話番号が送信されました";
          }
        }

        this.$store.dispatch("commitModalMessage", {
          message: message,
          title: "登録失敗"
        });
        this.showModal = true;
        this.isLoading = false;
      });
    },

    completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "participants"
      });
    },

    open(value) {
      if (value === "section1") {
        this.section1 = true;
      } else if (value === "section2") {
        this.section2 = true;
      } else if (value === "section3") {
        this.section3 = true;
      }
    },

    close(value) {
      if (value === "section1") {
        this.section1 = false;
      } else if (value === "section2") {
        this.section2 = false;
      } else if (value === "section3") {
        this.section3 = false;
      }
    }

  }
};