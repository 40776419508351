// @ts-check
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import {
  getAbsentAlert as commonGetAbsentAlert,
  assignAbsentBudge as commonAssignAbsentBudge,
} from "@/services/common";

export const useAbsentAlertStore = defineStore("absentAlert", () => {
  const absentList3 = ref([]);
  const absentList20 = ref([]);
  const absentList30 = ref([]);
  const latestDekitusByStudent = ref([]);

  const absentcontinuouslyObj = computed(() => {
    const obj = {};
    absentList3.value.forEach((v) => {
      obj[v.student_id] = v;
    });

    return obj;
  });

  const absentFrequentlyObj = computed(() => {
    const obj = {};
    absentList20.value.forEach((v) => {
      obj[v.student_id] = v;
    });
    absentList30.value.forEach((v) => {
      obj[v.student_id] = v;
    });

    return obj;
  });

  const getAbsentAlert = async () => {
    ({
      absentList3: absentList3.value,
      absentList20: absentList20.value,
      absentList30: absentList30.value,
      latestDekitusByStudent: latestDekitusByStudent.value,
    } = await commonGetAbsentAlert());
  };

  const assignAbsentBudge = (student) => {
    return commonAssignAbsentBudge(
      student,
      absentList3.value,
      absentList20.value,
      absentList30.value
    );
  };

  // 既にどこかで取得していた場合は最新データを取得するまで従来のデータを返す
  return {
    absentList3,
    absentList20,
    absentList30,
    latestDekitusByStudent,
    absentcontinuouslyObj,
    absentFrequentlyObj,
    getAbsentAlert,
    assignAbsentBudge,
  };
});
