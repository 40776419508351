// @ts-check
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useGradeClassroomStore } from "@/stores/gradeClassroom";

export const useGroupStore = defineStore("group", () => {
  const groups = ref([]);

  const gradeClassroomStore = useGradeClassroomStore();
  // 関係者のグループ及び卒業済みクラスのグループを取り除く;
  const studentGroups = computed(() =>
    groups.value.filter(
      (v) =>
        v.type === "student" &&
        (v.class_id === 0 ||
          gradeClassroomStore.classrooms.some((w) => w.id === v.id))
    )
  );

  return { groups, studentGroups };
});
