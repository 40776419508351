import "core-js/modules/es.array.push.js";
import MessageModal from "../common/MessageModal.vue";
import { mapState } from "vuex";
import { getData, createData, updateData } from "@/services/axios";
import { deleteData } from "../../services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import ConfirmModal from "@/components/common/ConfirmModal";
import Encoding from "encoding-japanese";
import RowItem from "../RowItem";
export default {
  name: "CreateClassRoom",
  components: {
    RowItem,
    MessageModal,
    LoadingMessage,
    ConfirmModal
  },
  data: () => ({
    isLoading: {
      getData: false,
      postData: false,
      deleteData: false
    },
    loadingMessage: "",
    classroom: {},
    classroomGrades: [],
    showModal: false,
    createSuccess: false,
    error: {
      grade: null,
      name: null
    },
    showConfirmModal: false,
    confirmMessageTitle: "",
    confirmMessage: "",
    studentGrades: []
  }),
  computed: { ...mapState(["grades", "classrooms"]),

    disableCheckbox() {
      return gradeId => {
        return this.studentGrades.some(v => v === gradeId);
      };
    }

  },

  created() {
    if (this.$route.params.id) {
      this.setData();
    }
  },

  methods: {
    setData() {
      if (this.$route.params.id) {
        this.isLoading.getData = true;
        getData("classrooms/" + this.$route.params.id + "?with[]=grade&with[]=student").then(data => {
          if (data) {
            this.classroom = data.items[0];

            if (this.classroom.grade) {
              this.classroomGrades = this.classroom.grade.map(v => v.id);
            }
          }

          let sutdentGradeId;

          for (let i in this.classroom.student) {
            if (this.classroom.student[i].grade_id !== sutdentGradeId) {
              for (let j in this.classroomGrades) {
                if (this.classroom.student[i].grade_id === this.classroomGrades[j]) {
                  sutdentGradeId = this.classroom.student[i].grade_id;
                  this.studentGrades.push(sutdentGradeId);
                }
              }
            }
          }
        }).catch(error => {
          console.log(error);
        }).then(() => {
          this.isLoading.getData = false;
        });
      }
    },

    handleChange(e) {
      this.classroom.name = Encoding.toHankakuCase(e.target.value).trim();
    },

    saveData() {
      let valid = true;
      this.error = {
        name: null
      };

      if (this.classroomGrades.length <= 0) {
        this.error.grade = "学年を入力してください。";
        valid = false;
      }

      if (!this.classroom.name) {
        this.error.name = "クラス名を入力してください。";
        valid = false;
      }

      if (this.classrooms.find(v => v.id !== this.$route.params.id && v.name === this.classroom.name)) {
        this.error.name = "既存のクラスと重複しています。";
        valid = false;
      }

      if (!valid) {
        return;
      }

      this.classroom.name = Encoding.toHankakuCase(this.classroom.name).trim();
      this.isLoading.postData = true;

      if (this.$route.params.id) {
        this.loadingMessage = "クラスデータを更新しています。"; // 更新の際にはindexを変更しない

        updateData("writer/write_classroom/" + this.$route.params.id, {
          grades: this.classroomGrades,
          name: this.classroom.name
        }).then(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "クラス更新が完了しました",
            title: "更新完了"
          });
          this.createSuccess = true; //作成したクラスを取得してVuexに再格納

          getData("reader/get_classroom_with_students_count").then(data => {
            this.$store.dispatch("commitClassroom", data.items);
            this.gradeClassroomStore.classrooms = data.items; // pinia使用のため重複管理を許容する
          });
        }).catch(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "更新に失敗しました。",
            title: "エラー"
          });
          this.showModal = true;
        }).then(() => {
          this.showModal = true;
          this.isLoading.postData = false;
        });
      } else {
        // classroom.indexでは本来複式学級の正しいindexを表現できず、DB設計が破綻している。やむをえず既存のindexの最大値を取る
        const targetIndex = Number.isInteger(Math.max(...this.grades.filter(v => this.classroomGrades.includes(v.id)).map(v => Math.max(...v.classroom.map(c => c.index))))) ? Math.max(...this.grades.filter(v => this.classroomGrades.includes(v.id)).map(v => Math.max(...v.classroom.map(c => c.index)))) : 1;
        this.loadingMessage = "クラスデータを登録しています。";
        createData("writer/write_classroom", {
          grades: this.classroomGrades,
          name: this.classroom.name,
          index: targetIndex
        }).then(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "クラス登録が完了しました",
            title: "登録完了"
          });
          this.createSuccess = true; //作成したクラスを取得してVuexに再格納

          getData("reader/get_classroom_with_students_count").then(data => {
            this.$store.dispatch("commitClassroom", data.items);
            this.gradeClassroomStore.classrooms = data.items; // pinia使用のため重複管理を許容する
          });
        }).catch(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "作成に失敗しました。",
            title: "エラー"
          });
          this.showModal = true;
        }).then(() => {
          this.showModal = true;
          this.isLoading.postData = false;
        });
      }
    },

    confirmDelete() {
      this.confirmMessageTitle = "クラス削除";
      this.confirmMessage = "クラスを削除します。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
    },

    removeData() {
      if (this.$route.params.id) {
        deleteData("eraser/delete_classroom/" + this.$route.params.id).then(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "クラス削除が完了しました",
            title: "削除完了"
          });
          this.createSuccess = true; //作成したクラスを取得してVuexに再格納

          getData("reader/get_classroom_with_students_count").then(data => {
            this.$store.dispatch("commitClassroom", data.items);
            this.gradeClassroomStore.classrooms = data.items; // pinia使用のため重複管理を許容する
          });
        }).catch(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "削除に失敗しました。クラスに生徒が存在する可能性があります。",
            title: "エラー"
          });
          this.showModal = true;
        }).then(() => {
          this.showModal = true;
          this.showConfirmModal = false;
        });
      }
    },

    cancelConfirm() {
      this.showConfirmModal = false;
    },

    completeCreate() {
      this.showModal = false;

      if (this.createSuccess) {
        this.$router.push({
          name: "classrooms"
        });
      }
    }

  }
};