import Vue from "vue";
import Router from "vue-router";
import { isLoggedIn } from "@/services/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    /* -------------------
     * 認証が必要なルート
     * ------------------ */
    {
      path: "/initialize",
      name: "appInit",
      component: require("@/components/common/AppInit.vue").default,
      meta: { needAuth: true },
    },
    // ダッシュボード
    {
      path: "/",
      name: "dashboard",
      component: require("@/components/dashboard/Index.vue").default,
      meta: { needAuth: true },
    },
    // 初回ログイン
    {
      path: "/agreements",
      name: "agreements",
      component: require("@/components/agreements/Index.vue").default,
      meta: { needAuth: true },
    },
    // お知らせ
    {
      path: "/messages",
      name: "messages",
      component: require("@/components/messages/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/messages/detail/:id",
      name: "messages.detail",
      component: require("@/components/messages/Detail.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/messages/status/:id",
      name: "messages.status",
      component: require("@/components/messages/Status.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/messages/create",
      name: "messages.create",
      component: require("@/components/messages/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/messages/edit/:id",
      name: "messages.edit",
      component: require("@/components/messages/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/messages/edit_confirm/:id",
      name: "messages.edit_confirm",
      component: require("@/components/messages/EditConfirm.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/messages/templates",
      name: "messages.templates",
      component: require("@/components/messages/templates/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/messages/templates/create",
      name: "messages.templates.create",
      component: require("@/components/messages/templates/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/messages/templates/edit/:id",
      name: "messages.templates.edit",
      component: require("@/components/messages/templates/Edit.vue").default,
      meta: { needAuth: true },
    },
    // 出欠席管理
    {
      path: "/attendances",
      name: "attendances",
      component: require("@/components/attendances/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/attendances/monthly",
      name: "attendances.monthly",
      component: require("@/components/attendances/Monthly.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/attendances/classroom",
      name: "attendances.classroom",
      component: require("@/components/attendances/Classroom.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/attendances/student_select",
      name: "attendances.student_select",
      component: require("@/components/attendances/StudentSelect.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/attendances/create",
      name: "attendances.create",
      component: require("@/components/attendances/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/attendances/edit/:id",
      name: "attendances.edit",
      component: require("@/components/attendances/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/attendances/detail/",
      name: "attendances.detail",
      component: require("@/components/attendances/Detail.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/attendances/summary",
      name: "attendances.summary",
      component: require("@/components/attendances/Summary.vue").default,
      meta: { needAuth: true },
    },

    // 生徒管理
    {
      path: "/students",
      name: "students",
      component: require("@/components/students/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/students/create",
      name: "students.create",
      component: require("@/components/students/EditStudent.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/students/edit/:id",
      name: "students.edit",
      component: require("@/components/students/EditStudent.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/students/create_guardian/",
      name: "guardians.create",
      component: require("@/components/students/EditGuardian.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/students/edit_guardian/:id",
      name: "guardians.edit",
      component: require("@/components/students/EditGuardian.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/students/detail/:id",
      name: "students.detail",
      component: require("@/components/students/Detail.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/students/delete/:id",
      name: "students.delete",
      component: require("@/components/students/Delete.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/students/qr_gen",
      name: "students.qr_generate",
      component: require("@/components/students/QrGen.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/students/csv_upload",
      name: "students.csv_upload",
      component: require("@/components/students/CSVUpload.vue").default,
      meta: { needAuth: true },
    },

    // アンケート
    {
      path: "/enquetes",
      name: "enquetes",
      component: require("@/components/enquetes/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/enquetes/detail/:id",
      name: "enquetes.detail",
      component: require("@/components/enquetes/Detail.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/enquetes/status/:id",
      name: "enquetes.status",
      component: require("@/components/enquetes/Status.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/enquetes/result/:id",
      name: "enquetes.result",
      component: require("@/components/enquetes/Result.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/questions/summary",
      name: "questions.summary",
      component: require("@/components/questions/Summary.vue").default,
      meta: { needAuth: true },
    },

    // クラス管理
    {
      path: "/classrooms",
      name: "classrooms",
      component: require("@/components/classrooms/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/classrooms/create",
      name: "classrooms.create",
      component: require("@/components/classrooms/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/classrooms/edit/:id",
      name: "classrooms.edit",
      component: require("@/components/classrooms/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/classrooms/assignment/:id",
      name: "classrooms.assignment",
      component: require("@/components/classrooms/Assignment.vue").default,
      meta: { needAuth: true },
    },

    // グループ管理
    {
      path: "/groups",
      name: "groups",
      component: require("@/components/groups/Index.vue").default,
    },
    {
      path: "/groups/create",
      name: "groups.create",
      component: require("@/components/groups/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/groups/edit/:id",
      name: "groups.edit",
      component: require("@/components/groups/Edit.vue").default,
      meta: { needAuth: true },
    },

    // 学年管理
    {
      path: "/grades",
      name: "grades",
      component: require("@/components/grades/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/grades/create",
      name: "grades.create",
      component: require("@/components/grades/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/grades/edit/:id",
      name: "grades.edit",
      component: require("@/components/grades/Edit.vue").default,
      meta: { needAuth: true },
    },

    // 先生管理
    {
      path: "/teachers",
      name: "teachers",
      component: require("@/components/teachers/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/teachers/create",
      name: "teachers.create",
      component: require("@/components/teachers/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/teachers/edit/:id",
      name: "teachers.edit",
      component: require("@/components/teachers/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/teachers/csv_upload",
      name: "teachers.csv_upload",
      component: require("@/components/teachers/CSVUpload.vue").default,
      meta: { needAuth: true },
    },

    // 関係者管理
    {
      path: "/participants",
      name: "participants",
      component: require("@/components/participants/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/participants/create",
      name: "participants.create",
      component: require("@/components/participants/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/participants/edit/:id",
      name: "participants.edit",
      component: require("@/components/participants/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/participants/qr_gen",
      name: "participants.qr_generate",
      component: require("@/components/participants/QrGen.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/participants/csv_upload",
      name: "participants.csv_upload",
      component: require("@/components/participants/CSVUpload.vue").default,
      meta: { needAuth: true },
    },
    // 年間予定
    {
      path: "/events",
      name: "events",
      component: require("@/components/events/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/events/create",
      name: "events.create",
      component: require("@/components/events/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/events/edit/:id",
      name: "events.edit",
      component: require("@/components/events/Edit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/events/csv_upload",
      name: "events.csv_upload",
      component: require("@/components/events/CSVUpload.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/logs/login",
      name: "logs.login",
      component: require("@/components/logs/Login.vue").default,
      meta: { needAuth: true },
    },

    {
      path: "/elevate",
      name: "elevate",
      component: require("@/components/elevate/Index.vue").default,
      meta: { needAuth: true },
    },
    // News
    {
      path: "/news",
      name: "news",
      component: require("@/components/news/Index.vue").default,
      meta: { needAuth: true },
    },

    // 掲示板
    {
      path: "/forums",
      name: "forums",
      component: require("@/components/forums/ForumIndex.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/forums/create",
      name: "forums.create",
      component: require("@/components/forums/ForumEdit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/forums/edit/:id",
      name: "forums.edit",
      component: require("@/components/forums/ForumEdit.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/forums/detail/:id",
      name: "forums.detail",
      component: require("@/components/forums/ForumDetail.vue").default,
      meta: { needAuth: true },
    },
    // 学習支援ツールID連携
    {
      path: "/dekitus",
      name: "dekitus",
      component: require("@/components/dekitus/Index.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/dekitus_setting",
      name: "dekitus.setting",
      component: require("@/components/dekitus/Setting.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/dekitus/detail/",
      name: "dekitus.detail",
      component: require("@/components/dekitus/Detail.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/dekitus/monthly",
      name: "dekitus.monthly",
      component: require("@/components/dekitus/Monthly.vue").default,
      meta: { needAuth: true },
    },

    // 自治体掲示板
    {
      path: "/gov_forums",
      name: "gov.forum",
      component: require("@/components/gov/GovForumIndex.vue").default,
      meta: { needAuth: true },
    },
    {
      path: "/gov_forums/:id",
      name: "gov.show",
      component: require("@/components/gov/GovForumShow.vue").default,
      meta: { needAuth: true },
    },

    /* -------------------
     * 認証不要（あるいはできない）なルート
     * ------------------ */
    {
      path: "/login",
      name: "login",
      component: require("@/components/login/Index.vue").default,
    },
    {
      path: "/login/mfa",
      name: "login.mfa",
      component: require("@/components/login/Mfa.vue").default,
    },
    {
      path: "/auth/callback",
      name: "auth.callback",
      component: require("@/components/auth/Callback.vue").default,
    },

    { path: "*", component: require("@/components/NotFound").default }, // catch all
  ],
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
      return savePosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.needAuth)) {
    if (to.fullPath !== "/initialize") {
      localStorage.setItem("redirectBack", to.fullPath);
    }

    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
