import { getData, createData } from "@/services/axios";
import { mapState } from "vuex";
import LoadingMessage from "@/components/common/LoadingMessage";
import RowItem from "../RowItem";
export default {
  name: "Assignment",
  components: {
    RowItem,
    LoadingMessage
  },
  data: () => ({
    pageTitle: "学校管理 - クラス分け",
    otherClassPullDown: {
      grade: 0,
      class: 0
    },
    isLoading: {
      students: true,
      postData: false
    },
    currentGrade: {},
    currentGradeId: null,
    currentClassroomId: null,
    students: [],
    showModal: false,
    filterKeyword: "",
    filterNotSetOnly: false,
    filteredStudents: [],
    showSmallWindow: false
  }),
  head: {
    title: function () {
      return {
        inner: this.pageTitle
      };
    },
    meta: []
  },
  computed: { ...mapState(["school", "grades", "classrooms", "studentAddress"]),
    filteredNameList: function () {
      let students = this.filteredStudents;
      let keyword = this.filterKeyword;

      if (keyword) {
        students = students.filter(v => {
          return Object.keys(v).some(k => String(v[k]).indexOf(this.filterKeyword) > -1);
        });
      }

      return students;
    },
    filteredClassrooms: function () {
      return this.classrooms.filter(v => {
        if (this.currentGrade && this.currentGrade.classroom) {
          return this.currentGrade.classroom.find(c => Number(c.id) === Number(v.id));
        }
      });
    },
    classMemberCounts: function () {
      const counts = [];
      this.classrooms.forEach(v => {
        const c = this.students.filter(s => s.class_id === v.id);
        counts[v.id] = c.length;
      });
      return counts;
    },
    miniWinClassList: function () {
      const newList = this.classrooms.filter(v => v.grade && v.grade[0].id === this.otherClassPullDown.grade);

      if (newList && newList[0]) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.otherClassPullDown.class = newList[0].id;
      }

      return newList;
    },
    notSetStudents: function () {
      return this.students.filter(s => !s.class_id).length;
    }
  },

  created() {
    this.setData();
  },

  methods: {
    setData: function () {
      getData("students?with=classroom").then(data => {
        this.students = this.filteredStudents = data.items.map(v => {
          v.original_class_id = v.class_id;
          return v;
        }).sort((a, b) => {
          return a.ruby < b.ruby ? -1 : a.ruby > b.ruby ? 1 : 0;
        });
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.students = false;
        this.filterList();
      });
      this.currentGrade = this.grades.find(v => Number(v.id) === Number(this.$route.params.id));
      this.currentGradeId = Number(this.$route.params.id);
    },
    filterList: function () {
      let students = this.students;

      if (this.currentGradeId) {
        this.currentGrade = this.grades.find(v => v.id === this.currentGradeId);
        students = students.filter(v => v.grade_id === Number(this.currentGradeId));
      }

      if (this.currentClassroomId) {
        students = students.filter(v => v.original_class_id === Number(this.currentClassroomId));
      }

      return this.filteredStudents = students;
    },

    resetFilter() {
      this.filterKeyword = "";
      this.currentClassroomId = null;
      this.filterList();
    },

    openSmallWindow: function (student_id) {
      // todo 学年一覧とクラス一覧を取得して１つ目のアイテムの値で初期化
      this.otherClassPullDown.class = 1;
      this.otherClassPullDown.grade = 1;
      this.showSmallWindow = student_id;
    },
    setOtherClass: function (student_id) {
      let index = this.students.findIndex(v => v.id === student_id);
      this.students[index].grade_id = this.otherClassPullDown.grade;
      this.students[index].class_id = this.otherClassPullDown.class; // リスト再生成

      this.filterList();
      this.showSmallWindow = false;
    },
    saveAssignment: function () {
      this.isLoading.postData = true;
      createData("writer/write_assignment", {
        students: this.filteredStudents.map(v => {
          return {
            id: v.id,
            class_id: v.class_id
          };
        })
      }).then(() => {
        this.showModal = true;
        return getData("teachers/whoami");
      }).then(data => {
        this.$store.dispatch("commitSelfProfile", data.items[0]);
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.postData = false;
      });
    }
  }
};