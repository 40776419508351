const Text = {
  attendances: {
    type: {
      0: "出席",
      1: "欠席",
      2: "遅刻",
      3: "早退",
    },
  },
};
export default Text;
