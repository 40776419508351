var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_class_index page_school_teacher"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("学校管理 - 登録教職員一覧")]), _c('form', {
    staticClass: "gray_frame",
    attrs: {
      "action": "",
      "method": "get"
    }
  }, [_c(_setup.RowItem, [_c('div', {
    staticClass: "col_5"
  }, [_c(_setup.RowItem, [_c('label', {
    staticClass: "col_2",
    attrs: {
      "for": "filter_class"
    }
  }, [_vm._v("学年")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.filterGrade,
      expression: "filterGrade"
    }],
    staticClass: "common_form--select col_9",
    attrs: {
      "id": "filter_class"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _setup.filterGrade = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("すべて")]), _vm._l(_vm.grades, function (grade, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": grade.id
      }
    }, [_vm._v(" " + _vm._s(grade.name) + " ")]);
  })], 2)])], 1), _c('div', {
    staticClass: "col_5"
  }, [_c(_setup.RowItem, [_c('label', {
    staticClass: "col_3",
    attrs: {
      "for": "filter_keyword"
    }
  }, [_vm._v("先生氏名、メールアドレス")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.filterKeyword,
      expression: "filterKeyword"
    }],
    staticClass: "common_form--input col_8",
    attrs: {
      "id": "filter_keyword",
      "type": "text"
    },
    domProps: {
      "value": _setup.filterKeyword
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _setup.filterKeyword = $event.target.value;
      }
    }
  })])], 1), _c('div', {
    staticClass: "col_2 tar"
  }, [_c('input', {
    staticClass: "search_button",
    attrs: {
      "type": "submit",
      "value": "検索"
    },
    on: {
      "click": _setup.handleFilterData
    }
  })])])], 1), _c('section', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "create_button",
    attrs: {
      "to": {
        name: 'teachers.create'
      }
    }
  }, [_vm._v(" 新規登録 ")]), _c('router-link', {
    staticClass: "create_button",
    attrs: {
      "to": {
        name: 'teachers.csv_upload'
      }
    }
  }, [_vm._v(" 一括登録 ")])], 1), _c('p', {
    staticClass: "teachers_count"
  }, [_vm._v(" 表示教職員数　" + _vm._s(_setup.visibleTeachers.length) + "人　/　全教職員数　" + _vm._s(_setup.teachers.length) + "人 ")]), _setup.visibleTeachers.length === 0 && !_setup.isLoading.teachers ? _c('div', [_vm._v(" 該当する先生が見つかりません ")]) : _c('div', {
    staticClass: "teachers_table"
  }, [_c(_setup.RowItem, {
    staticClass: "teachers_table__head"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v(" 先生氏名 "), _c('button', {
    staticClass: "order_button",
    class: {
      active: _setup.sortStatus.key === 'name' && _setup.sortStatus.order === 'desc'
    },
    on: {
      "click": function ($event) {
        return _setup.changeSort('name', 'desc');
      }
    }
  }, [_vm._v(" ↓ ")]), _c('button', {
    staticClass: "order_button",
    class: {
      active: _setup.sortStatus.key === 'name' && _setup.sortStatus.order === 'asc'
    },
    on: {
      "click": function ($event) {
        return _setup.changeSort('name', 'asc');
      }
    }
  }, [_vm._v(" ↑ ")])]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v(" クラス "), _c('button', {
    staticClass: "order_button",
    class: {
      active: _setup.sortStatus.key === 'classroom' && _setup.sortStatus.order === 'desc'
    },
    on: {
      "click": function ($event) {
        return _setup.changeSort('classroom', 'desc');
      }
    }
  }, [_vm._v(" ↓ ")]), _c('button', {
    staticClass: "order_button",
    class: {
      active: _setup.sortStatus.key === 'classroom' && _setup.sortStatus.order === 'asc'
    },
    on: {
      "click": function ($event) {
        return _setup.changeSort('classroom', 'asc');
      }
    }
  }, [_vm._v(" ↑ ")])]), _c('p', {
    staticClass: "col_2"
  }, [_vm._v(" 役割 "), _c('button', {
    staticClass: "order_button",
    class: {
      active: _setup.sortStatus.key === 'role' && _setup.sortStatus.order === 'desc'
    },
    on: {
      "click": function ($event) {
        return _setup.changeSort('role', 'desc');
      }
    }
  }, [_vm._v(" ↓ ")]), _c('button', {
    staticClass: "order_button",
    class: {
      active: _setup.sortStatus.key === 'role' && _setup.sortStatus.order === 'asc'
    },
    on: {
      "click": function ($event) {
        return _setup.changeSort('role', 'asc');
      }
    }
  }, [_vm._v(" ↑ ")])]), _c('p', {
    staticClass: "col_2"
  }), _c('p', {
    staticClass: "col_2"
  })]), _setup.isLoading.teachers || _setup.isLoading.grades ? _c('content-placeholders', {
    staticClass: "teachers_table__body"
  }, _vm._l(5, function (n) {
    return _c(_setup.RowItem, {
      key: n
    }, [_c('p', {
      staticClass: "col_12"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)]);
  }), 1) : _c('div', {
    staticClass: "teachers_table__body"
  }, _vm._l(_setup.visibleTeachers, function (teacher, index) {
    return _c(_setup.RowItem, {
      key: index
    }, [_c('p', {
      staticClass: "col_3 name"
    }, [_vm._v(" " + _vm._s(teacher.name) + " ")]), !teacher.classrooms[0] ? _c('p', {
      staticClass: "col_3"
    }, [_vm._v("（割り当てなし）")]) : _c('p', {
      staticClass: "col_3"
    }, [_vm._l(teacher.classrooms.length > _setup.classroomListLimit ? _setup.classroomListLimit : teacher.classrooms.length, function (n) {
      return _c('span', {
        key: n,
        staticClass: "classroom_name"
      }, [_vm._v(" " + _vm._s(teacher.classrooms[n - 1].classroom_name) + " ")]);
    }), teacher.classrooms.length > _setup.classroomListLimit ? _c('span', {
      staticClass: "classroom_name abbreviated"
    }, [_vm._v(" 他 " + _vm._s(teacher.classrooms.length - _setup.classroomListLimit) + " クラス ")]) : _vm._e()], 2), _c('p', {
      staticClass: "col_2"
    }, [_vm._v(" " + _vm._s(teacher.role_id === 11 ? "管理者" : "") + " ")]), _c('p', {
      staticClass: "col_2"
    }), _c('p', {
      staticClass: "col_2 tar"
    }, [_c('router-link', {
      staticClass: "edit_button",
      attrs: {
        "to": {
          name: 'teachers.edit',
          params: {
            id: teacher.id
          }
        }
      }
    }, [_vm._v(" 編集 ")])], 1)]);
  }), 1)], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };