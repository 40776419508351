var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('loading-message', {
    attrs: {
      "loading-message": _vm.loadingMessage
    }
  });
};

var staticRenderFns = [];
export { render, staticRenderFns };