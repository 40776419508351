export default {
  name: "MonthlyMixin",
  data() {
    return {};
  },
  computed: {
    monthlyYearlySummaryTemplate() {
      // 出席日数と要出席日数は呼び出し元で入れる
      return {
        停忌等: 0,
        要出席日数: 0,
        "欠席（病気）": 0,
        "欠席（事故）": 0,
        出席日数: 0,
        遅刻日数: 0,
        早退日数: 0,
      };
    },
  },
  methods: {
    // 生徒ごとの出席状況
    aggregateStudentSummary(summary, countingType) {
      if (countingType === "停忌等") {
        summary["停忌等"]++;
        summary["要出席日数"]--;
        summary["出席日数"]--;
      } else if (countingType === "病気欠席") {
        summary["欠席（病気）"]++;
        summary["出席日数"]--;
      } else if (countingType === "事故欠席") {
        summary["欠席（事故）"]++;
        summary["出席日数"]--;
      } else if (countingType === "遅刻") {
        summary["遅刻日数"]++;
      } else if (countingType === "早退") {
        summary["早退日数"]++;
      } else if (countingType === "遅早") {
        summary["遅刻日数"]++;
        summary["早退日数"]++;
      }
    },

    // 欠席理由概要の計算
    aggregateMonthlyTypeSummary(v, monthlyTypeSummary) {
      const typeSplit = v.type.split("-");
      if (typeSplit[0] === "欠席") {
        this.appendMonthlyTypeSummary(typeSplit[1], monthlyTypeSummary);
      } else if (typeSplit[0] === "遅刻" || typeSplit[0] === "早退") {
        this.appendMonthlyTypeSummary(typeSplit[0], monthlyTypeSummary);
      } else if (typeSplit[0] === "遅刻早退") {
        this.appendMonthlyTypeSummary("遅刻", monthlyTypeSummary);
        this.appendMonthlyTypeSummary("早退", monthlyTypeSummary);
      }
    },
    appendMonthlyTypeSummary(key, monthlyTypeSummary) {
      if (monthlyTypeSummary[key]) {
        monthlyTypeSummary[key]++;
      } else {
        monthlyTypeSummary[key] = 1;
      }
    },
  },
};
