// @ts-check
import { ref, computed, watch } from "vue";
import { useStore } from "vue2-helpers/vuex";
import moment from "moment";

export const useMonthData = (filter) => {
  const store = useStore();
  /** @type ComputedRef<string[]> */
  const holidays = computed(() => store.state.holidays);

  /**
   * 1か月の日ごとの情報
   *
   * @type Ref<MonthData[]>
   */
  const monthData = ref([]);
  /** 1か月の日数 */
  const days = ref(30);

  const getMonthData = () => {
    const monthDataTemp = [];
    const fdOm = moment(filter.yearMonth).startOf("month");
    days.value = fdOm.daysInMonth();
    for (let i = 0; i < days.value; i++) {
      monthDataTemp.push({
        "YYYY-MM-DD": fdOm.format("YYYY-MM-DD"),
        d: fdOm.format("d"),
        D: fdOm.format("D"),
        isHoliday: holidays.value.includes(fdOm.format("YYYY-MM-DD")),
        weekName: fdOm.format("ddd"),
        isSchoolDayOff: false,
      });
      monthDataTemp[i].isSchoolDayOff =
        monthDataTemp[i].isHoliday ||
        monthDataTemp[i].d === "0" ||
        monthDataTemp[i].d === "6";

      fdOm.add(1, "d");
    }

    monthData.value = monthDataTemp;
  };

  watch(
    () => filter.yearMonth,
    () => getMonthData()
  );

  // created
  getMonthData();

  return { monthData, days };
};
