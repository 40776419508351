var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('section', {
    staticClass: "bulletin-board"
  }, [_c('div', {
    staticClass: "bulletin-board__inner"
  }, [_c('div', {
    staticClass: "bulletin-board__header"
  }, [_c('h2', {
    staticClass: "bulletin-board__title"
  }, [_vm._v("【学校内掲示板】最新記事")]), _c('div', {
    staticClass: "bulletin-board__status"
  }), _c('router-link', {
    staticClass: "show_button",
    attrs: {
      "to": {
        name: 'forums'
      }
    }
  }, [_vm._v(" 一覧を見る ")])], 1), _c('div', {
    staticClass: "bulletin-board__comments"
  }, _vm._l(_setup.forumList, function (forum, index) {
    return _c('article', {
      key: index,
      staticClass: "bulletin-board__comments__item"
    }, [_c('span', {
      staticClass: "icon"
    }, [_vm._v("校")]), _c('h3', {
      staticClass: "title"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'forums.detail',
          params: {
            id: forum.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(forum.title) + " ")])], 1), _c('div', {
      staticClass: "other"
    }, [forum.attachment_count ? _c('div', {
      staticClass: "other__item"
    }, [_c('i', {
      staticClass: "fa fa-paperclip"
    })]) : _vm._e(), _c('div', {
      staticClass: "other__item"
    }, [_c('i', {
      staticClass: "far fa-comment"
    }), _c('span', {
      staticClass: "count"
    }, [_vm._v(_vm._s(forum.school_forum_comment_count))])])]), _c('div', {
      staticClass: "bottom"
    }, [_c('time', {
      staticClass: "date",
      attrs: {
        "datetime": forum.published_at
      }
    }, [_vm._v(" " + _vm._s(_setup.moment(forum.published_at).format("YYYY年MM月DD日 HH時mm分")) + " ")]), forum.author_name ? _c('p', {
      staticClass: "contributor"
    }, [_vm._v(" " + _vm._s(forum.author_name) + "先生 ")]) : _vm._e(), _c('div', {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(_vm._f("shorten")(
    /**@ts-expect-error */
    forum.message, 60)) + " ")])])]);
  }), 0)])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };