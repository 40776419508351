var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _vm.summary.length > 0 ? _c('section', {
    staticClass: "information_frame"
  }, [_c('div', {
    staticClass: "col2_frame__inner"
  }, [_c('div', {
    staticClass: "inner"
  }, _vm._l(_vm.summary, function (c, i) {
    return _c('div', {
      key: i,
      staticClass: "data_box__row"
    }, [_c('h4', {
      staticClass: "data_box__heading"
    }, [_vm._v(" " + _vm._s(c.classroom.name) + " "), _c('router-link', {
      staticClass: "show_button",
      attrs: {
        "to": {
          name: 'attendances.monthly',
          query: {
            classroom_id: c.classroom.id
          }
        }
      }
    }, [_vm._v(" カレンダー ")]), _c('router-link', {
      staticClass: "show_button",
      attrs: {
        "to": {
          name: 'attendances.classroom',
          query: {
            id: [c.classroom.id]
          }
        }
      }
    }, [_vm._v(" 名簿 ")])], 1), _c('dl', {
      staticClass: "data_box"
    }, [_c('div', {
      staticClass: "data_box__item"
    }, [_c('dt', [_vm._v("欠席")]), _c('dd', [_c('p', {
      staticClass: "number"
    }, [_vm._v(_vm._s(c.attendance.absence)), _c('span', [_vm._v("名")])]), _c('p', {
      staticClass: "percentage"
    }, [_vm._v(_vm._s(c.absenceRate) + "%")])])]), _c('div', {
      staticClass: "data_box__item"
    }, [_c('dt', [_vm._v("遅刻")]), _c('dd', [_c('p', {
      staticClass: "number"
    }, [_vm._v(_vm._s(c.attendance.late)), _c('span', [_vm._v("名")])])])]), _c('div', {
      staticClass: "data_box__item"
    }, [_c('dt', [_vm._v("早退")]), _c('dd', [_c('p', {
      staticClass: "number"
    }, [_vm._v(_vm._s(c.attendance.leave)), _c('span', [_vm._v("名")])])])]), _c('div', {
      staticClass: "data_box__item"
    }, [_c('dt', [_vm._v("未登校")]), _c('dd', [_c('p', {
      staticClass: "number"
    }, [_vm._v(_vm._s(c.attendance.noAttend)), _c('span', [_vm._v("名")])])])])]), _c('dl', {}, _vm._l(c.attendanceList, function (attendance, i2) {
      return _c('div', {
        key: i2,
        staticClass: "attendance_list__item"
      }, [i2 === 0 || c.attendanceList[i2 - 1].date !== attendance.date ? _c('dt', {
        staticClass: "attendance_list__dt"
      }, [_c('p', {
        staticClass: "date"
      }, [_vm._v(" " + _vm._s(_vm._f("mmdd")(attendance.date)) + " ")])]) : _vm._e(), _c('dt', {
        staticClass: "attendance_list__dt",
        class: {
          deleted: attendance.isDeleted
        }
      }, [attendance.isHistory ? _c('img', {
        attrs: {
          "src": require("@/assets/images/history.svg"),
          "alt": "history",
          "width": "5%"
        }
      }) : _vm._e(), _c('p', {
        class: `cause icon--${_setup.iconColor(attendance.type)}`
      }, [!attendance.isDeleted ? _c('router-link', {
        attrs: {
          "to": {
            name: 'attendances.edit',
            params: {
              id: attendance.id
            }
          }
        }
      }, [_vm._v(" " + _vm._s(_vm._f("attendanceShort")(attendance.type)) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm._f("attendanceShort")(attendance.type)) + " ")])], 1), _c('p', {
        staticClass: "name"
      }, [_c('router-link', {
        attrs: {
          "to": {
            name: 'attendances.detail',
            query: {
              student_id: attendance.studentId
            }
          }
        }
      }, [_vm._v(" " + _vm._s(attendance.studentName) + " ")])], 1), _c('p', {
        staticClass: "contact_date"
      }, [_vm._v("(" + _vm._s(attendance.createdAt) + "連絡)")])]), _c('dd', {
        staticClass: "attendance_list__dd"
      }, [_vm._v(" " + _vm._s(attendance.guardianMemo) + " " + _vm._s(attendance.comment ? "先生用メモ:" + attendance.comment : "") + " ")])]);
    }), 0)]);
  }), 0)])]) : _vm._e();
};

var staticRenderFns = [];
export { render, staticRenderFns };