import "core-js/modules/es.array.push.js";
import moment from "moment";
import { createData, getData, updateData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import store from "../../services/store.js";
import LoadingMessage from "@/components/common/LoadingMessage";
import AttendanceSelection from "@/components/attendances/AttendanceSelection";
import { mapState } from "vuex";
import RowItem from "../RowItem";
export default {
  store,
  name: "CreateAttendance",
  components: {
    RowItem,
    MessageModal,
    LoadingMessage,
    AttendanceSelection
  },

  data() {
    return {
      showModal: false,
      student: {
        classroom: {}
      },
      type: "",
      date: moment().format("YYYY-MM-DD"),
      isLoading: {
        getData: true,
        postData: false
      },
      guardian_memo: "",
      comment: "",
      validationErrors: {},
      error: null,
      attendanceType: null,
      countingType: null
    };
  },

  computed: { ...mapState(["studentAddress", "attendanceTypes", "attendanceBookCountingTypes"])
  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      if (this.$route.name === "attendances.edit" && this.$route.params.id) {
        getData("attendances/" + this.$route.params.id).then(data => {
          const v = data.items[0];

          if (!v.is_daily_latest) {
            // 最新ではない欠席連絡を編集することはできない
            this.$router.go(-1);
          }

          this.guardian_memo = v.guardian_memo;
          this.attendanceType = v.attendance_type_id;
          this.countingType = v.counting_type_id;
          this.comment = v.comment;
          this.date = v.date;
          this.type = v.type;
          return getData("students/" + v.student_id + "?with=classroom");
        }).then(data => {
          this.student = data.items[0];
        }).catch(error => {
          console.log(error);
        }).then(() => {
          this.isLoading.getData = false;
        });
      } else if (this.$route.query.student_id) {
        getData("students/" + this.$route.query.student_id + "?with=classroom").then(data => {
          this.student = data.items[0];
        }).catch(error => {
          console.log(error);
        }).then(() => {
          this.isLoading.getData = false;
        });
      }
    },

    setType(type) {
      this.type = type;
      const attendanceType = this.attendanceTypes.find(v => v.key === this.type);

      if (attendanceType) {
        this.attendanceType = attendanceType.id;
        this.countingType = attendanceType.attendance_book_counting_type.id;
      } else {
        this.attendanceType = null;
        this.countingType = null;
      }
    },

    resetType() {
      this.type = "";
    },

    saveData() {
      const userInputs = {
        student_id: this.student.id,
        type: this.type,
        date: this.date,
        comment: this.comment,
        attendance_type_id: this.attendanceType,
        counting_type_id: this.countingType
      };

      if (this.validateInput(userInputs)) {
        this.isLoading.postData = true;

        if (this.$route.name === "attendances.edit" && this.$route.params.id) {
          updateData("attendances/" + this.$route.params.id, userInputs).then(() => {
            this.$store.dispatch("commitModalMessage", {
              message: "出欠更新が完了しました",
              title: "登録完了"
            });
            this.showModal = true;
          }).catch(error => {
            console.log(error);
          }).then(() => {
            this.isLoading.postData = false;
          });
        } else {
          createData("attendances", userInputs).then(() => {
            this.$store.dispatch("commitModalMessage", {
              message: "出欠登録が完了しました",
              title: "登録完了"
            });
            this.showModal = true;
          }).catch(error => {
            console.log(error);
          }).then(() => {
            this.isLoading.postData = false;
          });
        }
      }
    },

    validateInput(input) {
      this.validationErrors = {};

      if (!input.type) {
        this.validationErrors.type = "事由を決定してください";
      }

      return Object.keys(this.validationErrors).length === 0;
    },

    completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "dashboard"
      });
    }

  }
};