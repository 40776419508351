import "core-js/modules/es.array.push.js";
import { logout } from "@/services/auth";
import { getData, createData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import moment from "moment";
import { mapStores } from "pinia";
import { useGradeClassroomStore } from "@/stores/gradeClassroom";
import { useGroupStore } from "@/stores/group";
import { useSchoolPlanStore } from "@/stores/schoolPlan";
export default {
  name: "AppInit",
  components: {
    LoadingMessage
  },
  data: () => ({
    pageTitle: "初期化中",
    isLoading: {
      school: true,
      grade: true,
      classroom: true,
      group: true
    },
    loadingMessage: "基本データを読み込んでいます…しばらくお待ち下さい"
  }),
  head: {
    title: function () {
      return {
        inner: this.pageTitle
      };
    },
    meta: []
  },
  computed: { ...mapStores(useGroupStore, useGradeClassroomStore, useSchoolPlanStore)
  },

  created() {
    getData(["schools?with[]=school_plan&with[]=government", // 学校情報
    "grades?with=classroom", // 学年
    "reader/get_classroom_with_students_count", // クラス
    "groups", // グループ
    "teachers/whoami", // ログインユーザ
    "holidays", // 祝日
    "attendance_types?with=attendance_book_counting_type", // 欠席種別
    "attendance_book_counting_types" // 出席簿表示種別
    ]).then(data => {
      this.$store.dispatch("commitLoginStatus", true);
      const school = data[0].items[0];

      if (!school) {
        alert("あなたの学校はCOCOOの契約を終了されました。ご利用ありがとうございました");
        logout();
      }

      this.schoolPlanStore.schoolPlan = school.school_plan.allow;
      delete school.school_plan;
      this.$store.dispatch("commitSchool", school);
      const currentFiscalYear = moment().add(-3, "month").year();
      const threeYearsAgo = moment().add(-3, "month").add(-3, "year").year();
      const firstFiscalYear = moment(school.created_at).add(-3, "month").year() > threeYearsAgo ? moment(school.created_at).add(-3, "month").year() : threeYearsAgo;
      const fiscalYears = [];
      let i;

      for (i = firstFiscalYear; i <= currentFiscalYear; i++) {
        if (i === currentFiscalYear) {
          fiscalYears.push({
            value: currentFiscalYear,
            label: "今年度"
          });
        } else {
          fiscalYears.push({
            value: i,
            label: i + "年度"
          });
        }
      }

      this.$store.dispatch("commitFiscalYears", fiscalYears);
      const grades = data[1].items;
      this.$store.dispatch("commitGrade", grades);
      this.gradeClassroomStore.grades = grades; // pinia使用のため重複管理を許容する

      const classrooms = data[2].items;
      this.$store.dispatch("commitClassroom", classrooms);
      this.gradeClassroomStore.classrooms = classrooms; // pinia使用のため重複管理を許容する

      this.groupStore.groups = data[3].items;
      const me = data[4].items[0];
      this.$store.dispatch("commitSelfProfile", me);
      this.$store.dispatch("commitHolidays", data[5].items);
      this.$store.dispatch("commitAttendanceTypes", data[6].items);
      this.$store.dispatch("commitAttendanceBookCountingTypes", data[7].items); // 学校の区分によって生徒の呼称を変える

      switch (data[0].items[0].type) {
        case "el":
          this.$store.dispatch("commitStudentAddress", "児童");
          break;

        case "jh":
          this.$store.dispatch("commitStudentAddress", "生徒");
          break;

        case "hs":
          this.$store.dispatch("commitStudentAddress", "生徒");
          break;

        default:
          this.$store.dispatch("commitStudentAddress", "生徒");
          break;
      }

      if (me.plan === "standard") {
        this.$store.dispatch("commitEnableEmergencyCall", true);
      }
    }).catch(error => {
      console.log(error);
    }).then(() => {
      if (this.$store.state.me.last_login) {
        createData("writer/write_last_login").then(() => {
          if (localStorage.getItem("redirectBack")) {
            this.$router.push(localStorage.getItem("redirectBack"));
          } else {
            this.$router.push({
              name: "dashboard"
            });
          }
        }).catch(error => {
          console.log(error);
        });
      } else {
        this.$router.push({
          name: "agreements"
        });
      }
    });
    setTimeout(() => this.loadingMessage = "読み込みに時間がかかっています。このまま画面が切り替わらない場合はブラウザの再読込をお試しください", 10000);
  }

};