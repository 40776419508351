import useSWRV from "swrv";
import moment from "moment";
import { getData } from "@/services/axios";
export default {
  __name: 'LatestGovernmentForum',

  setup(__props) {
    // @ts-check
    const {
      data: forumList
    } = useSWRV("forum-latest", async () => {
      const params = new URLSearchParams({
        limit: String(10)
      });
      const result = await getData(`government_forums?${params.toString()}`);
      return result.items;
    }, {
      refreshInterval: 60000,
      // 毎分更新
      revalidateOnFocus: false
    });
    return {
      __sfc: true,
      forumList,
      moment
    };
  }

};