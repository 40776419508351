import { ref, watch } from "vue";
export default {
  __name: 'SettingRow',
  props: {
    grades: {
      type: Array,
      default: () => []
    },
    dekitusId: {
      type: String,
      default: ""
    },
    currentStudent: {
      type: Object,
      default: () => {}
    },
    dekitusStudents: {
      type: Array,
      default: () => []
    }
  },
  emits: ["handleStudentChange"],

  setup(__props, {
    emit
  }) {
    const props = __props;
    const selectedGradeId = ref(null);
    const selectedClassroomId = ref(null);
    const selectedStudentId = ref(props.currentStudent && props.currentStudent.id ? props.currentStudent.id : null);
    watch(() => selectedStudentId.value, async () => {
      emit("handleStudentChange", {
        dekitusId: props.dekitusId,
        studentId: selectedStudentId.value
      });
    });
    return {
      __sfc: true,
      props,
      selectedGradeId,
      selectedClassroomId,
      selectedStudentId,
      emit
    };
  }

};