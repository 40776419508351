import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: "",
    message: "",
    enquete: {
      enqueteName: "",
      period: "",
      questions: [{ subject: "", options: ["", "", "", ""], type: "single" }],
    },
    selectedStudent: {
      grade: "",
      classroom: "",
      id: "",
      name: "",
    },
    me: {},
    school: {},
    studentAddress: "",
    grades: [],
    classrooms: [],
    isLoggedIn: false,
    holidays: [],
    capabilities: {
      enableEmergencyCall: false,
    },
    attendanceTypes: [],
    attendanceBookCountingTypes: [],
    news: [],
    fiscalYears: [],
    showElevatingNotice: false,
  },
  mutations: {
    mutationLoginStatus(state, payload) {
      state.isLoggedIn = payload;
    },
    mutationModalMessage(state, payload) {
      state.message = payload.message;
      state.title = payload.title;
    },
    mutationEnquete(state, payload) {
      state.enquete = payload;
    },
    mutationSelectedStudent(state, payload) {
      state.selectedStudent = payload;
    },
    mutationSchool(state, payload) {
      state.school = payload;
    },
    mutationGrade(state, payload) {
      state.grades = payload;
    },
    mutationClassroom(state, payload) {
      state.classrooms = payload;
    },
    mutationStudentAddress(state, payload) {
      state.studentAddress = payload;
    },
    mutationSelfProfile(state, payload) {
      state.me = payload;
    },
    mutationHolidays(state, payload) {
      state.holidays = payload;
    },
    mutationEnableEmergencyCall(state, payload) {
      state.capabilities.enableEmergencyCall = payload;
    },
    mutationAttendanceTypes(state, payload) {
      state.attendanceTypes = payload;
    },
    mutationAttendanceBookCountingTypes(state, payload) {
      state.attendanceBookCountingTypes = payload;
    },
    mutationNews(state, payload) {
      state.news = payload;
    },
    mutationFiscalYears(state, payload) {
      state.fiscalYears = payload;
    },
    mutationShowElevatingNotice(state, payload) {
      state.showElevatingNotice = payload;
    },
  },
  actions: {
    commitLoginStatus({ commit }, payload) {
      commit("mutationLoginStatus", payload);
    },
    commitModalMessage({ commit }, payload) {
      commit("mutationModalMessage", payload);
    },
    commitEnquete({ commit }, payload) {
      commit("mutationEnquete", payload);
    },
    commitSelectedStudent({ commit }, payload) {
      commit("mutationSelectedStudent", payload);
    },
    commitSchool({ commit }, payload) {
      commit("mutationSchool", payload);
    },
    commitGrade({ commit }, payload) {
      commit("mutationGrade", payload);
    },
    commitClassroom({ commit }, payload) {
      commit("mutationClassroom", payload);
    },
    commitStudentAddress({ commit }, payload) {
      commit("mutationStudentAddress", payload);
    },
    commitSelfProfile({ commit }, payload) {
      commit("mutationSelfProfile", payload);
    },
    commitHolidays({ commit }, payload) {
      commit("mutationHolidays", payload);
    },
    commitEnableEmergencyCall({ commit }, payload) {
      commit("mutationEnableEmergencyCall", payload);
    },
    commitAttendanceTypes({ commit }, payload) {
      commit("mutationAttendanceTypes", payload);
    },
    commitAttendanceBookCountingTypes({ commit }, payload) {
      commit("mutationAttendanceBookCountingTypes", payload);
    },
    commitNews({ commit }, payload) {
      commit("mutationNews", payload);
    },
    commitFiscalYears({ commit }, payload) {
      commit("mutationFiscalYears", payload);
    },
    commitShowElevatingNotice({ commit }, payload) {
      commit("mutationShowElevatingNotice", payload);
    },
  },
});
