// @ts-check
import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useGradeClassroomStore = defineStore("gradeClassroom", () => {
  /** @type {Ref<Grade[]>} */
  const grades = ref([]);

  /** @type {Ref<ClassRoom[]>} */
  const classrooms = ref([]);

  /** 一人以上の生徒が所属するクラス */
  const leastStudentClassrooms = computed(() =>
    classrooms.value.filter((v) => v.students_count)
  );

  /** 学校の生徒総数 */
  const totalStudentCount = computed(() =>
    classrooms.value.reduce((sum, v) => {
      sum += v.students_count;
      return sum;
    }, 0)
  );

  return { grades, classrooms, leastStudentClassrooms, totalStudentCount };
});
