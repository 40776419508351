import "core-js/modules/es.array.push.js";
import MessageModal from "../common/MessageModal.vue";
import { mapState } from "vuex";
import { createData, getData, updateData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import RowItem from "../RowItem";
export default {
  name: "EditStudent",
  components: {
    RowItem,
    MessageModal,
    LoadingMessage
  },

  data() {
    return {
      showModal: false,
      student: {},
      id: this.$route.params.id,
      new_id: false,
      isLoading: {
        getData: false,
        postData: false
      },
      loadingMessage: "",
      error: {
        grade_id: null,
        class_id: null,
        name: null,
        ruby: null
      }
    };
  },

  computed: { ...mapState(["classrooms", "grades", "studentAddress"]),
    classroomList: function () {
      let newList = this.classrooms;

      if (this.student.grade_id) {
        const currentGrade = this.grades.filter(v => v.id === this.student.grade_id)[0];
        newList = newList.filter(v => currentGrade.classroom.find(c => c.id === v.id));
      }

      return newList;
    }
  },

  created() {
    this.setData();
  },

  methods: {
    setData: function () {
      if (this.id) {
        this.isLoading.getData = true;
        getData("students/" + this.$route.params.id + "?with[]=guardian&with[]=classroom").then(data => {
          if (data) {
            this.student = data.items[0];

            if (this.student.guardian) {
              const paths = this.student.guardian.map(v => "guardians/" + v.id + "?with=user");
              getData(paths).then(data => {
                if (data) {
                  this.student.guardian = data.map(v => {
                    const d = v.items[0]; //編集用にemailを1回層上げる

                    d.email = d.user.email;
                    return d;
                  });
                }
              });
            }
          }
        }).catch(error => {
          console.log(error);
        }).then(() => {
          this.isLoading.getData = false;
        });
      }
    },

    postStudent() {
      createData("writer/write_student", {
        student: this.student
      }).then(data => {
        this.new_id = data.message.id;
        this.$store.dispatch("commitModalMessage", {
          message: this.studentAddress + "情報登録が完了しました",
          title: "登録完了"
        });
        this.showModal = true;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.postData = false;
      });
    },

    putStudent() {
      updateData("writer/write_student/" + this.id, {
        student: this.student
      }).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: this.studentAddress + "情報更新が完了しました",
          title: "更新完了"
        });
        this.showModal = true;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.postData = false;
      });
    },

    saveData() {
      let valid = true;
      this.error = {
        grade_id: null,
        class_id: null,
        name: null,
        ruby: null
      };

      if (!this.student.grade_id) {
        const message = "学年を指定してください。";
        this.error.grade_id = message;
        valid = false;
      }

      if (!this.student.class_id) {
        const message = "クラスを入力してください。";
        this.error.class_id = message;
        valid = false;
      }

      if (!this.student.name) {
        const message = "名前を入力してください。";
        this.error.name = message;
        valid = false;
      } else {
        this.student.name = this.student.name.replace(/\s+/, " ");
      }

      if (!this.student.ruby) {
        const message = "ふりがなを入力してください。";
        this.error.ruby = message;
        valid = false;
      } else {
        this.student.ruby = this.student.ruby.replace(/\s+/, " ");
      }

      if (!valid) {
        return;
      }

      this.isLoading.postData = true;

      if (this.id) {
        this.loadingMessage = "生徒情報を更新しています。";
        this.putStudent();
      } else {
        this.loadingMessage = "生徒情報を登録しています。";
        this.postStudent();
      }
    },

    completeCreate() {
      this.showModal = false;

      if (this.new_id) {
        this.$router.push({
          name: "students"
        });
      } else {
        this.$router.push({
          name: "students.detail",
          params: {
            id: this.id
          }
        });
      }
    }

  }
};