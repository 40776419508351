var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main event_edit"
  }, [_vm.isLoading ? _c('loading-message') : _vm._e(), _c('section', {
    staticClass: "page"
  }, [_c('h2', {
    staticClass: "title--page"
  }, [_vm._v("年間予定登録")]), _vm._m(0), _c('form', {
    staticClass: "page__form common_form",
    attrs: {
      "action": ""
    }
  }, [_vm.isAllDay ? [_c('row-item', [_c('label', {
    staticClass: "required col_3"
  }, [_vm._v("開始日")]), _c('div', {
    staticClass: "common_form--radio_wrap col_9"
  }, [_c('VueCtkDateTimePicker', {
    attrs: {
      "label": "開始日",
      "no-button-now": "",
      "locale": "ja",
      "formatted": "YYYY年MM月DD日(ddd)",
      "output-format": "YYYY-MM-DD 00:00:00",
      "only-date": "",
      "auto-close": ""
    },
    model: {
      value: _vm.from,
      callback: function ($$v) {
        _vm.from = $$v;
      },
      expression: "from"
    }
  }), _vm.error.from ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.from) + " ")]) : _vm._e()], 1)]), _c('row-item', [_c('label', {
    staticClass: "required col_3"
  }, [_vm._v("終了日")]), _c('div', {
    staticClass: "common_form--radio_wrap col_9"
  }, [_c('VueCtkDateTimePicker', {
    attrs: {
      "min-date": _vm.from,
      "label": "終了日",
      "no-button-now": "",
      "locale": "ja",
      "formatted": "YYYY年MM月DD日(ddd)",
      "output-format": "YYYY-MM-DD 00:00:00",
      "only-date": "",
      "auto-close": ""
    },
    model: {
      value: _vm.to,
      callback: function ($$v) {
        _vm.to = $$v;
      },
      expression: "to"
    }
  }), _vm.error.to ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.to) + " ")]) : _vm._e()], 1)])] : [_c('row-item', [_c('label', {
    staticClass: "required col_3"
  }, [_vm._v("開始日時")]), _c('div', {
    staticClass: "common_form--radio_wrap col_9"
  }, [_c('VueCtkDateTimePicker', {
    attrs: {
      "label": "開始日時",
      "no-button-now": "",
      "locale": "ja",
      "format": "YYYY-MM-DD HH:mm",
      "output-format": "YYYY-MM-DD HH:mm:ss",
      "minute-interval": "5"
    },
    model: {
      value: _vm.from,
      callback: function ($$v) {
        _vm.from = $$v;
      },
      expression: "from"
    }
  }), _vm.error.from ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.from) + " ")]) : _vm._e()], 1)]), _c('row-item', [_c('label', {
    staticClass: "required col_3"
  }, [_vm._v("終了日時")]), _c('div', {
    staticClass: "common_form--radio_wrap col_9"
  }, [_c('VueCtkDateTimePicker', {
    attrs: {
      "label": "終了日時",
      "no-button-now": "",
      "locale": "ja",
      "format": "YYYY-MM-DD HH:mm",
      "output-format": "YYYY-MM-DD HH:mm:ss",
      "minute-interval": "5"
    },
    model: {
      value: _vm.to,
      callback: function ($$v) {
        _vm.to = $$v;
      },
      expression: "to"
    }
  }), _vm.error.to ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.to) + " ")]) : _vm._e()], 1)])], _c('row-item', [_c('label', {
    staticClass: "required col_3"
  }, [_vm._v("終日")]), _c('div', {
    staticClass: "common_form--radio_wrap col_9"
  }, [_c('div', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isAllDay,
      expression: "isAllDay"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "isAllDay",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.isAllDay) ? _vm._i(_vm.isAllDay, null) > -1 : _vm.isAllDay
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.isAllDay,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isAllDay = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isAllDay = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isAllDay = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "isAllDay"
    }
  }, [_vm._v("終日の予定")])])])]), _c('row-item', [_c('label', {
    staticClass: "required col_3"
  }, [_vm._v("タイトル")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.title,
      expression: "title"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.title = $event.target.value;
      }
    }
  }), _vm.error.title ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.title) + " ")]) : _vm._e()])]), _c('row-item', [_c('div', {
    staticClass: "common_form--checkbox_wrapper col_3"
  }, [_vm._v("授業日計算")]), _c('div', {
    staticClass: "col_9"
  }, [_c('div', {
    staticClass: "common_form--radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isSchoolDayOff,
      expression: "isSchoolDayOff"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "school_day_on",
      "type": "radio",
      "name": "isSchoolDayOff"
    },
    domProps: {
      "value": 0,
      "checked": _vm._q(_vm.isSchoolDayOff, 0)
    },
    on: {
      "change": function ($event) {
        _vm.isSchoolDayOff = 0;
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "school_day_on"
    }
  }, [_vm._v("この予定を授業日とする ")])]), _c('div', {
    staticClass: "common_form--radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isSchoolDayOff,
      expression: "isSchoolDayOff"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "school_day_off",
      "type": "radio",
      "name": "isSchoolDayOff"
    },
    domProps: {
      "value": 1,
      "checked": _vm._q(_vm.isSchoolDayOff, 1)
    },
    on: {
      "change": function ($event) {
        _vm.isSchoolDayOff = 1;
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "school_day_off"
    }
  }, [_vm._v("この予定を休日とする ")])])])]), _c('row-item', [_c('label', {
    staticClass: "required col_3"
  }, [_vm._v("対象クラス")]), _c('div', {
    staticClass: "col_9"
  }, [_c('div', {
    staticClass: "common_form-checkbox_wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allCheck,
      expression: "allCheck"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "all",
      "type": "checkbox"
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.allCheck) ? _vm._i(_vm.allCheck, true) > -1 : _vm.allCheck
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.allCheck,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = true,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.allCheck = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.allCheck = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.allCheck = $$c;
        }
      }, _vm.handleAllCheck]
    }
  }), _c('label', {
    attrs: {
      "for": "all"
    }
  }, [_vm._v("全校")])]), _c('h3', [_vm._v("学年")]), _c('row-item', {
    staticClass: "wrap"
  }, _vm._l(_vm.grades, function (grade, i) {
    return _c('div', {
      key: i,
      staticClass: "class_checkbox col_2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedGrades,
        expression: "selectedGrades"
      }],
      staticClass: "common_form--checkbox",
      attrs: {
        "id": 'grade' + i,
        "type": "checkbox"
      },
      domProps: {
        "value": grade.id,
        "checked": Array.isArray(_vm.selectedGrades) ? _vm._i(_vm.selectedGrades, grade.id) > -1 : _vm.selectedGrades
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.selectedGrades,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = grade.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.selectedGrades = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedGrades = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedGrades = $$c;
          }
        }
      }
    }), _c('label', {
      attrs: {
        "for": 'grade' + i
      }
    }, [_vm._v(_vm._s(grade.name))])]);
  }), 0), _vm.filteredClassrooms.length > 0 ? _c('h3', [_vm._v("クラス")]) : _vm._e(), _c('row-item', {
    staticClass: "wrap"
  }, _vm._l(_vm.filteredClassrooms, function (classroom, i) {
    return _c('div', {
      key: i,
      staticClass: "class_checkbox col_2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.eventClassroom,
        expression: "eventClassroom"
      }],
      staticClass: "common_form--checkbox",
      attrs: {
        "id": 'classroom' + i,
        "type": "checkbox"
      },
      domProps: {
        "value": classroom.id,
        "checked": Array.isArray(_vm.eventClassroom) ? _vm._i(_vm.eventClassroom, classroom.id) > -1 : _vm.eventClassroom
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.eventClassroom,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = classroom.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.eventClassroom = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.eventClassroom = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.eventClassroom = $$c;
          }
        }
      }
    }), _c('label', {
      attrs: {
        "for": 'classroom' + i
      }
    }, [_vm._v(_vm._s(classroom.name))])]);
  }), 0), _vm.error.eventClassroom ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.eventClassroom) + " ")]) : _vm._e()], 1)]), _c('row-item', [_c('label', {
    staticClass: "col_3"
  }, [_vm._v("保護者へのお知らせ")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.sendMessage,
      expression: "sendMessage"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "submit",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.sendMessage) ? _vm._i(_vm.sendMessage, null) > -1 : _vm.sendMessage
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.sendMessage,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.sendMessage = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.sendMessage = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.sendMessage = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "submit"
    }
  }, [_vm._v("お知らせを送信する")]), _c('p', {
    staticClass: "check_desc"
  }, [_vm._v(" 「お知らせを送信する」にチェックを入れると、"), _c('br'), _vm._v(" この予定の登録後にお知らせ送信画面を開きます。 ")])])]), _c('div', {
    staticClass: "buttons_wrapper tac"
  }, [_c('button', {
    staticClass: "cancel_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('button', {
    staticClass: "submit_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.confirmCreate.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$route.params.id ? "更新" : "登録") + " ")])]), _vm.$route.params.id ? _c('div', {
    staticClass: "buttons_wrapper tac"
  }, [_c('button', {
    staticClass: "delete_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.confirmDelete.apply(null, arguments);
      }
    }
  }, [_vm._v(" 削除 ")])]) : _vm._e()], 2), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.completeAction
    }
  }) : _vm._e(), _vm.showConfirmModal ? _c('confirm-modal', {
    attrs: {
      "confirm-message": _vm.confirmMessage,
      "confirm-message-title": _vm.confirmMessageTitle
    },
    on: {
      "clickOK": _vm.handleModalAction,
      "clickCancel": _vm.cancelConfirm
    }
  }) : _vm._e()], 1)], 1);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('p', {
    staticClass: "page__description"
  }, [_vm._v(" 学校の予定を登録し、出席簿と保護者のカレンダーへ共有します。"), _c('br'), _vm._v(" 予定登録の範囲は学校・学年・クラス（複数選択可）から選べます。 ")]);
}];
export { render, staticRenderFns };