/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ForumIndex.vue?vue&type=template&id=b52c96aa&scoped=true&"
import script from "./ForumIndex.vue?vue&type=script&setup=true&lang=js&"
export * from "./ForumIndex.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ForumIndex.vue?vue&type=style&index=0&id=b52c96aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b52c96aa",
  null
  
)

export default component.exports