export const iconColor = (type) => {
  const colors = {
    遅刻: "green",
    早退: "blue",
    欠席: "orange",
    未登校: "gray",
    登校: "yellow",
    下校: "yellow",
  };
  const k = Object.keys(colors).find((k) => type.indexOf(k) > -1);
  return colors[k] ?? "gray";
};
