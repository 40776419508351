import { computed } from "vue";
import { useRoute } from "vue-router/composables";
import { useStore } from "vue2-helpers/vuex";
import { storeToRefs } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import { useAbsentAlertStore } from "@/stores/absentAlert";
import MonthlyAttendance from "@/components/attendances/monthly/MonthlyAttendance.vue";
import MonthlyTemperature from "@/components/temperatures/MonthlyTemperature.vue";
import LoadingMessage from "@/components/common/LoadingMessage.vue";
export default {
  __name: 'Monthly',

  setup(__props) {
    // @ts-check
    const route = useRoute();
    const store = useStore();
    const {
      isLoading
    } = storeToRefs(useLoadingStore());
    const absentAlertStore = useAbsentAlertStore();
    const studentAddress = computed(() => store.state.studentAddress); // created

    absentAlertStore.getAbsentAlert();
    return {
      __sfc: true,
      route,
      store,
      isLoading,
      absentAlertStore,
      studentAddress,
      MonthlyAttendance,
      MonthlyTemperature,
      LoadingMessage
    };
  }

};