import { login } from "@/services/auth";
import { mapState } from "vuex";
import emailValidator from "email-validator";
import axios from "axios";
export default {
  name: "Mfa",
  data: () => ({
    MFACodeIsSent: false,
    email: "",
    MFACode: "",
    error: {
      email: null
    }
  }),
  head: {
    title: function () {
      return {
        inner: "ログイン"
      };
    },
    meta: []
  },
  computed: { ...mapState(["news"])
  },

  created() {
    this.isForceLogout = !!localStorage.getItem("FORCE_LOGOUT");
  },

  methods: {
    sendMFACode() {
      let valid = true;

      if (!this.email) {
        this.error.email = "メールアドレスを入力してください。";
        valid = false;
      } else if (!emailValidator.validate(this.email)) {
        this.error.email = "メールアドレスの形式が正しくありません。";
        valid = false;
      }

      if (!valid) {
        return;
      }

      axios.post(process.env.VUE_APP_API_ENDPOINT_BASE + "/sendMFACode", {
        email: this.email
      }).then(response => {
        console.log(response.data);

        if (response.data) {
          this.MFACodeIsSent = true;
        }
      }).catch(error => {
        console.log(error);
        this.error.email = "何らかの理由で認証コードを送ることに失敗しました。";
        this.MFACodeIsSent = false;
      });
    },

    handleLogin() {
      login(this.MFACode);
    }

  }
};