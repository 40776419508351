var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('router-link', {
    staticClass: "attachment",
    attrs: {
      "to": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _setup.getS3SignedUrl.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.attachment.file_name) + " ")]);
};

var staticRenderFns = [];
export { render, staticRenderFns };