var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "description"
  }, [_setup.props.to === 'students' ? _c('div', [!_setup.isPastYearMessage ? _c('p', [_vm._v(" 配信率" + _vm._s(_setup.studentDeliverRate) + "%（到達保護者数 " + _vm._s(_setup.studentMessageCountObj.delivered) + " / 送信保護者数 " + _vm._s(_setup.studentMessageCountObj.total) + "） ")]) : _vm._e(), !_setup.isPastYearMessage ? _c('p', [_vm._v(" 開封率" + _vm._s(_setup.studentOpenRate) + "%（開封" + _vm._s(_setup.props.studentAddress) + "数 " + _vm._s(_setup.message.student_opened_count) + " / 送信" + _vm._s(_setup.props.studentAddress) + "数 " + _vm._s(_setup.message.student_sending_count) + "）※" + _vm._s(_setup.props.studentAddress) + "のいずれかの保護者が開封した場合に開封" + _vm._s(_setup.props.studentAddress) + "数に数え上げます ")]) : _vm._e()]) : _c('div', [_c('p', [_vm._v(" 配信率" + _vm._s(_setup.participantDeliverRate) + "%（到達関係者数 " + _vm._s(_setup.participantDelivered) + " / 送信関係者数 " + _vm._s(_setup.message.participant_sending_count) + "） ")]), _c('p', [_vm._v(" 開封率" + _vm._s(_setup.participantOpenRate) + "%（開封関係者数 " + _vm._s(_setup.message.participant_opened_count) + " / 送信関係者数 " + _vm._s(_setup.message.participant_sending_count) + "） ")])]), _vm._m(0)]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "icons"
  }, [_c('p', {
    staticClass: "icons__item display--inline_block"
  }, [_c('i', {
    staticClass: "far fa-envelope"
  }), _vm._v("メール ")]), _c('p', {
    staticClass: "icons__item display--inline_block"
  }, [_c('i', {
    staticClass: "fas fa-phone"
  }), _vm._v("電話 ")])]);
}];
export { render, staticRenderFns };