import { ref, computed } from "vue";
import { useRoute } from "vue-router/composables";
import { useStore } from "vue2-helpers/vuex";
import moment from "moment";
import { createData, deleteData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage.vue";
import ConfirmSlotModal from "@/components/common/ConfirmSlotModal.vue";
import UrlToLink from "@/components/common/UrlToLink.vue";
import { useMessageUrlValidator } from "@/composables/messageUrlValidator";
export default {
  __name: 'ForumDetailComment',
  props: {
    commentList: {
      /** @type {PropType<Object<string, any>[]>} */
      type: Array,
      required: true
    }
  },
  emits: ["setData"],

  setup(__props, {
    emit
  }) {
    // @ts-check
    const route = useRoute();
    const store = useStore();
    const me = computed(() => store.state.me);
    const isLoading = ref(false);
    const commentMessage = ref("");
    const {
      isUrlValidate
    } = useMessageUrlValidator(commentMessage);
    const isShowDeleteConfirmModal = ref(false);
    const deleteTargetId = ref(null);

    const handleCommentDeleteButton = event => {
      isShowDeleteConfirmModal.value = true;
      deleteTargetId.value = event.target.value;
    };

    const hideConfirmModal = () => {
      isShowDeleteConfirmModal.value = false;
    };

    const createForumComment = async () => {
      const payload = {
        school_forum_id: route.params.id,
        message: commentMessage.value
      };

      try {
        isLoading.value = true;
        await createData(`school_forum_comments`, payload);
        isLoading.value = false;
        emit("setData");
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    };

    const deleteForumComment = async () => {
      if (deleteTargetId.value) {
        try {
          isLoading.value = true;
          await deleteData(`school_forum_comments/${deleteTargetId.value}`);
          deleteTargetId.value = null;
          isLoading.value = false;
          emit("setData");
        } catch (error) {
          console.log(error);
          isLoading.value = false;
        }
      }
    };

    return {
      __sfc: true,
      route,
      store,
      emit,
      me,
      isLoading,
      commentMessage,
      isUrlValidate,
      isShowDeleteConfirmModal,
      deleteTargetId,
      handleCommentDeleteButton,
      hideConfirmModal,
      createForumComment,
      deleteForumComment,
      moment,
      LoadingMessage,
      ConfirmSlotModal,
      UrlToLink
    };
  }

};