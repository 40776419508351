var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('section', {
    staticClass: "common_modal"
  }, [_c('div', {
    staticClass: "common_modal__inner"
  }, [_c('div', {
    staticClass: "common_modal__white_frame",
    attrs: {
      "id": "targetFrame"
    }
  }, [_c('div', {
    staticClass: "common_modal__inner_frame",
    attrs: {
      "id": "modal_sentSettled"
    }
  }, [_c('h3', {
    staticClass: "common_modal__title"
  }, [_vm._t("title")], 2), _c('div', {
    staticClass: "common_modal__text"
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "common_modal__bottom tac"
  }, [_c('input', {
    staticClass: "cancel_button",
    attrs: {
      "type": "button",
      "value": "キャンセル"
    },
    on: {
      "click": function ($event) {
        return _vm.clickCancel();
      }
    }
  }), _c('input', {
    staticClass: "execution_button",
    attrs: {
      "type": "button",
      "value": "OK"
    },
    on: {
      "click": function ($event) {
        return _vm.clickOk();
      }
    }
  })])])])])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };