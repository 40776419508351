import "core-js/modules/es.array.push.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import moment from "moment";
import { getData } from "@/services/axios";
import { mapState } from "vuex";
import { saveAs } from "file-saver";
import RowItem from "../RowItem";
import PageSwitch from "./PageSwitch";
import Papa from "papaparse";
export default {
  name: "EnqueteResult",
  components: {
    RowItem,
    PageSwitch
  },

  data() {
    return {
      to: "students",
      enquete: {},
      questions: [],
      answers: [],
      studentAnswers: [],
      participantAnswers: [],
      studentTargetCount: 0,
      participantTargetCount: 0,
      isLoading: true,
      isProcessing: false
    };
  },

  computed: { ...mapState(["studentAddress"]),
    inProgress: function () {
      return moment().startOf("day").isBefore(moment(this.enquete.implement_to).startOf("day"));
    },
    targetCount: function () {
      return this.to === "students" ? this.studentTargetCount : this.participantTargetCount;
    }
  },
  watch: {
    to() {
      this.answers = this.to === "students" ? this.studentAnswers : this.participantAnswers;
    }

  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      getData(["enquetes/progress/" + this.$route.params.id, "questions?locale=ja-JP&enquete_id=" + this.$route.params.id, "reader/get_answers/" + this.$route.params.id]).then(data => {
        if (data[0] && data[1] && data[2]) {
          this.enquete = data[0].items;
          this.questions = data[1].items.map(v => {
            if (v.question_contents_i18n) {
              return {
                id: v.id,
                subject: v.question_contents_i18n[0].subject,
                type: v.type,
                options: v.question_contents_i18n[0].options
              };
            }
          });
          this.answers = this.studentAnswers = data[2].items.answers.students;
          this.participantAnswers = data[2].items.answers.participants;
          this.studentTargetCount = data[2].items.target_count.students;
          this.participantTargetCount = data[2].items.target_count.participants;
        }
      }).catch(error => {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る

        if (error.response) {
          this.$router.push({
            name: "enquetes"
          });
        }
      }).then(() => {
        this.isLoading = false;
      });
    },

    getDateJP(datetime) {
      return moment(datetime).format("YYYY年MM月DD日 HH:mm");
    },

    getAnswerSummary(question_id, answer_index) {
      const answers = this.answers.find(v => v[0] && v[0].question_id === question_id);
      return answers ? answers.filter(v => parseInt(v.body) === answer_index).length : 0;
    },

    getAnswerGraph(question_id, answer_index) {
      return this.targetCount > 0 ? Math.floor(this.getAnswerSummary(question_id, answer_index) / this.targetCount * 100) : 0;
    },

    getAnswerRate() {
      //すべての質問には均等に回答されているとみなして、１つ目で回答率を算出
      return this.answers[0] && this.answers[0].length && this.targetCount ? Math.round(this.answers[0].length / this.targetCount * 1000) / 10 : 0;
    },

    getResultCSV() {
      const enquete_id = this.$route.params.id;
      const output_filename = "アンケート結果【" + this.enquete.subject.replace(/[/\\?%*:|"<>]/g, "-") + "】（" + (this.to === "students" ? this.studentAddress : "関係者") + "）_" + moment().format("YYYYMMDD_HHmm") + "時点.csv";
      this.isProcessing = true;
      getData("reader/get_enquete_result_csv/" + enquete_id + "?to=" + this.to, {
        responseType: "blob"
      }).then(response => {
        let data = response.items;
        const csv = [[data.title]].concat([data.headers], data.contents);
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        saveAs(new Blob([bom, Papa.unparse(csv)], {
          type: "text/csv;charset=utf-8"
        }), output_filename);
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isProcessing = false;
      });
    },

    handleTo(val) {
      this.to = val;
    }

  }
};