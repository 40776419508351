import "core-js/modules/es.array.push.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_information_data status"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("お知らせ配信履歴詳細")]), _c('div', {
    staticClass: "button_wrap"
  }, [_c('section', {
    staticClass: "switch_button"
  }, [_c('router-link', {
    staticClass: "switch_button__left item",
    attrs: {
      "to": {
        name: 'messages.detail',
        params: {
          id: _vm.$route.params.id
        }
      }
    }
  }, [_vm._v(" 送信内容 ")]), _c('router-link', {
    staticClass: "switch_button__right item active",
    attrs: {
      "to": {
        name: 'messages.status',
        params: {
          id: _vm.$route.params.id
        }
      }
    }
  }, [_vm._v(" 配信状況 ")])], 1), _vm.message_detail.enquete ? _c('router-link', {
    staticClass: "result_button",
    attrs: {
      "to": {
        name: 'enquetes.result',
        params: {
          id: _vm.message_detail.enquete.id
        }
      }
    }
  }, [_vm._v(" アンケート集計結果 ")]) : _vm._e()], 1), _c('div', {
    staticClass: "button_wrap"
  }, [_c('section', {
    staticClass: "switch_button"
  }, [_c('a', {
    class: 'switch_button__left item ' + (_vm.to === 'students' ? 'active' : ''),
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleTo('students');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + " ")]), _c('a', {
    class: 'switch_button__right item ' + (_vm.to === 'participants' ? 'active' : ''),
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleTo('participants');
      }
    }
  }, [_vm._v(" 関係者 ")])]), !_vm.isLoading ? _c('button', {
    staticClass: "download_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.downloadCSV.apply(null, arguments);
      }
    }
  }, [_vm._v(" 配信状況ダウンロード ")]) : _vm._e()]), _c('form', {
    staticClass: "gray_frame",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.filterList.apply(null, arguments);
      }
    }
  }, [_c(_setup.RowItem, {
    staticClass: "upper"
  }, [_c('div', {
    staticClass: "col_6"
  }, [_c(_setup.RowItem, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("ステータス")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterOptions.checkbox,
      expression: "filterOptions.checkbox"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "error",
      "type": "checkbox",
      "disabled": _vm.isLoading,
      "value": "error"
    },
    domProps: {
      "checked": Array.isArray(_vm.filterOptions.checkbox) ? _vm._i(_vm.filterOptions.checkbox, "error") > -1 : _vm.filterOptions.checkbox
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.filterOptions.checkbox,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "error",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.filterOptions, "checkbox", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.filterOptions, "checkbox", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.filterOptions, "checkbox", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label col_3",
    attrs: {
      "for": "error"
    }
  }, [_vm._v(" 未達 ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterOptions.checkbox,
      expression: "filterOptions.checkbox"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "reached",
      "type": "checkbox",
      "disabled": _vm.isLoading,
      "value": "reached"
    },
    domProps: {
      "checked": Array.isArray(_vm.filterOptions.checkbox) ? _vm._i(_vm.filterOptions.checkbox, "reached") > -1 : _vm.filterOptions.checkbox
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.filterOptions.checkbox,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "reached",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.filterOptions, "checkbox", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.filterOptions, "checkbox", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.filterOptions, "checkbox", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label col_3",
    attrs: {
      "for": "reached"
    }
  }, [_vm._v(" 未開封 ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterOptions.checkbox,
      expression: "filterOptions.checkbox"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "opened",
      "type": "checkbox",
      "disabled": _vm.isLoading,
      "value": "opened"
    },
    domProps: {
      "checked": Array.isArray(_vm.filterOptions.checkbox) ? _vm._i(_vm.filterOptions.checkbox, "opened") > -1 : _vm.filterOptions.checkbox
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.filterOptions.checkbox,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = "opened",
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.filterOptions, "checkbox", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.filterOptions, "checkbox", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.filterOptions, "checkbox", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label col_4",
    attrs: {
      "for": "opened"
    }
  }, [_vm._v(" 開封済 ")])])], 1), _c('div', {
    staticClass: "row col_6"
  }, [_c(_setup.RowItem, [_c('label', {
    staticClass: "col_2",
    attrs: {
      "for": "filterInputType"
    }
  }, [_vm._v("種別")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterOptions.type,
      expression: "filterOptions.type"
    }],
    staticClass: "common_form--select",
    attrs: {
      "id": "filterInputType",
      "disabled": _vm.isLoading
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.filterOptions, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("選択")]), _vm._l(_vm.typeDropDown, function (type, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": type.slug
      }
    }, [_vm._v(" " + _vm._s(type.label) + " ")]);
  })], 2)])], 1)]), _c(_setup.RowItem, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.to === 'students',
      expression: "to === 'students'"
    }],
    staticClass: "col_5"
  }, [_c(_setup.RowItem, [_c('label', {
    staticClass: "col_3",
    attrs: {
      "for": "filterInputClass"
    }
  }, [_vm._v("クラス")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterOptions.classroom,
      expression: "filterOptions.classroom"
    }],
    staticClass: "common_form--select",
    attrs: {
      "id": "filterInputClass",
      "disabled": _vm.isLoading
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.filterOptions, "classroom", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("選択")]), _vm._l(_vm.activeClassrooms, function (classroom, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": classroom.id
      }
    }, [_vm._v(" " + _vm._s(classroom.name) + " ")]);
  })], 2)])], 1), _c('div', {
    staticClass: "col_5"
  }, [_c(_setup.RowItem, [_c('label', {
    staticClass: "col_2",
    attrs: {
      "for": "filterInputName"
    }
  }, [_vm._v("氏名")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterOptions.keyword,
      expression: "filterOptions.keyword"
    }],
    staticClass: "common_form--input col_8",
    attrs: {
      "id": "filterInputName",
      "type": "text",
      "disabled": _vm.isLoading
    },
    domProps: {
      "value": _vm.filterOptions.keyword
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.filterOptions, "keyword", $event.target.value);
      }
    }
  })])], 1), _c('div', {
    staticClass: "col_2"
  }, [_c('input', {
    staticClass: "search_button",
    attrs: {
      "type": "submit",
      "disabled": _vm.isLoading,
      "value": "検索"
    }
  })])])], 1), _c('div', {
    staticClass: "common_form send_list"
  }, [_c(_setup.StatusRate, {
    attrs: {
      "to": _vm.to,
      "statuses": _vm.statuses,
      "message": _vm.message_detail.message,
      "student-address": _vm.studentAddress
    }
  }), _c('div', {
    staticClass: "info_table"
  }, [_c('div', {
    staticClass: "info_table__head"
  }, [_c(_setup.RowItem, [_c('p', {
    staticClass: "col_1"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checkAllFlag,
      expression: "checkAllFlag"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "check_all",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.checkAllFlag) ? _vm._i(_vm.checkAllFlag, null) > -1 : _vm.checkAllFlag
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.checkAllFlag,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.checkAllFlag = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checkAllFlag = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checkAllFlag = $$c;
        }
      }, _vm.checkAll]
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "check_all"
    }
  })]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v(" 氏名(" + _vm._s(_vm.to === "students" ? "クラス" : "所属など") + ") ")]), _c('p', {
    staticClass: "col_1"
  }, [_vm._v("種別")]), _c('p', {
    staticClass: "col_2"
  }, [_vm._v("ステータス")]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v("開封日時")]), _c('div', {
    staticClass: "col_2"
  }, [_c('p', {
    staticClass: "information_subject"
  })])])], 1), _vm.isLoading ? _c('div', {
    staticClass: "info_table__body"
  }, _vm._l(5, function (n) {
    return _c(_setup.RowItem, {
      key: n
    }, [_c('p', {
      staticClass: "fake_scroll_table__span col_12"
    }, [_c('content-placeholders', [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)], 1)]);
  }), 1) : _vm.to === 'students' ? _vm._l(_vm.filteredStatuses, function (status, i) {
    return _c('div', {
      key: i,
      staticClass: "info_table__body"
    }, [status.name ? [_c(_setup.RowItem, {
      staticClass: "students"
    }, [_c('p', {
      staticClass: "col_1"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.reSendTargets,
        expression: "reSendTargets"
      }],
      staticClass: "common_form--checkbox",
      attrs: {
        "id": 'status_' + status.id,
        "type": "checkbox"
      },
      domProps: {
        "value": status.id,
        "checked": Array.isArray(_vm.reSendTargets) ? _vm._i(_vm.reSendTargets, status.id) > -1 : _vm.reSendTargets
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.reSendTargets,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = status.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.reSendTargets = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.reSendTargets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.reSendTargets = $$c;
          }
        }, _vm.testAllChecked]
      }
    }), _c('label', {
      staticClass: "common_form--label",
      attrs: {
        "for": 'status_' + status.id
      }
    })]), _c('p', {
      staticClass: "col_3"
    }, [_c('span', {
      staticClass: "classroom_name"
    }, [_vm._v(_vm._s(status.classroom_name))]), _vm._v(_vm._s(_vm._f("shorten")(status.name, 30)) + " ")]), _c('p', {
      staticClass: "col_1"
    }), _c('p', {
      staticClass: "col_2"
    }, [_vm._v(" " + _vm._s(_vm.getSendStatusJP(status.status)) + " ")]), status.status === 'opened' ? _c('p', {
      staticClass: "col_3"
    }, [_vm._v(" " + _vm._s(_vm.getJPTime(status.status_timestamps.updated_at)) + " ")]) : _vm._e(), _c('div', {
      staticClass: "col_2 tar"
    })]), _vm._l(status.guardian, function (guardian, index) {
      return _c(_setup.RowItem, {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: status.guardian,
          expression: "status.guardian"
        }],
        key: index,
        staticClass: "participants"
      }, [_c('p', {
        staticClass: "col_1"
      }), _c('p', {
        staticClass: "col_3"
      }, [_vm._v(" " + _vm._s(_vm._f("shorten")(guardian.name, 30)) + _vm._s(guardian.relationship ? "（" + guardian.relationship + "）" : "") + " ")]), _c('p', {
        staticClass: "col_1"
      }, [guardian.type === 'email' || guardian.type === 'both' ? _c('span', [_c('i', {
        staticClass: "far fa-envelope"
      })]) : _vm._e(), guardian.type === 'phone' || guardian.type === 'both' ? _c('span', [_c('i', {
        staticClass: "fas fa-phone"
      })]) : _vm._e()]), _c('p', {
        staticClass: "col_2"
      }, [_vm._v(" " + _vm._s(_vm.getSendStatusJP(guardian.status)) + " ")]), guardian.status === 'opened' ? _c('p', {
        staticClass: "col_3"
      }, [_vm._v(" " + _vm._s(_vm.getJPTime(guardian.status_timestamps.updated_at)) + " ")]) : _c('p', {
        staticClass: "col_2"
      })]);
    })] : _vm._e()], 2);
  }) : _vm.to === 'participants' ? _vm._l(_vm.filteredStatuses, function (status, i2) {
    return _c('div', {
      key: i2,
      staticClass: "info_table__body"
    }, [status.participant ? _c(_setup.RowItem, [_c('p', {
      staticClass: "col_1"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.reSendTargets,
        expression: "reSendTargets"
      }],
      staticClass: "common_form--checkbox",
      attrs: {
        "id": 'status_' + status.id,
        "type": "checkbox"
      },
      domProps: {
        "value": status.participant.id,
        "checked": Array.isArray(_vm.reSendTargets) ? _vm._i(_vm.reSendTargets, status.participant.id) > -1 : _vm.reSendTargets
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.reSendTargets,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = status.participant.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.reSendTargets = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.reSendTargets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.reSendTargets = $$c;
          }
        }, _vm.testAllChecked]
      }
    }), _c('label', {
      staticClass: "common_form--label",
      attrs: {
        "for": 'status_' + status.id
      }
    })]), _c('p', {
      staticClass: "col_3"
    }, [_vm._v(" " + _vm._s(_vm._f("shorten")(status.participant.name)) + _vm._s(status.participant.belonging ? "(" + status.participant.belonging + ")" : "") + " ")]), _c('p', {
      staticClass: "col_1"
    }, [status.type === 'email' || status.type === 'both' ? _c('span', [_c('i', {
      staticClass: "far fa-envelope"
    })]) : _vm._e(), status.type === 'phone' || status.type === 'both' ? _c('span', [_c('i', {
      staticClass: "far fa-envelope"
    })]) : _vm._e()]), _c('p', {
      staticClass: "col_2"
    }, [_vm._v(" " + _vm._s(_vm.getSendStatusJP(status.final_status)) + " ")]), _c('div', {
      staticClass: "col_3"
    }, [status.final_status === 'opened' ? _c('p', [_vm._v(" " + _vm._s(_vm.getJPTime(status.updated_at)) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "col_2"
    })]) : _vm._e()], 1);
  }) : _vm._e()], 2), _c('div', {
    staticClass: "under_button"
  }, [_c('span', {
    staticClass: "arrow"
  }), _c('button', {
    staticClass: "create_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push({
          name: 'messages.create',
          params: {
            targets: _vm.sendTargetsParam,
            content: {
              subject: _vm.message_detail.content.subject,
              call_body: _vm.message_detail.content.call_body,
              mail_body: _vm.message_detail.content.mail_body,
              attached_file: _vm.message_detail.message.attached_file
            },
            enquete_subject: _vm.message_detail.enquete_contents && _vm.message_detail.enquete_contents.subject ? _vm.message_detail.enquete_contents.subject : '',
            questions: _vm.message_detail.questions
          }
        });
      }
    }
  }, [_vm._v(" 選択した" + _vm._s(_vm.to === "students" ? _vm.studentAddress : "関係者") + "にお知らせ作成 ")])]), _c('div', {
    staticClass: "tac"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'messages'
      }
    }
  }, [_vm._v(" 一覧に戻る ")])], 1)], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };