import { ref } from "vue";
import { getData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage.vue";
import DekitusIntroduction from "@/components/common/DekitusIntroduction";
export default {
  __name: 'Index',

  setup(__props) {
    const dekitusStudents = ref([]);
    const isLoading = ref(false);

    const setData = async () => {
      try {
        isLoading.value = true;
        const data = await getData(`dekitus_student?with=student.classroom`);
        dekitusStudents.value = data.items;
        isLoading.value = false;
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    };

    setData();
    return {
      __sfc: true,
      dekitusStudents,
      isLoading,
      setData,
      LoadingMessage,
      DekitusIntroduction
    };
  }

};