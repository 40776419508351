import { useRoute } from 'vue-router/composables';
export default {
  __name: 'ForumSwitch',

  setup(__props) {
    // @ts-check
    const route = useRoute();
    return {
      __sfc: true,
      route
    };
  }

};